import React from 'react'
import { css } from 'emotion'
import icons from '../icons'

const FieldTechnical = props => (
    <React.Fragment>
        <span className={css`color: #B11A04;`}>{props.field.id}</span> : <span className={css`color: #CA9800;`}>{props.field.type || 'undefined'}</span>{props.field.required ? <span className={css`color: #000; margin-left: 1px;`}>!</span> : null}{props.field.formulaDependencies ? <span className={css`color: #888; margin-left: 4px;`}>{icons.formula({ width: 11 })}</span> : null}{props.field.virtual ? <span className={css`color: #888; margin-left: 4px; font-size: 11px;`}>virtual</span> : null}
    </React.Fragment>
)

export default FieldTechnical