import React from 'react'
import ReactDOM from 'react-dom'
import { css } from 'emotion'

export default class OutputConsole extends React.Component {

    componentDidUpdate() {

        const el = ReactDOM.findDOMNode(this)

        el.scrollTop = el.scrollHeight
    }

    render() {

        return (
            <div
                className={css`
                position: relative;
            `}
            >
                <div
                    className={css`
                    width: 100%;
                    height: 400px;
                    overflow-x: hidden;
                    overflow-y: scroll;
                    background-color: #181818;
                    color: rgb(255, 255, 255, 0.8);
                    white-space: pre-wrap;
                    word-break: break-word;
                    font-family: Monospace, Menlo, "Courier New";
                    padding: 20px;
                    font-size: 14px;
                    border-radius: 3px;
                `}
                >
                    {this.props.value}

                </div>
                <div
                    className={css`
                        position: absolute;
                        right: 16px;
                        bottom: 16px;
                        width: 44px;
                        height: 44px;
                        border-radius: 6px;
                        background-color: #fff;
                    `}
                >

                </div>
            </div>
        )
    }
}
