import React from 'react'
import DifferenceCard from './DifferenceCard'

const ContractYearDebtorTrajectoryCountCard = ({ component, source }) => (
    <DifferenceCard
        title={component.name}
        value={source.debtorTrajectoryCount}
        differences={[
            {
                title: 'Vergeleken met vorig jaar',
                prev: source.debtorTrajectoryCountPrev,
                diff: source.debtorTrajectoryCountDiff,
                diffPct: source.debtorTrajectoryCountDiffPct,
                reverse: true
            },
            {
                title: 'Vergeleken met LesautoDeal gemiddelde',
                prev: source.stdDebtorTrajectoryCount,
                diff: source.stdDebtorTrajectoryCountDiff,
                diffPct: source.stdDebtorTrajectoryCountDiffPct,
                reverse: true
            }
        ]}
    />
)

export default ContractYearDebtorTrajectoryCountCard