import React from 'react'
import api from '../services/api'
import Title from "../components/Title";
import LoadingState from "../components/LoadingState";
import ListTable from '../components/ListTable'
import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import Button from '@pndr/button'
import spinner from '@pndr/spinner'

export default class AgendaRoute extends React.Component {

    state = {
        loading: true,
        running: null,
        data: null
    }

    componentDidMount() {
        this.fetch()
    }

    fetch = async () => {

        const response = await api.request({
            method: 'post',
            data: {
                query: `
                    query tasks {
                        viewer {
                            tasks {
                                id
                                name
                                description
                                cron
                                prettyCron
                                lastDate
                                script
                                url
                                nextDate
                                enabled
                            }
                        }
                    }
                `
            }
        })

        this.setState({
            loading: false,
            data: response.data.data
        })
    }

    render() {

        if (this.state.loading) return <LoadingState />

        const { tasks } = this.state.data.viewer

        return (
            <div>
                <Title>
                    {`Agenda (${tasks.length})`}
                </Title>
                <PageHeader title={`Agenda (${tasks.length})`} />
                <Content>
                    <ListTable
                        fields={[{
                            name: 'name',
                            dataKey: 'name'
                        }, {
                            name: 'description',
                            dataKey: 'description'
                        }, {
                            name: 'cron',
                            dataKey: 'cron'
                        }, {
                            name: 'prettyCron',
                            dataKey: 'prettyCron'
                        }, {
                            name: 'script',
                            dataKey: 'script'
                        }, {
                            name: 'url',
                            dataKey: 'url'
                        }, {
                            name: 'lastRun',
                            type: 'dateTime',
                            getter: ({ row }) => row.lastDate ? row.lastDate : '-'
                        }, {
                            name: 'nextRun',
                            type: 'dateTime',
                            dataKey: 'nextDate'
                        }, {
                            name: 'enabled',
                            type: 'boolean',
                            dataKey: 'enabled'
                        }, {
                            name: 'manual run',
                            type: 'boolean',
                            renderer: ({ row }) => row.enabled ? (
                                <Button
                                    icon={this.state.running === row.id ? spinner : null}
                                    primary
                                    onClick={() => this.handleRun({ id: row.id })}
                                >
                                    {this.state.running === row.id ? 'Running...' : 'Run'}
                                </Button>
                            ) : null
                        }]}
                        rows={tasks}
                    />
                </Content>
            </div>
        )
    }

    handleRun = async ({ id }) => {

        const confirmed = window.confirm('This is a developer action. Manually running tasks is only for testing purposes. Are you sure you want to run this task?')

        if (!confirmed) {
            return
        }

        this.setState({ running: id })

        await api.request({
            method: 'post',
            data: {
                query: `
                    mutation executeTask($id: ID!) {
                        executeTask(id: $id)
                    } 
                `,
                variables: {
                    id
                }
            }
        })

        await this.fetch()

        this.setState({ running: null })
    }
}