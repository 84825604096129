import React from 'react'
import { css } from 'emotion'

const ChartLegend = ({ children }) => (
    <div
        className={css`
            display: flex;
            align-items: center;
            margin-bottom: 30px;
        `}
    >
        {children}
    </div>
)

export default ChartLegend