import React from 'react'
import { css } from 'emotion'
import StatisticsDiff from './StatisticsDiff'
import StatisticsDiffPct from './StatisticsDiffPct'
import StatisticsValue from './StatisticsValue'

const Card = ({ children }) => (
    <div
        className={css`
            border-radius: 4px;
            background-color: #fff;
            border: 1px solid #e3e3e3;
            box-shadow: 0 5px 20px rgba(0,0,0,.1);
        `}
    >
        {children}
    </div>
)

const rowRenderers = {
    Default: ({ key, row }) => (
        <div
            key={key}
            className={css`
                padding: 5px 10px;
                border-bottom: 1px solid #f2f2f2;
                &:last-child {
                    border-bottom: none;
                }
            `}
        >
            <div
                className={css`
                display: flex;
                align-items: center;
                font-size: 14px;
                margin-bottom: 4px;
            `}
            >
                <div
                    className={css`
                    font-weight: 500;
                    flex-grow: 1;
                    margin-right: 16px;
                `}
                >
                    {row.title}
                </div>
                <div
                    className={css`
                    font-weight: 400;
                `}
                >
                    <StatisticsValue value={row.value} />
                </div>
            </div>
        </div>
    ),
    Difference: ({ key, row }) => (
        <div
            key={key}
            className={css`
                    padding: 5px 10px;
                    border-bottom: 1px solid #f2f2f2;
                    &:last-child {
                        border-bottom: none;
                    }
                `}
        >
            <div
                className={css`
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    margin-bottom: 4px;
                `}
            >
                <div
                    className={css`
                        font-weight: 500;
                        flex-grow: 1;
                        margin-right: 16px;
                    `}
                >
                    {row.title}
                </div>
                <div
                    className={css`
                        font-weight: 400;
                    `}
                >
                    <StatisticsValue value={row.value} />
                </div>
            </div>
            <div
                className={css`
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                `}
            >
                <div
                    className={css`
                        flex-grow: 1;
                        margin-right: 16px;
                    `}
                >
                    <StatisticsDiffPct value={row.prevDiffPct} reverse={row.reverse} />
                </div>
                <div
                    className={css`
                        font-weight: 400;
                        color: #737373;
                        font-size: 12px;
                        margin-left: auto;
                    `}
                >
                    <StatisticsValue value={row.prev} />
                    {' '}(<StatisticsDiff value={row.prevDiff} />)
                    </div>
            </div>
        </div>
    )
}

const StatisticsPreview = ({ title, description, rows }) => {

    return (
        <Card>
            <div
                className={css`
                    padding: 15px 10px;
                    border-bottom: 1px solid #f2f2f2;
                `}
            >
                <div
                    className={css`
                        font-weight: 600;
                        font-size: 16px;
                        margin-bottom: 4px;
                    `}
                >
                    {title}
                </div>
                <div
                    className={css`
                        font-weight: 400;
                        font-size: 14px;
                        color: #737373;
                    `}
                >
                    {description}
                </div>
            </div>
            {rows.map((row, index) => {

                const renderer = rowRenderers[row.type]

                return renderer({ row, key: index })
            })}
        </Card>
    )
}

export default StatisticsPreview