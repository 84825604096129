import React from 'react'
import { css } from 'emotion'
import getComponent from '../custom-components/getComponent'

export default class ComponentHeader extends React.Component {

    render() {

        return (
            <div className={css`display: flex; align-items: center; margin-bottom: 20px;`}>
                <div className={css`flex-grow: 1;`}>
                    <div className={css`font-size: 21px; font-weight: bold;`}>
                        {this.props.component.name}
                    </div>
                </div>
                <div>
                    {this.props.component.actions ? this.props.component.actions.map((component, index) => {

                        const Component = getComponent(component)

                        return (
                            <Component
                                {...this.props}
                                key={index}
                                component={component}
                            />
                        )
                    }) : null}
                </div>
            </div>
        )
    }
}