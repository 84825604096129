import React from 'react'
import get from 'lodash/get'
import { css } from 'emotion'
import renderType from './renderType'
import Box from './Box'
import EmptyState from './EmptyState'
import defaultEmptyRenderer from './defaultEmptyRenderer';

export default class ListTable extends React.Component {

    render() {

        const { rows, fields } = this.props

        return rows && rows.length ? (
            <div
                className={css`
                    width: 100%;
                    margin-bottom: 70px;
                `}
            >
                <Box>
                    <table
                        className={css`
                    width: 100%;
                    table-layout: fixed;
                    border-collapse: collapse;
                `}
                    >
                        <colgroup>
                            {fields.map(field => (
                                <col width={field.width} />
                            ))}
                        </colgroup>
                        <thead>
                            <tr>
                                {fields.map((field, index) => (
                                    <th
                                        title={field.name}
                                        key={index}
                                        className={css`
                                border-bottom: 1px solid #f2f2f2;
                                padding: 16px 8px;
                                text-align: left;
                                color: #000;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                &:first-of-type {
                                    padding-left: 16px;
                                }
                                &:last-of-type {
                                    padding-right: 16px;
                                }
                            `}
                                    >
                                        {field.name}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {rows && rows.map((row, index) => (
                                <tr
                                    key={row.id}
                                    className={css`
                            &:hover {
                                background-color: #f7fbff;
                                cursor: pointer;
                            }
                        `}
                                >
                                    {fields.map((field, index) => {

                                        let data = null

                                        if (field.getter) {
                                            data = field.getter({ row })
                                        } else {
                                            data = get(row, field.dataKey)
                                        }

                                        let value = null

                                        if (field.renderer) {
                                            value = field.renderer({ row })
                                        } else {
                                            value = renderType(field.type)({
                                                value: data
                                            })
                                        }

                                        return (
                                            <td
                                                key={index}
                                                className={css`
                                border-top: 1px solid #f2f2f2;
                                border-bottom: 1px solid #f2f2f2;
                                padding: 16px 8px;
                                overflow: hidden;
                                word-break: break-word;
                                color: #737373;
                                 &:first-of-type {
                                    padding-left: 16px;
                                }
                                &:last-of-type {
                                    padding-right: 16px;
                                }
                            `}
                                            >
                                                {value !== null ? value : defaultEmptyRenderer()}
                                            </td>
                                        )
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Box>
            </div>
        ) : (
                <EmptyState>
                    No records found
            </EmptyState>
            )
    }
}