import React from 'react'
import Button from '@pndr/button'
import spinner from '@pndr/spinner'
import api from '../services/api'

export default class DebtorTrajectoryConvertPenaltiesToInvoiceButton extends React.Component {

    state = {
        loading: true,
        data: null,
        converting: false
    }

    componentDidMount() {
        this.fetch()
    }

    fetch = async () => {

        const response = await api.request({
            method: 'post',
            data: {
                query: `
                    query debtorTrajectory($id: ID!) {
                        viewer {
                            debtorTrajectory(id: $id) {
                                id
                                penalties {
                                    id
                                    billed
                                }
                            }
                        }
                    }
                `,
                variables: {
                    id: this.props.recordId
                }
            }
        })


        this.setState({
            loading: false,
            data: response.data.data
        })
    }

    render() {

        if (this.state.loading) {
            return null
        }

        const { debtorTrajectory } = this.state.data.viewer
        const { penalties } = debtorTrajectory
        const unbilledPenalties = penalties.filter(penalty => penalty.billed === false)

        return (
            <Button
                disabled={!unbilledPenalties.length}
                icon={this.state.converting ? spinner : () => this.props.component.props.icon}
                primary
                onClick={this.handleCreatePenaltyInvoice}
            >
                {this.props.component.props.label}
            </Button>
        )
    }

    handleCreatePenaltyInvoice = async () => {

        this.setState({ converting: true })

        await api.request({
            method: 'post',
            data: {
                query: `
                    mutation createPenaltyInvoiceForUnbilledPenaltiesOfDebtorTrajectory($debtorTrajectoryId: ID!) {
                        createPenaltyInvoiceForUnbilledPenaltiesOfDebtorTrajectory(debtorTrajectoryId: $debtorTrajectoryId) {
                            id
                        }
                    } 
                `,
                variables: {
                    debtorTrajectoryId: this.props.recordId
                }
            }
        })

        this.setState({ converting: false })

        await this.props.onPageRefresh()
    }
}