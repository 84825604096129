import React from 'react'
import Switch from '@pndr/switch'
import { css } from 'emotion'
import api from '../services/api'
import Button from '@pndr/button'
import Box from '../components/Box'
import LoadingOverlay from '../components/LoadingOverlay'
import SetPhaseOffsetForContract from '../components/SetPhaseOffsetForContract'

class SetPenaltiesEnabledForDebtorTrajectory extends React.Component {

    state = {
        value: this.props.source.penaltiesEnabled,
        loading: false
    }

    render() {

        return (
            <div className={css`position:relative;`}>
                <div
                    className={css`
                font-weight: bold;
                margin-bottom: 16px;
            `}
                >
                    Boetes in- of uitschakelen
        </div>
                <Switch
                    value={this.state.value}
                    width={25}
                    onChange={this.handlePenaltiesEnabledChanged}
                />
                {this.state.loading ? (
                    <LoadingOverlay />
                ) : null}
            </div>
        )
    }

    handlePenaltiesEnabledChanged = async ({ value: penaltiesEnabled }) => {

        this.setState({
            loading: true
        })

        await api.request({
            method: 'post',
            data: {
                query: `
                    mutation setPenaltiesEnabledForDebtorTrajectory($id: ID!, $penaltiesEnabled: Boolean!) {
                        setPenaltiesEnabledForDebtorTrajectory(id: $id, penaltiesEnabled: $penaltiesEnabled) {
                            id
                            penaltiesEnabled
                        }
                    } 
                `,
                variables: {
                    id: this.props.recordId,
                    penaltiesEnabled
                }
            }
        })

        await this.props.onPageRefresh()
    }
}


class SetAlertEnabledForDebtorTrajectory extends React.Component {

    state = {
        value: this.props.source.alertEnabled,
        loading: false
    }

    render() {

        return (
            <div className={css`position:relative;`}>
                <div
                    className={css`
                font-weight: bold;
                margin-bottom: 16px;
            `}
                >
                    <span role="img" aria-label="img">⚠️</span> Corona melding in- of uitschakelen
        </div>
                <Switch
                    value={this.state.value}
                    width={25}
                    onChange={this.handleAlertEnabledChanged}
                />
                {this.state.loading ? (
                    <LoadingOverlay />
                ) : null}
            </div>
        )
    }

    handleAlertEnabledChanged = async ({ value: alertEnabled }) => {

        this.setState({
            loading: true
        })

        await api.request({
            method: 'post',
            data: {
                query: `
                    mutation setAlertEnabledForDebtorTrajectory($id: ID!, $alertEnabled: Boolean!) {
                        setAlertEnabledForDebtorTrajectory(id: $id, alertEnabled: $alertEnabled) {
                            id
                            alertEnabled
                        }
                    } 
                `,
                variables: {
                    id: this.props.recordId,
                    alertEnabled
                }
            }
        })

        await this.props.onPageRefresh()
    }
}
class SetMutedForDebtorTrajectory extends React.Component {

    state = {
        value: this.props.source.muted,
        loading: false
    }

    render() {

        return (
            <div className={css`position:relative;`}>
                <div
                    className={css`
                font-weight: bold;
                margin-bottom: 16px;
            `}
                >
                    Stille modus in- of uitschakelen
        </div>
                <Switch
                    value={this.state.value}
                    width={25}
                    onChange={this.handleMutedChange}
                />
                {this.state.loading ? (
                    <LoadingOverlay />
                ) : null}
            </div>
        )
    }

    handleMutedChange = async ({ value: muted }) => {

        this.setState({
            loading: true
        })

        await api.request({
            method: 'post',
            data: {
                query: `
                    mutation setMutedForDebtorTrajectory($id: ID!, $muted: Boolean!) {
                        setMutedForDebtorTrajectory(id: $id, muted: $muted) {
                            id
                            muted
                        }
                    } 
                `,
                variables: {
                    id: this.props.recordId,
                    muted
                }
            }
        })

        await this.props.onPageRefresh()
    }
}

class ResendPhaseMessageForDebtorTrajectory extends React.Component {

    state = {
        value: this.props.source.muted,
        loading: false
    }

    render() {

        return (
            <div className={css`position:relative;`}>
                <div
                    className={css`
                font-weight: bold;
                margin-bottom: 16px;
            `}
                >
                    E-mail voor huidige fase opnieuw versturen
        </div>
                <Button size={'sm'} primary onClick={this.handleResend}>
                    Versturen
                </Button>
                {this.state.loading ? (
                    <LoadingOverlay />
                ) : null}
            </div>
        )
    }

    handleResend = async () => {

        this.setState({
            loading: true
        })

        await api.request({
            method: 'post',
            data: {
                query: `
                    mutation resendPhaseMessageForDebtorTrajectory($id: ID!) {
                        resendPhaseMessageForDebtorTrajectory(id: $id)
                    } 
                `,
                variables: {
                    id: this.props.recordId
                }
            }
        })

        this.setState({
            loading: false
        })

        // await this.props.onPageRefresh()
    }
}

export default class DebtorTrajectoryActions extends React.Component {

    state = {
        value: this.props.source.muted,
        loading: false
    }

    render() {

        return (
            <div>
                <Box p={32}>
                    <div className={css`margin-bottom: 32px;`}>
                        <SetAlertEnabledForDebtorTrajectory {...this.props} />
                    </div>
                    <div className={css`margin-bottom: 32px;`}>
                        <SetMutedForDebtorTrajectory {...this.props} />
                    </div>
                    <div className={css`margin-bottom: 32px;`}>
                        <SetPhaseOffsetForContract
                            dtg={this.props.source.dtg}
                            atg={this.props.source.atg}
                            phaseOffset={this.props.source.phaseOffset}
                            contractId={this.props.source.contractId}
                            onPageRefresh={this.props.onPageRefresh}
                        />
                    </div>
                    <div className={css`margin-bottom: 32px;`}>
                        <SetPenaltiesEnabledForDebtorTrajectory {...this.props} />
                    </div>
                    <ResendPhaseMessageForDebtorTrajectory {...this.props} />
                </Box>
            </div>
        )
    }
}