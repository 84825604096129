import React from 'react'
import spinner from '@pndr/spinner'
import api from '../services/api'
import meanBy from 'lodash/meanBy'
import moment from 'moment'
import 'moment/locale/nl'
import { css } from 'emotion'
import SegmentedControl from '../components/SegmentedControl'
import Autosizer from 'react-virtualized-auto-sizer'
import random from 'lodash/random'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ReferenceArea, Text, ReferenceLine, Scatter } from 'recharts'

const transformToDayDataPoints = (data) => {

    if (!data.length) {
        return []
    }

    const pointsByDate = data.reduce((result, point) => {

        result[point.date] = point

        return result
    }, {})

    const start_date = moment(data[0].date)
    const end_date = moment(data[data.length - 1].date)

    const startDate = start_date.clone().startOf('month')
    const endDate = end_date.clone().endOf('month')

    let result = []

    for (let pointer = startDate.clone(); pointer.isSameOrBefore(endDate); pointer.add(1, 'day')) {

        const key = pointer.toISOString()

        const point = pointsByDate[key]

        if (point) {
            result.push(point)
        } else {
            result.push({
                date: key,
                dtg: null
            })
        }
    }

    return result
}

const transformToMonthDataPoints = (data) => {

    if (!data.length) {
        return []
    }

    const start_date = moment(data[0].date)
    const end_date = moment(data[data.length - 1].date)

    const startDate = start_date.clone().startOf('year')
    const endDate = end_date.clone().endOf('year')

    const unit = 'month'
    const pointsByUnitDate = {}

    data.forEach(point => {

        const unit_date = moment(point.date).startOf(unit)
        const unit_date_key = unit_date.toISOString()
        const points = pointsByUnitDate[unit_date_key] = pointsByUnitDate[unit_date_key] || []
        points.push(point)
    })

    let result = []

    for (let pointer = startDate.clone(); pointer.isSameOrBefore(endDate); pointer.add(1, unit)) {

        const start = pointer.clone()
        const key = start.clone().toISOString()
        const end = start.clone().add(1, unit)
        const range = end.diff(start, 'days')

        const points = pointsByUnitDate[key]

        if (points && points.length === range) {

            const mean = meanBy(points, point => point.dtg)

            result.push({
                date: key,
                dtg: Math.ceil(mean)
            })

        } else {
            result.push({
                date: key,
                dtg: null
            })
        }
    }

    result = result.sort((a, b) =>
        a.date.localeCompare(b.date)
    )

    return result
}

const GraphLegend = ({ children }) => (
    <div
        className={css`
            display: flex;
            align-items: center;
            margin-bottom: 30px;
        `}
    >
        {children}
    </div>
)

const GraphLegendItem = ({ type, title, description }) => (
    <div
        className={css`
            padding: 0;
            list-style-type: none;
            display: flex;
            align-items: center;
            color: ${type === 'alt' ? '#63bfff' : '#07f'};
            -webkit-transform: translateX(2px);
            transform: translateX(2px);
            font-size: 14px;
            margin-left: 40px;
            &:first-child {
                margin-left: 0;
            }
        `}
    >
        <div
            className={css`
                width: 14px;
                height: 14px;
                margin-right: 5px;
                border: 1px solid;
                border-radius: 50%;
                ${type === 'alt' ? 'border-style: dashed;' : ''}
            `}
        />
        <div>
            {title}
        </div>
        <div
            className={css`
                color: #b3b3b3;
                font-size: 12px;
                margin-left: 5px;
            `}
        >
            {description}
        </div>
    </div>
)

const CustomXTick = (props) => {

    return <Text
        {...props}
        fill={'#000'}
        fontSize={13}
        fontWeight={'bold'}
    >
        {props.labelFormatter({ date: moment(props.payload.value) })}
    </Text>
}

const CustomizedTooltip = (props) => {

    if (!props.payload || !props.payload.length) {
        return null
    }

    // console.log(props.payload)

    const payload = props.payload[0].payload

    const { date } = payload

    return (
        <div
            className={css`
            width: 250px;
            border: 1px solid #e3e3e3;
            border-radius: 4px;
            background: #fff;
            font-size: 14px;
            box-shadow: 0 5px 20px rgba(0,0,0,.1);
            `}
        >
            <div
                className={css`
                padding: 15px;             
                `}
            >
                <div
                    className={css`
                    color: #000;
                    font-weight: 700;
                    margin-bottom: 4px;
                    `}
                >{props.labelFormatter({ date: moment(date) })}</div>
                <div
                    className={css`
                    color: #737373;
    font-weight: 400;
                    `}
                >omschrijving</div>
            </div>
            {props.payload.map((payload, index) => {

                return (
                    <div
                        key={index}
                        className={css`
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                border-top: 1px solid #ebebeb;
                padding: 10px 15px;
                `}
                    >
                        <div
                            className={css`
                    display: flex;
                    justify-content: space-between;
                    flex: 0 0 100%;
                    margin-bottom: 0;
                    `}
                        >
                            <span>
                                {payload.dataKey}
                            </span>
                            <span>
                                {payload.value ? payload.value.toFixed(0) : '-'}
                            </span>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

const CustomCursor = props => {
    console.log("CustomCursor", props)
    return null
};

class Example extends React.Component {

    render() {

        const data = this.props.data

        const phases = [
            {
                "id": "PRE_PHASE_2",
                "name": "Fase -1",
                "description": "DTG of 8\nAdministrator get's notified that the customer will receive a message the following day\n",
                "backgroundColor": "#ddd",
                "color": "white",
                "type": "ReferenceArea",
                "props": {
                    "y1": 8,
                    "y2": 9
                }
            },
            {
                "id": "PRE_PHASE_1",
                "name": "Fase 0",
                "description": "DTG of 9\nCustomer get's notified that they will enter Phase 1 of the debtor trajectory the following day\n",
                "backgroundColor": "#888",
                "color": "white",
                "type": "ReferenceArea",
                "props": {
                    "y1": 9,
                    "y2": 10
                }
            },
            {
                "id": "PHASE_1",
                "name": "Fase 1",
                "description": "DTG of 10 to 19\nPenalty of € 10,- per day in this phase\n",
                "backgroundColor": "#FEB24C",
                "color": "white",
                "type": "ReferenceArea",
                "props": {
                    "y1": 10,
                    "y2": 20
                }
            },
            {
                "id": "PHASE_2",
                "name": "Fase 2",
                "description": "DTG of 20 to 24\nPenalty of € 15,- per day in this phase\n",
                "backgroundColor": "#FD8D3C",
                "color": "white",
                "type": "ReferenceArea",
                "props": {
                    "y1": 20,
                    "y2": 25
                }
            },
            {
                "id": "PHASE_3",
                "name": "Fase 3",
                "description": "DTG of 25 to 28\nPenalty of € 25,- per day in this phase\n",
                "backgroundColor": "#FC4E2A",
                "color": "white",
                "type": "ReferenceArea",
                "props": {
                    "y1": 25,
                    "y2": 29
                }
            },
            {
                "id": "PHASE_4",
                "name": "Fase 4",
                "description": "DTG of 29\nPenalty of € 25,- per day in this phase\n",
                "backgroundColor": "#E31A1C",
                "color": "white",
                "type": "ReferenceArea",
                "props": {
                    "y1": 29,
                    "y2": 30
                }
            },
            {
                "id": "PHASE_5",
                "name": "Fase 5",
                "description": "DTG of 30\nPenalty of € 25,- per day in this phase\n",
                "backgroundColor": "#BD0026",
                "color": "white",
                "type": "ReferenceArea",
                "props": {
                    "y1": 30,
                    "y2": 31
                }
            },
            {
                "id": "PHASE_6",
                "name": "Fase 6",
                "description": "DTG of 31\nPenalty of € 25,- per day in this phase\n",
                "backgroundColor": "#800026",
                "color": "white",
                "type": "ReferenceArea",
                "props": {
                    "y1": 31,
                    "y2": 32
                }
            }
        ]

        return (
            <LineChart
                width={this.props.width}
                height={this.props.height}
                data={data}
                margin={{
                    top: 10, right: 30, left: -20, bottom: 20,
                }}
            >
                <CartesianGrid vertical={false} stroke={'#f5f5f5'} />
                <XAxis
                    dataKey="date"
                    tick={props => <CustomXTick {...props} labelFormatter={this.props.interval.tickLabelFormatter} />}
                    type="category"
                    tickMargin={20}
                    interval={this.props.interval.interval}
                    tickLine={false}
                    axisLine={false}
                />
                <YAxis
                    tick={{ fill: '#000', fontSize: 13 }}
                    tickMargin={10}
                    tickLine={false}
                    axisLine={false}
                    domain={[-40, 100]}
                />
                <Tooltip
                    isAnimationActive={false}
                    filterNull={false}
                    offset={40}
                    position={{ y: this.props.height / 2 }}
                    content={<CustomizedTooltip labelFormatter={this.props.interval.tickLabelFormatter} />}
                    // cursor={{ stroke: 'rgba(0,119,255,.05)', strokeWidth: 40 }}
                    cursor={<CustomCursor />}
                    dot={{ stroke: '#fff', strokeWidth: 3, fill: '#0095ff', r: 5, fillOpacity: 1 }}
                />
                <defs>
                    {phases.map(phase => (
                        <linearGradient key={phase.id} id={phase.id} x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset={'0%'} stopColor={phase.backgroundColor} stopOpacity={0} />
                            <stop offset={'3%'} stopColor={phase.backgroundColor} stopOpacity={0.4} />
                            <stop offset={'97%'} stopColor={phase.backgroundColor} stopOpacity={0.4} />
                            <stop offset={'100%'} stopColor={phase.backgroundColor} stopOpacity={0} />
                        </linearGradient>
                    ))}
                </defs>
                {[
                    // { dataKey: 'previous', stroke: '#0095ff', strokeDasharray: '5, 3', strokeWidth: 1 },
                    { dataKey: 'current', stroke: '#0095ff', strokeWidth: 1 },
                    // { dataKey: 'min', stroke: '#0095ff', strokeWidth: 0 },
                    // { dataKey: 'max', stroke: '#0095ff', strokeWidth: 0 },
                ].map((line, index) => (
                    <Line
                        key={index}
                        dataKey={line.dataKey}
                        stroke={line.stroke}
                        strokeDasharray={line.strokeDasharray}
                        strokeWidth={line.strokeWidth}
                        isAnimationActive={false}
                        dot={{ strokeDasharray: '', stroke: '#0095ff', strokeWidth: 2, fill: '#fff', r: 4, fillOpacity: 1 }}
                    />
                ))}
                {/* {data.map((point, index) => {

                    return (
                        <ReferenceLine
                            key={index}
                            stroke={'red'}
                            x={point.date}
                        />
                    )
                })} */}
                <Scatter
                    data={data.map(point => ({
                        fill: 'red',
                        x: 50,
                        y: 50
                    }))}
                />
                {phases.map(phase => {

                    if (phase.type === 'ReferenceLine') {

                        return (
                            <ReferenceLine key={phase.id} y={phase.props.y} stroke={`url(#${phase.id})`} ifOverflow={'extendDomain'} />
                        )
                    }

                    if (phase.type === 'ReferenceArea') {

                        return (
                            <ReferenceArea key={phase.id} y1={phase.props.y1} y2={phase.props.y2} fill={`url(#${phase.id})`} ifOverflow={'extendDomain'} />
                        )
                    }

                    return null
                })}
            </LineChart>
        );
    }
}

const intervals = {
    month: {
        pageUnit: 'month',
        pageLabelFormatter: ({ date }) => {
            return date.format('MMM YY')
        },
        tickUnit: 'day',
        tickLabelFormatter: ({ date }) => {
            return date.format('dd DD MMM')
        },
        interval: 'preserveEnd'
    },
    year: {
        pageUnit: 'year',
        pageLabelFormatter: ({ date }) => {
            return date.format('YYYY')
        },
        tickUnit: 'month',
        tickLabelFormatter: ({ date }) => {
            return date.format('MMM YY')
        },
        interval: 0
    }
}

class Frame extends React.Component {

    constructor(props) {
        super(props)

        const interval = 'year'

        this.state = {
            interval,
            ...this.getPageData(interval)
        }
    }

    getPageData = (intervalId) => {

        const interval = intervals[intervalId]

        let pages = []

        const end = moment().endOf(interval.pageUnit)
        const start = end.clone().subtract(10, interval.pageUnit).startOf(interval.pageUnit)

        const data_points = this.props.data[interval.tickUnit]

        for (var pointer = start.clone(); pointer.isSameOrBefore(end); pointer.add(1, interval.pageUnit)) {

            const page_start = pointer.clone()
            const page_end = pointer.clone().endOf(interval.pageUnit)

            const data = data_points
                .filter(point =>
                    moment(point.date).isSameOrAfter(page_start) && moment(point.date).isSameOrBefore(page_end)
                )
                .map(point => ({
                    ...point,
                    timestamp: new Date(point.date).valueOf(),
                    previous: point.dtg !== null ? point.dtg + random(-10, 10) : null,
                    current: point.dtg,
                    // min: point.dtg !== null ? point.dtg + random(-10, 0) : null,
                    // max: point.dtg !== null ? point.dtg + random(0, 10) : null
                }))

            pages.push({
                id: pointer.toISOString(),
                date: pointer.toDate(),
                name: interval.pageLabelFormatter({ date: pointer.clone() }),
                data
            })
        }

        // console.log(pages)

        return {
            pages,
            page: pages[pages.length - 1].id
        }
    }

    render() {

        const page = this.state.pages.find(page =>
            page.id === this.state.page
        )

        const interval = intervals[this.state.interval]

        return (
            <div>
                <div
                    className={css`
                        position: relative;
                        padding: 40px 40px;   
                    `}
                >
                    <div
                        className={css`
                        display: flex;
                        justify-content: flex-end;
                        margin-bottom: 20px;
                    `}
                    >
                        <SegmentedControl
                            value={this.state.interval}
                            onChange={this.handleChangeInterval}
                            options={[
                                {
                                    id: 'month',
                                    name: 'Maanden'
                                },
                                {
                                    id: 'year',
                                    name: 'Jaren'
                                }
                            ]}
                        />
                    </div>
                    <div
                        className={css`
                            display: flex;
                            justify-content: flex-end;
                        `}
                    >
                        <SegmentedControl
                            value={this.state.page}
                            onChange={this.handleChangePage}
                            options={this.state.pages}
                        />
                    </div>
                </div>
                <div
                    className={css`
                    position: relative;
                    padding: 40px 40px;
                    background-color: #fff;
                    box-shadow: 0 0 1px 0 #ebebeb, 0 0 1px 0 #ebebeb;
                    `}
                >
                    <div>
                        <GraphLegend>
                            <GraphLegendItem
                                title={'Huidige periode'}
                                description={'(december 2019)'}
                            />
                            <GraphLegendItem
                                type={'alt'}
                                title={'Vorige periode'}
                                description={'(december 2018)'}
                            />
                        </GraphLegend>
                    </div>
                    <div
                        className={css`
                            position: relative;
                            height: 500px;
                        `}
                    >
                        <Autosizer>
                            {({ width, height }) => (
                                <Example
                                    interval={interval}
                                    data={page.data}
                                    width={width}
                                    height={height}
                                />
                            )}
                        </Autosizer>
                    </div>
                </div>
            </div>
        )
    }

    handleChangeInterval = ({ value }) => {

        this.setState({
            interval: value,
            ...this.getPageData(value)
        })
    }

    handleChangePage = ({ value }) => {

        this.setState({
            page: value
        })
    }
}

const StatisticsTotalsCardRow = ({ children }) => (
    <div
        className={css`
        flex-flow: row wrap;
        display: flex;
        justify-content: space-between;
        &:not(:last-child):after {
            content: "";
    margin: 15px -15px;
    width: calc(100% + 30px);
    border-bottom: 1px solid #f2f2f2;
        }
        `}
    >
        {children}
    </div>
)

const StatisticsDifferencePercentage = ({ isPositive, isNegative, value }) => (
    <span
        className={css`
                                                color: #b3b3b3;
                                                font-weight: 700;
                                                font-size: 18px;
                                                display: flex;
                                                align-items: center;
                                                color: #b3b3b3;
                                                ${isPositive ? 'color: #4cd964;' : ''}
                                                ${isNegative ? 'color: #ff4a1f;' : ''}
                                                `}
    >
        {isPositive || isNegative ? (
            <span
                className={css`
                                                    border-radius: 50%;
                                                    height: 15px;
                                                    width: 15px;
                                                    font-size: 12px;
                                                    display: inline-block;
                                                    line-height: 1;
                                                    margin-right: 5px;
                                                    text-align: center;
                                                    ${isNegative ? 'background-color: rgba(255,74,31,.1);' : ''}
                                                    ${isPositive ? 'background-color: rgba(76,217,100,.1);' : ''}
                                                    `}
            >
                {isPositive ? '▴' : ''}
                {isNegative ? '▾' : ''}
            </span>
        ) : null}
        <span>{value}</span>
    </span>
)

const StatisticsDifferenceNumber = ({ children }) => (
    <span
        className={css`
        color: #b3b3b3;
        font-size: 16px;
        display: block;
        `}
    >
        {children}
    </span>
)
class DTGChart extends React.Component {

    // render() {

    //     return (
    //         <div>
    //             <Frame />
    //         </div>
    //     )
    // }

    render() {

        return (
            <div>
                <Frame data={this.props.data} />
                <div
                    className={css`
                        position: relative;
                        padding: 40px 40px;   
                    `}
                >
                    <h2
                        className={css`
                            color: #000;
                            font-weight: 700;
                            margin: 0 0 .75em;
                        `}
                    >
                        Totalen
                    </h2>
                    <div>
                        <article
                            className={css`
                            border: 1px solid #f2f2f2;
                            border-radius: 8px;
                            box-shadow: 0 3px 6px rgba(0,0,0,.03);
                            background-color: #fff;
                            line-height: 1.42857;
                            `}
                        >
                            <header
                                className={css`
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    padding: 8px 15px;
                                    border-bottom: 1px solid #f2f2f2;
                                    white-space: nowrap;
                                `}
                            >
                                <h3
                                    className={css`
                                    margin: 0;
                                    color: #000;
                                    font-size: 16px;
                                    `}
                                >
                                    <span>Omzet</span>
                                </h3>
                                <span
                                    className={css`
                                        color: #b3b3b3;
                                        font-size: 12px;
                                    `}
                                >
                                    Transacties
                                </span>
                            </header>
                            <div
                                className={css`
                                    position: relative;
                                    min-height: 85px;
                                    padding: 15px;
                                `}
                            >
                                <div
                                    className={css`
                                    display: flex;
                                    flex-flow: column;
                                    justify-content: flex-start;
                                    min-height: 125px;
                                    `}
                                >
                                    <StatisticsTotalsCardRow>
                                        <span
                                            className={css`
                                                font-weight: 700;
                                                line-height: normal;
                                                font-size: 22px;
                                            `}
                                        >
                                            <span>€&nbsp;27.513,02</span>
                                        </span>
                                        <span
                                            className={css`
                                                line-height: normal;
                                                font-size: 22px;
                                                font-weight: 400;
                                            `}
                                        >
                                            <span>37</span>
                                        </span>
                                    </StatisticsTotalsCardRow>
                                    <div
                                        className={css`
                                        font-weight: 700;
                                        `}
                                    >
                                        <span>Vergeleken met <span>vorig jaar</span></span>
                                    </div>
                                    <StatisticsTotalsCardRow>
                                        <div
                                            className={css`
                                            margin-top: 10px;
                                            `}
                                        >
                                            <StatisticsDifferencePercentage
                                                isPositive={true}
                                                value={'5,1%'}
                                            />
                                            <StatisticsDifferenceNumber>
                                                € 26,3k (+ € 1,2k)
                                            </StatisticsDifferenceNumber>
                                        </div>
                                        <div
                                            className={css`
                                                text-align: right;
                                            `}
                                        >
                                            <StatisticsDifferencePercentage
                                                isNegative={true}
                                                value={'5,1%'}
                                            />
                                            <StatisticsDifferenceNumber>
                                                39 (-2)
                                            </StatisticsDifferenceNumber>
                                        </div>
                                    </StatisticsTotalsCardRow>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        )
    }
}

export default class ContractDTGGraph extends React.Component {

    state = {
        loading: true,
        data: null
    }

    componentDidMount() {
        this.fetch()
    }

    fetch = async () => {

        const response = await api.request({
            method: 'post',
            data: {
                query: `
                    query contract($id: ID!) {
                        viewer {
                            contract(id: $id) {
                                contractDates {
                                    id
                                    date
                                    dtg
                                }
                            }
                        }
                    }
                `,
                variables: {
                    id: this.props.recordId
                }
            }
        })

        const contractDates = response.data.data.viewer.contract.contractDates

        console.log('contract dtg graph', contractDates)

        const data = {
            day: transformToDayDataPoints(contractDates),
            month: transformToMonthDataPoints(contractDates)
        }

        console.log('data', data)

        this.setState({
            loading: false,
            data
        })
    }

    renderContent() {

        if (this.state.loading) {
            return spinner({ width: 24 })
        }

        return (
            <div
                className={css`
                    margin-left: -40px;
                    margin-right: -40px;
                `}
            >
                <DTGChart data={this.state.data} />
            </div>
        )
    }

    render() {

        return (
            <div>
                <h2>
                    {this.props.component.name}
                </h2>
                {this.renderContent()}
            </div>
        )
    }
}