import React from 'react'
import { css } from 'emotion'
import Button from '@pndr/button'
import spinner from '@pndr/spinner'
import api from '../services/api'
import { Link } from 'react-router-dom'

export default class ContractDatesComputeButton extends React.Component {

    state = {
        loading: false
    }

    render() {

        return (
            <div
                className={css`
                max-width: 600px;
                `}
            >
                <div
                    className={css`
                font-weight: bold;
                margin-bottom: 16px;
            `}
                >
                    Contract Meta Objecten
                </div>
                <p>
                    Door op onderstaande knop te drukken wordt in de achtergrond meta data (Contract Dagen, Contract Datum Issues, Contract Maanden, Contract Jaren) voor dit contract berekend. Ga naar de <Link to={'/job-queue'}>job queue</Link> om de voortgang van deze actie te checken.
                </p>
                <Button
                    icon={this.state.loading ? spinner : null}
                    primary
                    onClick={this.handleComputeContractDates}
                >
                    Berekening toevoegen aan job queue
            </Button>
            </div>

        )
    }

    handleComputeContractDates = async () => {

        this.setState({ loading: true })

        await api.request({
            method: 'post',
            data: {
                query: `
                    mutation computeContractDates($contractId: ID!) {
                        computeContractDates(contractId: $contractId)
                    } 
                `,
                variables: {
                    contractId: this.props.recordId
                }
            }
        })

        this.setState({ loading: false })
    }
}