import React from 'react'
import { css } from 'emotion'
import Button from '@pndr/button'
import NumberInput from '@pndr/number-input'

class NumberField extends React.Component {

    state = {
        value: this.props.value
    }

    render() {

        return (
            <div
                className={css`
            display: flex;
            align-items: center;
        `}
            >
                <div>
                    <NumberInput
                        className={css`
                        height: 30px;
                            margin-right: 8px;
                        `}
                        value={this.state.value}
                        onChange={this.handleChange}
                        allowNegative={true}
                    />
                </div>
                <div>
                    <Button
                        primary
                        onClick={this.handleSave}
                    >
                        Opslaan
                    </Button>
                </div>
            </div>
        )
    }

    handleChange = ({ value }) => {

        this.setState({
            value
        })
    }

    handleSave = async () => {

        await this.props.onChange({
            value: this.state.value
        })
    }
}

export default NumberField