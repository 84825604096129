import React from 'react'
import isNil from 'lodash/isNil'
import defaultEmptyRenderer from './defaultEmptyRenderer'

const StatisticsValue = ({ value }) => {

    return (
        <span>
            {isNil(value) ? defaultEmptyRenderer() : value.toFixed(1)}
        </span>
    )
}

export default StatisticsValue