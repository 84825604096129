import React from 'react'
import { css } from 'emotion'
import Title from '../components/Title'
import Box from '../components/Box'
import api from '../services/api'
import Button from '@pndr/button'
import TextInput from '@pndr/text-input'
import spinner from '@pndr/spinner'
import noop from 'lodash/noop'
import DeveloperDatabase from '../components/DeveloperDatabase';
import DeveloperSystemTask from '../components/DeveloperSystemTask'
import PageHeader from '../components/PageHeader'
import Content from '../components/Content'

const parseJSON = input => {
    try {
        return JSON.parse(input)
    } catch (e) {
        // do nothing
        return null
    }
}

class DeveloperSubmitEvent extends React.Component {

    state = {
        submitting: false,
        name: 'Contract:updated',
        payload: JSON.stringify({
            entity: {
                id: 'ctrIWDxjXdTuZN0jA'
            }
        }, null, 2)
    }

    render() {

        return (
            <div>
                <h2>Submit Event</h2>
                <div
                    className={css`
                        margin-bottom: 20px;
                    `}
                >
                    <TextInput
                        value={this.state.name}
                        onChange={this.handleNameChange}
                    />
                </div>
                <div
                    className={css`
                        margin-bottom: 20px;
                    `}
                >
                    <textarea
                        className={css`
                background-color: #fff;
                border: 1px solid #d9d9d9;
                border-radius: 3px;
                color: #191919;
                display: block;
                font-size: 16px;
                line-height: 1.42857;
                padding: 5px 15px;
                -webkit-transition: border-color .15s ease-in-out;
                transition: border-color .15s ease-in-out;
                width: 100%;
                -webkit-appearance: none;
                height: 400px;
                `}
                        value={this.state.payload}
                        onChange={this.handlePayloadChange}
                    />
                </div>
                <Button primary onClick={this.handleSubmitEvent}>
                    Submit
                </Button>
            </div>
        )
    }

    handleNameChange = ({ value }) => {
        this.setState({
            name: value
        })
    }

    handlePayloadChange = (e) => {
        this.setState({
            payload: e.target.value
        })
    }

    handleSubmitEvent = async () => {

        this.setState({
            submitting: true
        })

        await api.request({
            method: 'post',
            data: {
                query: `
                    mutation submitEvent($name: String!, $payload: JSON!) {
                            submitEvent(name: $name, payload: $payload)
                    }
                `,
                variables: {
                    name: this.state.name,
                    payload: parseJSON(this.state.payload)
                }
            }
        })

        this.setState({
            submitting: false
        })
    }
}

const ConnectionBadge = ({ connected }) => (
    <div
        className={css`
            border-radius: 16px;
            background-color: #f2f2f2;
            padding: 8px 16px;
            display: inline-flex;
            align-items: center;
        `}
    >
        <div
            className={css`
                width: 14px;   
                height: 14px;
                border-radius: 50%;
                background-color: ${connected ? '#01FF70' : '#FF4136'};   
            `}
        >

        </div>
        <div
            className={css`
                margin-left: 8px;
            `}
        >
            {connected ? 'connected' : 'disconnected'}
        </div>
    </div>
)
class DeveloperQueueManager extends React.Component {

    state = {
        loading: true,
        starting: false,
        stopping: false,
        data: null
    }

    async componentDidMount() {

        this.fetch()
    }

    fetch = async () => {

        this.setState({
            loading: true
        })

        const response = await api.request({
            method: 'post',
            data: {
                query: `
                    query queueManager {
                    viewer {
                    queueManager {
                    connected
                }
                }
            }
        `
            }
        })

        this.setState({
            loading: false,
            data: response.data.data
        })
    }

    render() {

        return (
            <div>
                <h2>Queue Manager</h2>
                {this.state.loading ? spinner({ width: 24 }) : (
                    <div
                        className={css`
                            margin-bottom: 24px;
                        `}
                    >
                        <ConnectionBadge connected={this.state.data.viewer.queueManager.connected} />
                    </div>
                )}
                <div
                    className={css`
                        display: flex;
                        align-items: center;
                    `}
                >
                    <Button primary onClick={this.handleQueueManagerStart}>
                        Start
                </Button>
                    <div
                        className={css`
                        margin-left: 8px;
                    `}
                    >
                        <Button primary onClick={this.handleQueueManagerStop}>
                            Stop
                </Button>
                    </div>
                </div>
            </div>
        )
    }


    handleQueueManagerStart = async () => {

        this.setState({ starting: true })

        await api.request({
            method: 'post',
            data: {
                query: `
                    mutation startQueueManager {
                    startQueueManager
                }
                `
            }
        })

        await this.fetch()

        this.setState({ starting: false })
    }

    handleQueueManagerStop = async () => {

        this.setState({ stopping: true })

        await api.request({
            method: 'post',
            data: {
                query: `
                    mutation stopQueueManager {
                    stopQueueManager
                }
                `
            }
        })

        await this.fetch()

        this.setState({ stopping: false })
    }
}

class DeveloperMailjetAPI extends React.Component {

    state = {
        attaching: false,
        detaching: false,
        data: null
    }

    async componentDidMount() {

        this.fetch()
    }

    fetch = async () => {

        this.setState({
            loading: true
        })

        const response = await api.request({
            method: 'post',
            data: {
                query: `
                    query mailjet {
                    viewer {
                    mailjet {
                    webhook
                                webhookUrl
            }
        }
    }
`
            }
        })

        this.setState({
            loading: false,
            data: response.data.data
        })
    }

    render() {

        let content = spinner({ width: 24 })

        if (this.state.data) {

            content = (
                <div>
                    <p>
                        Mailjet Webhook URL
                    </p>
                    <div
                        className={css`
                            margin-bottom: 20px;
                        `}
                    >
                        <TextInput
                            id={'mailjetWebhookUrl'}
                            type="text"
                            disabled={true}
                            value={this.state.data.viewer.mailjet.webhookUrl}
                            onChange={noop}
                        />
                    </div>
                    <div
                        className={css`
                            padding: 20px;
                            background-color: #f9f9f9;
                            border-radius: 6px;
                            margin-bottom: 20px;
                        `}
                    >
                        {this.state.data.viewer.mailjet.webhook ? (
                            <pre className={css`overflow: auto;`}>
                                {this.state.data.viewer.mailjet.webhook}
                            </pre>
                        ) : (
                                <div>
                                    Currently not attached
                                </div>
                            )}
                    </div>
                    <div
                        className={css`
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <Button
                            className={css`
                                margin-right: 8px;
                            `}
                            primary
                            disabled={!!this.state.data.viewer.mailjet.webhook}
                            onClick={this.handleAttach}
                        >
                            {this.state.attaching ? 'Loading...' : 'Attach webhook'}
                        </Button>
                        <Button
                            primary
                            disabled={!this.state.data.viewer.mailjet.webhook}
                            onClick={this.handleDetach}
                        >
                            {this.state.detaching ? 'Loading...' : 'Detach webhook'}
                        </Button>
                    </div>
                </div>
            )
        }

        return (
            <div>
                <h2>Mailjet Webhook</h2>
                {content}
            </div>
        )
    }


    handleAttach = async () => {

        this.setState({ attaching: true })

        await api.request({
            method: 'post',
            data: {
                query: `
                    mutation attachMailjetWebhook {
                    attachMailjetWebhook
                }
                `
            }
        })

        await this.fetch()

        this.setState({ attaching: false })
    }

    handleDetach = async () => {

        this.setState({ detaching: true })

        await api.request({
            method: 'post',
            data: {
                query: `
                    mutation detachMailjetWebhook {
                    detachMailjetWebhook
                }
                `
            }
        })

        await this.fetch()

        this.setState({ detaching: false })
    }
}

class DeveloperMoneybirdAPI extends React.Component {

    state = {
        attaching: false,
        detaching: false,
        data: null
    }

    async componentDidMount() {

        this.fetch()
    }

    fetch = async () => {

        this.setState({
            loading: true
        })

        const response = await api.request({
            method: 'post',
            data: {
                query: `
                    query developer {
                    viewer {
                    moneybird {
                    webhook
                                webhookUrl
            }
        }
    }
`
            }
        })

        this.setState({
            loading: false,
            data: response.data.data
        })
    }

    render() {

        let content = spinner({ width: 24 })

        if (this.state.data) {

            content = (
                <div>
                    <p>
                        Moneybird Webhook URL
                    </p>
                    <div
                        className={css`
                            margin-bottom: 20px;
                        `}
                    >
                        <TextInput
                            id={'moneybirdWebhookUrl'}
                            type="text"
                            disabled={true}
                            value={this.state.data.viewer.moneybird.webhookUrl}
                            onChange={noop}
                        />
                    </div>
                    <div
                        className={css`
                            padding: 20px;
                            background-color: #f9f9f9;
                            border-radius: 6px;
                            margin-bottom: 20px;
                        `}
                    >
                        {this.state.data.viewer.moneybird.webhook ? (
                            <pre className={css`overflow: auto;`}>
                                {this.state.data.viewer.moneybird.webhook}
                            </pre>
                        ) : (
                                <div>
                                    Currently not attached
                                </div>
                            )}
                    </div>
                    <div
                        className={css`
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <Button
                            className={css`
                                margin-right: 8px;
                            `}
                            primary
                            disabled={!!this.state.data.viewer.moneybird.webhook}
                            onClick={this.handleAttach}
                        >
                            {this.state.attaching ? 'Loading...' : 'Attach webhook'}
                        </Button>
                        <Button
                            primary
                            disabled={!this.state.data.viewer.moneybird.webhook}
                            onClick={this.handleDetach}
                        >
                            {this.state.detaching ? 'Loading...' : 'Detach webhook'}
                        </Button>
                    </div>
                </div>
            )
        }

        return (
            <div>
                <h2>Moneybird Webhook</h2>
                {content}
            </div>
        )
    }


    handleAttach = async () => {

        this.setState({ attaching: true })

        await api.request({
            method: 'post',
            data: {
                query: `
                    mutation attachMoneybirdWebhook {
                    attachMoneybirdWebhook
                }
                `
            }
        })

        await this.fetch()

        this.setState({ attaching: false })
    }

    handleDetach = async () => {

        this.setState({ detaching: true })

        await api.request({
            method: 'post',
            data: {
                query: `
                    mutation detachMoneybirdWebhook {
                    detachMoneybirdWebhook
                }
                `
            }
        })

        await this.fetch()

        this.setState({ detaching: false })
    }
}

export default class DeveloperRoute extends React.Component {

    render() {

        return (
            <div>
                <Title>
                    Developer
                </Title>
                <PageHeader title={'Developer'} />
                <Content>
                    <Box p={32} mb={50}>
                        <DeveloperDatabase />
                    </Box>
                    <Box p={32} mb={50}>
                        <h2>Import Moneybird Tax Rates</h2>
                        <DeveloperSystemTask typeId={'import_moneybird_tax_rates'} />
                    </Box>
                    <Box p={32} mb={50}>
                        <h2>Fetch Moneybird Data</h2>
                        <DeveloperSystemTask typeId={'fetch_moneybird_data'} />
                    </Box>
                    <Box p={32} mb={50}>
                        <h2>Import Moneybird Data</h2>
                        <DeveloperSystemTask typeId={'import_moneybird_data'} />
                    </Box>
                    <Box p={32} mb={50}>
                        <h2>Download Moneybird Data</h2>
                        <p>For development purposes</p>
                        <Button primary onClick={this.handleDownloadMoneybirdData}>
                            Download
                    </Button>
                    </Box>
                    <Box p={32} mb={50}>
                        <DeveloperMoneybirdAPI />
                    </Box>
                    <Box p={32} mb={50}>
                        <DeveloperMailjetAPI />
                    </Box>
                    <Box p={32} mb={50}>
                        <DeveloperQueueManager />
                    </Box>
                    <Box p={32} mb={50}>
                        <DeveloperSubmitEvent />
                    </Box>
                </Content>
            </div>
        )
    }

    handleDownloadMoneybirdData = async () => {

        await api.request({
            method: 'post',
            data: {
                query: `
                    mutation downloadMoneybirdData {
                        downloadMoneybirdData
                    }
                `
            }
        })
    }
}