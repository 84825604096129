import React from 'react'
import moment from 'moment'
import 'moment/locale/nl'
import { css } from 'emotion'
import api from '../services/api'
import Chart, { intervals } from './Chart'
import ChartLegendItem from './ChartLegendItem'
import ChartLegend from './ChartLegend'
import StandardMonthPreview from './StandardMonthPreview'
import wait from '../utils/wait'

class ContractYearChart extends React.Component {

    state = {
        loading: true,
        data: null,
        chartData: {},
        properties: [
            'dtgMean',
            'dtgMeanPrevYear',
            // 'stdDtgMean',
            // 'dtgMin',
            // 'dtgMax',
        ]
    }

    componentDidMount() {
        this.fetch()
    }

    componentDidUpdate(prevProps) {

        if (prevProps.recordId !== this.props.recordId) {

            this.setState({
                loading: true
            })

            this.fetch()
        }
    }

    fetch = async () => {

        await wait(1000)

        const response = await api.request({
            method: 'post',
            data: {
                query: `
                    query standardYearChart($input: StandardYearChartInput!) {
                        viewer {
                            standardYearChart(input: $input)
                        }
                    }
                `,
                variables: {
                    input: {
                        date: this.props.source.startDate
                    }
                }
            }
        })

        const data = response.data.data.viewer.standardYearChart

        const chartData = data.standardMonths.reduce((result, standardMonth) => {
            result[standardMonth.date] = standardMonth
            return result
        }, {})

        this.setState({
            loading: false,
            data,
            chartData
        })
    }

    tooltipRenderer = ({ source }) => {

        return <StandardMonthPreview
            source={source}
        />
    }

    dataPointGetter = ({ date }) => this.state.chartData[date]

    handlePropertyToggle = ({ dataKey }) => {

        let properties = [...this.state.properties]

        if (properties.includes(dataKey)) {
            properties = properties.filter(i => i !== dataKey)
        } else {
            properties.push(dataKey)
        }

        this.setState({
            properties
        })
    }

    render() {

        const previousPeriod = moment(this.props.source.date).subtract(1, 'year').format('YYYY')

        const startDate = moment(this.props.source.startDate)
        const endDate = moment(this.props.source.endDate)

        const properties = [
            { title: 'Huidige periode', description: `(${this.props.source.name})`, dataKey: 'dtgMean', color: '#0095ff', strokeWidth: 1 },
            { title: 'Vorige periode', description: `(${previousPeriod})`, dashed: true, dataKey: 'dtgMeanPrevYear', color: '#63bfff', strokeDasharray: '5, 3', strokeWidth: 1 },
            { title: 'Laagste DTG', dataKey: 'dtgMin', color: '#4cd964', strokeWidth: 0 },
            { title: 'Hoogste DTG', dataKey: 'dtgMax', color: '#ff4a1f', strokeWidth: 0 }
        ]

        const visibleProperties = properties.filter(property =>
            this.state.properties.includes(property.dataKey)
        )

        return (
            <div
                className={css`
                margin-left: -40px;
                margin-right: -40px;
            `}
            >
                <Chart
                    ref={ref => this.chart = ref}
                    loading={this.state.loading}
                    domain={[-40, 100]}
                    interval={intervals.year}
                    startDate={startDate}
                    endDate={endDate}
                    legend={
                        <ChartLegend>
                            {properties.map(property =>
                                <ChartLegendItem
                                    key={property.dataKey}
                                    title={property.title}
                                    active={this.state.properties.includes(property.dataKey)}
                                    dashed={property.dashed}
                                    color={property.color}
                                    description={property.description}
                                    onClick={() => this.handlePropertyToggle({ dataKey: property.dataKey })}
                                />
                            )}
                        </ChartLegend>
                    }
                    lines={visibleProperties}
                    dataPointGetter={this.dataPointGetter}
                    tooltipRenderer={this.tooltipRenderer}
                />
            </div>
        )
    }
}

export default ContractYearChart