import React from 'react'
import { css } from "emotion"
import Button from '@pndr/button'
import TextareaAutoResize from "react-textarea-autosize";
import arrowDown from '@pndr/icons/lib/arrowDown'
import spinner from '@pndr/spinner'
import api from '../services/api'

const TextArea = ({ placeholder, autoFocus, value, onChange }) => {

    const handleChange = e => onChange({ e, value: e.target.value })

    return <TextareaAutoResize
        className={css`
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 6px;
        padding: 6px;
        font-size: 16px;
        resize: none;
    `}
        type="text"
        autoFocus={autoFocus}
        value={value}
        minRows={3}
        placeholder={placeholder}
        onChange={handleChange}
    />
}


const bugTemplate = `**Describe the bug**
A clear and concise description of what the bug is.

**To Reproduce**
Steps to reproduce the behavior:
1. Go to '...'
2. Click on '....'
3. Scroll down to '....'
4. See error

**Expected behavior**
A clear and concise description of what you expected to happen.

**Additional context**
Add any other context about the problem here.
`

class FeedbackDialog extends React.Component {

    state = {
        text: '',
        open: false,
        loading: false
    }

    handleTextChange = ({ value }) => {

        console.log('handle text change', value)

        this.setState({
            text: value
        })
    }

    handleToggleOpen = () => this.setState({ open: !this.state.open })

    handleSend = async () => {

        this.setState({
            loading: true
        })

        await api.request({
            method: 'post',
            data: {
                query: `
                    mutation sendFeedback($url: String, $title: String, $text: String!) {
                        sendFeedback(url: $url, title: $title, text: $text)
                    } 
                `,
                variables: {
                    url: window.location.href,
                    title: document.title,
                    text: this.state.text
                }
            }
        })

        this.setState({
            loading: false,
            text: ''
        })
    }

    render() {

        return (
            <div
                className={css`
                    position: fixed;
                    bottom: 0;
                    right: 15px;
                `}
            >
                <div
                    className={css`
                        width: 400px;
                        border-top-left-radius: 6px;
                        border-top-right-radius: 6px;
                        border-top: 2px solid #ddd;
                        border-left: 2px solid #ddd;
                        border-right: 2px solid #ddd;
                        background-color: #fff;
                    `}
                >
                    <div
                        className={css`
                            display: flex;
                            align-items: center;
                            font-size: 16px;
                            font-weight: bold;
                            padding: 8px 16px;
                            user-select: none;
                            cursor: pointer;
                        `}
                        onClick={this.handleToggleOpen}
                    >
                        <div
                            className={css`
                                flex-grow: 1;
                            `}
                        >
                            Feedback?
                        </div>
                        <div
                            className={css`
                                width: 25px;
                                height: 25px;
                                border-radius: 6px;
                                background-color: #ccc;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            `}
                        >
                            {this.state.open ? arrowDown({ width: 14 }) : arrowDown({ width: 14, className: css`transform: rotate(180deg)` })}
                        </div>
                    </div>
                    {this.state.open ? (
                        <div
                            className={css`
                            padding: 16px;
                        `}
                        >
                            <div
                                className={css`
                                margin-bottom: 16px;
                            `}
                            >
                                <div
                                    className={css`
                                        font-weight: bold;
                                        margin-bottom: 8px;
                                    `}
                                >
                                    Pagina title
                                </div>
                                {document.title}
                            </div>
                            <div
                                className={css`
                                margin-bottom: 16px;
                            `}
                            >
                                <div
                                    className={css`
                                        font-weight: bold;
                                        margin-bottom: 8px;
                                    `}
                                >
                                    Pagina url
                                </div>
                                {window.location.href}
                            </div>
                            <div
                                className={css`
                                margin-bottom: 16px;
                            `}
                            >
                                <div
                                    className={css`
                                        font-weight: bold;
                                        margin-bottom: 8px;
                                    `}
                                >
                                    Bericht
                                </div>
                                <TextArea
                                    autoFocus={true}
                                    value={this.state.text}
                                    onChange={this.handleTextChange}
                                />
                                <div
                                    className={css`
                                        text-decoration: underline;
                                        color: #000;
                                        cursor: pointer;
                                        &:hover {
                                            opacity: 0.7;
                                        }
                                    `}
                                    onClick={() => this.setState({ text: bugTemplate })}
                                >
                                    Gebruik Bug-template
                                </div>
                            </div>
                            <div
                                className={css`
                                display: flex;
                            `}
                            >
                                <div
                                    className={css`
                                    margin-left: auto;
                                `}
                                >
                                    <Button
                                        icon={this.state.loading ? spinner : null}
                                        onClick={this.handleSend}
                                        disabled={this.state.text.length === 0}
                                    >
                                        {this.state.loading ? 'Verzenden...' : 'Verzenden'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        )
    }
}

export default FeedbackDialog