import React from 'react'
import moment from 'moment'
import StatisticsPreview from './StatisticsPreview'

const StandardDatePreview = ({ source }) => (
    <StatisticsPreview
        title={source.name}
        description={moment(source.date).subtract(1, 'year').format('D MMMM YYYY')}
        rows={[
            {
                title: 'DTG',
                type: 'Difference',
                value: source.dtgMean,
                prev: source.dtgMeanPrevYear,
                prevDiff: source.dtgMeanPrevYearDiff,
                prevDiffPct: source.dtgMeanPrevYearDiffPct,
                reverse: true
            }
        ]}
    />
)

export default StandardDatePreview