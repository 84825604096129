import React from 'react'
import { css } from 'emotion'
import Title from '../components/Title'
import Box from '../components/Box'
import api from '../services/api'
import Switch from '@pndr/switch'
import spinner from '@pndr/spinner'
import PageHeader from '../components/PageHeader'
import Content from '../components/Content'

class Option extends React.Component {

    state = {
        loading: true,
        value: false
    }

    async componentDidMount() {
        await this.fetch()
    }

    fetch = async () => {

        this.setState({
            loading: true
        })

        const response = await api.request({
            method: 'post',
            data: {
                query: `
                    query option($name: String!) {
                        viewer {
                            option(name: $name)
                        }
                    }
                `,
                variables: {
                    name: this.props.id
                }
            }
        })

        this.setState({
            loading: false,
            value: response.data.data.viewer.option
        })
    }

    render() {

        return (
            <div
                className={css`
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                `}
            >
                <div>
                    <Switch
                        value={this.state.value}
                        width={32}
                        onChange={this.handleChange}
                        disabled={this.state.loading}
                    />
                </div>
                <div
                    className={css`
                        margin-left: 16px;
                    `}
                >
                    {this.props.description} <span className={css`font-size: 11px; opacity: 0.5;`}>{this.props.id}</span>
                </div>
                {this.state.loading ? (
                    <div
                        className={css`
                        margin-left: 16px;
                    `}
                    >
                        {spinner({ width: 16 })}
                    </div>
                ) : null}
            </div>
        )
    }


    handleChange = async ({ value }) => {

        this.setState({ loading: true })

        this.setState({
            value
        })

        await api.request({
            method: 'post',
            data: {
                query: `
                    mutation setOption($name: String!, $value: Mixed) {
                        setOption(name: $name, value: $value)
                    } 
                `,
                variables: {
                    name: this.props.id,
                    value
                }
            }
        })

        this.setState({ loading: false })
    }
}

export default class SettingsRoute extends React.Component {

    state = {
        clearing: false
    }

    render() {

        return (
            <div>
                <Title>
                    Instellingen
                </Title>
                <PageHeader title={'Instellingen'} />
                <Content>
                    <Box p={32}>
                        <Option
                            id={'LINK_FINANCIAL_MUTATIONS_ENABLED'}
                            description={'Transacties afkomstig uit Moneybird koppelen.'}
                        />
                        <Option
                            id={'MAIL_PUBLIC_MANUAL_ENABLED'}
                            description={'Publieke mail handmatig versturen. Als deze optie aan staat worden mails die gemarkeerd staan als publiek (bijvoorbeeld mails gericht aan klanten) worden niet automatisch verstuurd. De mail kan alsnog handmatig verstuurd worden door op de versturen knop te klikken.'}
                        />
                        <Option
                            id={'MAIL_CUSTOMERS_ENABLED'}
                            description={'Mails versturen naar klanten. Als dit uit staat worden alle mails verstuurd aan klanten aan dev@lesautodeal.nl verstuurd in plaats van de klant zelf.'}
                        />
                        <Option
                            id={'MAIL_ENABLED'}
                            description={'Mails versturen. Als deze optie uit staat worden alle e-mails niet verstuurd.'}
                        />
                           <Option
                            id={'BACKUP_ENABLED'}
                            description={'Backups maken naar TransIP Stack.'}
                        />
                           <Option
                            id={'PENALTY_INVOICES_TO_MONEYBIRD_ENABLED'}
                            description={'Boete facturen aanmaken in Moneybird.'}
                        />
                             <Option
                            id={'SEND_EMPTY_MAILS'}
                            description={'Mails versturen die geen relevante data bevatten, puur ter bevestiging. Dit geldt voor ISSUE_DIGEST en UNPROCESSED_TRANSACTION_STATUS_UPDATE.'}
                        />
                    </Box>
                </Content>
            </div>
        )
    }
}