import React from 'react'
import { css } from 'emotion'
import LoadingOverlay from './LoadingOverlay'
import NumberField from './NumberField'
import api from '../services/api'

class SetPhaseOffsetForContract extends React.Component {

    state = {
        loading: false
    }

    render() {

        return (
            <div className={css`position:relative;`}>
                <div
                    className={css`
                font-weight: bold;
                margin-bottom: 16px;
            `}
                >
                    Fase offset aanpassen
                </div>
                <p>
                    Dit contract heeft een fase offset van <strong>{this.props.phaseOffset}</strong>. De atg (achterstandstraject dag) komt daardoor uit op <strong>{this.props.dtg + this.props.phaseOffset}</strong>.
                    Wil je dat dit contract eerder in een achterstandstraject komt? Pas dan een positieve fase offset toe, anderszijds een negatieve fase offset.
                </p>
                <NumberField
                    value={this.props.phaseOffset}
                    onChange={this.handlePhaseOffsetChange}
                />
                {this.state.loading ? (
                    <LoadingOverlay />
                ) : null}
            </div>
        )
    }

    handlePhaseOffsetChange = async ({ value: phaseOffset }) => {

        this.setState({
            loading: true
        })

        await api.request({
            method: 'post',
            data: {
                query: `
                    mutation setPhaseOffsetForContract($id: ID!, $phaseOffset: Int!) {
                        setPhaseOffsetForContract(id: $id, phaseOffset: $phaseOffset) {
                            id
                            phaseOffset
                        }
                    } 
                `,
                variables: {
                    id: this.props.contractId,
                    phaseOffset
                }
            }
        })

        await this.props.onPageRefresh()
    }
}

export default SetPhaseOffsetForContract