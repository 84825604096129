import React from 'react'
import signout from '@sublayer/passport-components/lib/signout'
import LoadingState from "../components/LoadingState";

export default class SignOutRoute extends React.Component {

    componentDidMount() {

        setTimeout(signout, 1000)
    }

    render() {
        return <LoadingState/>
    }
}