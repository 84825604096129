import React from 'react'
import pascalcase from 'pascalcase'
import RecordPage from '../components/RecordPage'

export default class RecordRoute extends React.Component {

    render() {

        return (
            <RecordPage
                tableId={pascalcase(this.props.match.params.tableId)}
                recordId={this.props.match.params.recordId}
                {...this.props}
            />
        )
    }
}