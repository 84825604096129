import React from 'react'
import moment from 'moment'
import StatisticsPreview from './StatisticsPreview'

const ContractMonthPreview = ({ source }) => (
    <StatisticsPreview
        title={source.name}
        description={moment(source.startDate).subtract(1, 'month').format('MMMM YYYY')}
        rows={[
            {
                title: 'Gemiddelde DTG',
                type: 'Difference',
                value: source.dtgMean,
                prev: source.dtgMeanPrev,
                prevDiff: source.dtgMeanDiff,
                prevDiffPct: source.dtgMeanDiffPct,
                reverse: true
            },
            {
                title: 'Max',
                type: 'Default',
                value: source.dtgMax
            },
            {
                title: 'Min',
                type: 'Default',
                value: source.dtgMin
            },
            {
                title: 'LesautoDeal Gemiddelde',
                type: 'Default',
                value: source.stdDtgMean
            }
        ]}
    />
)

export default ContractMonthPreview