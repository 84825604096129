import React from 'react'
import moment from 'moment'
import StatisticsPreview from './StatisticsPreview'

const ContractDatePreview = ({ source }) => (
    <StatisticsPreview
        title={source.name}
        description={moment(source.date).subtract(1, 'year').format('D MMMM YYYY')}
        rows={[
            {
                title: 'DTG',
                type: 'Difference',
                value: source.dtg,
                prev: source.dtgPrevYear,
                prevDiff: source.dtgPrevYearDiff,
                prevDiffPct: source.dtgPrevYearDiffPct,
                reverse: true
            }
        ]}
    />
)

export default ContractDatePreview