import React from 'react'
import { cx, css } from 'emotion'
import fieldRenderer from './fieldRenderer'
import icons from '../icons'
import FieldTooltip from './FieldTooltip'
import defaultEmptyRenderer from './defaultEmptyRenderer';
import GridView from './GridView'
import RecordDialog from './RecordDialog'

export default class Table extends React.Component {

    cursor = {
        columnIndex: null,
        rowIndex: null
    }

    state = {
        columnIndex: null,
        rowIndex: null
    }

    render() {

        const { rows, fields } = this.props

        return (
            <React.Fragment>
                <GridView
                    ref={'gridView'}
                    headerCellRenderer={this.headerCellRenderer}
                    bodyCellRenderer={this.bodyCellRenderer}
                    rowCount={rows.length}
                    columnCount={fields.length + 1}
                    columnWidth={this.columnWidthGetter}
                />
                {this.state.expandRecordId ? (
                    <RecordDialog
                        tableId={this.props.tableId}
                        recordId={this.state.expandRecordId}
                        onClose={this.handleCollapseRecord}
                    />
                ) : null}
            </React.Fragment>
        )
    }

    columnWidthGetter = ({ index }) => {

        const field = this.props.fields[index]

        return field.columnWidth ? field.columnWidth : 220
    }

    handleCollapseRecord = () => {
        this.setState({
            expandRecordId: null
        })
    }

    handleRecordClick = ({ recordId }) => {

        if (this.props.onRecordClick) {
            this.props.onRecordClick({ recordId })
        }
    }

    handleMouseEnter = ({ columnIndex, rowIndex }) => {

        this.cursor = {
            columnIndex,
            rowIndex
        }


        this.update()
    }

    update = () => {
        this.refs.gridView.update()
    }

    bodyCellRenderer = ({ columnIndex, rowIndex, key, style }) => {

        const record = this.props.rows[rowIndex]

        const hover = rowIndex === this.cursor.rowIndex

        if (columnIndex === 0) {
            return (
                <div className={cx("cell", { hover })} key={key} style={style} onMouseEnter={() => this.handleMouseEnter({ rowIndex, columnIndex })} onClick={() => this.handleRecordClick({ recordId: record.id })}>
                    <div
                        onClick={e => {
                            e.stopPropagation()
                            this.handleExpandRecord({ recordId: record.id })
                        }}
                        className={css`
                        border-radius: 50%;
                            width: 20px;   
                            height: 20px;   
                            color: #2750ae;
                            position: absolute;
                            background-color: transparent;
                            top: 15px;
                            left: 4px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            opacity: ${hover ? '1' : '0'};
                            transition: 200ms ease opacity, 200ms ease background;
                            &:hover {
                                background-color: #d0f0fd;
                            }
                        `}
                    >
                        {icons.expand({ width: 12 })}
                    </div>
                </div>
            )
        }

        return this._bodyCellRenderer({ columnIndex: columnIndex - 1, rowIndex, key, style })
    }

    handleExpandRecord = ({ recordId }) => {

        this.setState({
            expandRecordId: recordId
        })
    }

    _bodyCellRenderer = ({ columnIndex, key, rowIndex, style }) => {

        const record = this.props.rows[rowIndex]

        const field = this.props.fields[columnIndex]

        const value = record[field.id]

        const content = value === null ? defaultEmptyRenderer() : fieldRenderer({ type: 'tablePage' })({ field, onPageRefresh: this.props.onPageRefresh })(value, record)

        const hover = rowIndex === this.cursor.rowIndex

        return (
            <div className={cx("cell", { hover })} key={key} style={style} onMouseEnter={() => this.handleMouseEnter({ rowIndex, columnIndex })} onClick={() => this.handleRecordClick({ recordId: record.id })}>
                {content}
            </div>
        )
    }

    headerCellRenderer = ({ columnIndex, key, style }) => {

        if (columnIndex === 0) {
            return (
                <div className={'headerCell'} key={key} style={style}>

                </div>
            )
        }

        return this._headerCellRenderer({ columnIndex: columnIndex - 1, key, style })
    }

    _headerCellRenderer = ({ columnIndex, key, style }) => {

        const field = this.props.fields[columnIndex]

        return (
            <div className={'headerCell'} key={key} style={style}>
                <FieldTooltip field={field} placement={'bottom'}>
                    {field.name}
                </FieldTooltip>
            </div>
        )
    }
}