import React from 'react'
import { css } from 'emotion'
import api from '../services/api'
import Box from '../components/Box'
import ContractDatesComputeButton from './ContractDatesComputeButton'
import SetPhaseOffsetForContract from '../components/SetPhaseOffsetForContract'
export default class ContractActions extends React.Component {

    state = {
        loading: false
    }

    render() {

        return (
            <Box p={32}>
                <div
                    className={css`
                            margin-bottom: 32px;
                        `}
                >
                    <SetPhaseOffsetForContract
                        dtg={this.props.source.dtg}
                        phaseOffset={this.props.source.phaseOffset}
                        contractId={this.props.source.id}
                        onPageRefresh={this.props.onPageRefresh}
                    />
                </div>
                <ContractDatesComputeButton
                    {...this.props}
                />
            </Box>
        )
    }

    handlePhaseOffsetChange = async ({ value: phaseOffset }) => {

        this.setState({
            loading: true
        })

        await api.request({
            method: 'post',
            data: {
                query: `
                    mutation setPhaseOffsetForContract($id: ID!, $phaseOffset: Int!) {
                        setPhaseOffsetForContract(id: $id, phaseOffset: $phaseOffset) {
                            id
                            phaseOffset
                            relativeDtg
                        }
                    } 
                `,
                variables: {
                    id: this.props.recordId,
                    phaseOffset
                }
            }
        })

        await this.props.onPageRefresh()
    }
}