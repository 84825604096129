import React from 'react'
import {css} from 'emotion'
import spinner from '@pndr/spinner'

const LoadingState = props => (
    <div
        className={css`
            display: flex;
            justify-content: center;
            padding-top: 200px;
        `}
        {...props}
    >
        {spinner({width: 40})}
    </div>
)

export default LoadingState