import React from 'react'
import Button from '@pndr/button'
import spinner from '@pndr/spinner'
import api from '../services/api'

export default class ContractDateIssueIgnoreButton extends React.Component {

    state = {
        loading: false
    }

    render() {

        if (this.props.stateId === 'resolved') {
            return null
        }

        return (
            <Button
                icon={this.state.loading ? spinner : () => this.props.stateId === 'open' ? '🚫 ' : '⚠️ '}
                onClick={this.handleIgnoreContractDateIssue}
            >
                {this.props.stateId === 'open' ? 'Negeren' : 'Heropenen'}
            </Button>
        )
    }

    handleIgnoreContractDateIssue = async (e) => {

        e.stopPropagation()

        this.setState({ loading: true })

        await api.request({
            method: 'post',
            data: {
                query: `
                    mutation ignoreContractDateIssue($id: ID!) {
                        ignoreContractDateIssue(id: $id)
                    } 
                `,
                variables: {
                    id: this.props.id
                }
            }
        })

        this.setState({ loading: false })

        await this.props.onPageRefresh()
    }
}