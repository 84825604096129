import React from 'react'
import DifferenceCard from './DifferenceCard'

const StandardMonthDTGCard = ({ component, source }) => (
    <DifferenceCard
        title={component.name}
        value={source.dtgMean}
        differences={[
            {
                title: 'Vergeleken met dezelfde maand vorig jaar',
                prev: source.dtgMeanPrevYear,
                diff: source.dtgMeanPrevYearDiff,
                diffPct: source.dtgMeanPrevYearDiffPct,
                reverse: true
            }
        ]}
    />
)

export default StandardMonthDTGCard