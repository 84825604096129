import React from "react";
import { css, cx } from "emotion";

export const Tab = ({ index, active, onClick, children }) => (
    <div
        className={cx(
            css`
        -ms-flex: 0 0 auto;
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        margin: 4px 4px;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      `
        )}
        onClick={onClick}
    >
        <button
            type="button"
            className={cx(
                css`
          -moz-user-select: none;
          -ms-user-select: none;
          -webkit-transition: background-color 0.1s ease-out;
          -webkit-user-select: none;
          background: #fff;
          border: 0;
          border: 1px solid #ebebeb;
          border-bottom: 2px solid #ebebeb;
          border-radius: 6px;
          color: #4d4d4d;
          display: block;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 38px;
          padding: 0 16px;
          text-align: center;
          text-decoration: none;
          transition: background-color 0.1s ease-out;
          user-select: none;
          white-space: nowrap;
          cursor: pointer;
          &:focus {
            outline: 0;
          }
          &:hover {
            background-color: rgba(0, 119, 255, 0.05);
            border-color: #07f;
            color: #07f;
          }
        `,
                active
                    ? css`
                    background-color: rgba(0, 119, 255, 0.05);
              border-color: #07f;
              color: #07f;
              position: relative;
            `
                    : null
            )}
        >
            {children}
        </button>
    </div>
);

export const TabList = ({ children }) => (
    <div
        className={css`
      -ms-flex: 1 1;
      -webkit-box-flex: 1;
      flex: 1 1;
      list-style-type: none;
      padding: 0;
      position: relative;
      display: flex;
      flex-wrap: wrap;
    `}
    >
        {children}
    </div>
);
