import React from 'react'
import { connect } from 'react-redux'
import { css } from 'emotion'
import Box from './Box'
import fieldRenderer from './fieldRenderer'
import defaultEmptyRenderer from './defaultEmptyRenderer';
import FieldTooltip from './FieldTooltip'

class DetailTable extends React.Component {

    render() {

        let fields = this.props.table.get('fields').toJS()

        if (this.props.fields) {

            fields = this.props.fields.map(fieldId =>
                fields.find(field => field.id === fieldId)
            )
        }

        return (
            <Box>
                <table
                    className={css`
                    width: 100%;
                    table-layout: fixed;
                    border-collapse: collapse;
                `}
                >
                    <tbody>
                        {fields.map((field, index) => {

                            const record = this.props.data

                            const value = record[field.id]

                            const content = value === null ? defaultEmptyRenderer() : fieldRenderer({ type: 'recordPage' })({ field, onPageRefresh: this.props.onPageRefresh })(value, record)

                            return (
                                <tr
                                    key={field.name}
                                >
                                    <th
                                        className={css`
                                    text-align: left;
                                    ${index !== 0 ? 'border-top: 1px solid #f2f2f2;' : ''}
                                    ${index !== fields.length - 1 ? 'border-bottom: 1px solid #f2f2f2;' : ''}
                                    padding: 11px 16px;
                                    vertical-align: top;
                                    color: #000;
                                    font-weight: 700;
                                    width: 30%;
                                    font-size: 14px;
                                `}
                                        height={40}
                                    >
                                        <FieldTooltip field={field}>{field.name}</FieldTooltip>
                                    </th>
                                    <td
                                        className={css`
                                        ${index !== 0 ? 'border-top: 1px solid #f2f2f2;' : ''}
                                        ${index !== fields.length - 1 ? 'border-bottom: 1px solid #f2f2f2;' : ''}
                                    padding: 8px 16px;
                                    color: #737373;
                                `}
                                        height={40}
                                    >
                                        <div
                                            className={css`
                                            position: relative;
                                                display: flex;
                                                align-items: center;
                                                width: 100%;
                                                height: 100%;
                                                font-size: 14px;
                                            `}
                                        >
                                            {content}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </Box>
        )
    }
}

export default connect((state, props) => {

    return {
        table: state.getIn(['tablesById', props.tableId])
    }
})(DetailTable)