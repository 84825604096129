import React from 'react'
import isNil from 'lodash/isNil'
import defaultEmptyRenderer from "./defaultEmptyRenderer"

const StatisticsDiff = ({ value }) => {

    const isPositive = value > 0
    const isNegative = value < 0
    const text = `${isPositive ? '+' : isNegative ? '-' : ''}${Math.abs(value).toFixed(1)}`

    return (
        <span>{isNil(value) ? defaultEmptyRenderer() : text}</span>
    )
}

export default StatisticsDiff