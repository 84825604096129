import React, { useState } from 'react'
import { css } from 'emotion'
import { Manager, Reference, Popper } from 'react-popper';
import Portal from './Portal'

const Badge = ({ color, name, backgroundColor }) => (
    <div
        className={css`
            background-color: ${backgroundColor};
            padding: 2px 8px;
            font-size: 85%;
            border-radius: 99999px;
            display: inline-block;
            color: ${color};
            font-weight: 700;
        `}
    >
        {name}
    </div>
)

const Tooltip = ({ children, placement }) => (
    <div
        className={css`
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 4px 11px;
        border-radius: 3px;
        overflow: hidden;
        min-width: 250px;
        max-width: 400px;
        ${placement === 'bottom' ? 'margin-top: 10px;' : ''}
        ${placement === 'top' ? 'margin-bottom: 10px;' : ''}
        ${placement === 'left' ? 'margin-right: 10px;' : ''}
        ${placement === 'right' ? 'margin-left: 10px;' : ''}
        `}
    >
        {children}
    </div>
)

const SingleSelectCell = ({ field, value }) => {

    const [hover, setHover] = useState(false)

    const handleMouseEnter = () => setHover(true)
    const handleMouseLeave = () => setHover(false)

    const option = field.settings.options.find(option => option.id === value)

    if (!option) {
        return (
            <span className={css`font-size: 11px;`}>
                Option with value {value} not found
            </span>
        )
    }

    return (
        <Manager>
            <Reference>
                {({ ref }) => (
                    <div
                        ref={ref}
                        onMouseOver={handleMouseEnter}
                        onMouseOut={handleMouseLeave}
                    >
                        <Badge backgroundColor={option.backgroundColor} color={option.color} name={option.name} />
                    </div>
                )}
            </Reference>
            {hover ? (
                <Popper placement={'top'}>
                    {({ ref, style, placement, arrowProps }) => (
                        <Portal>
                            <div ref={ref} style={style} data-placement={placement} className={css`z-index:1300;`}>
                                <Tooltip placement={placement}>
                                    <div
                                        className={css`
                                            padding: 16px;
                                        `}
                                    >
                                        <div
                                            className={css`
                                                margin-bottom: 8px;
                                            `}
                                        >
                                            <Badge backgroundColor={option.backgroundColor} color={option.color} name={option.name} />
                                        </div>
                                        <div>
                                            {option.description}
                                        </div>
                                    </div>
                                </Tooltip>
                            </div>
                        </Portal>
                    )}
                </Popper>
            ) : null}
        </Manager>
    )
}

export const singleSelect = ctx => ({ field }) => value => <SingleSelectCell field={field} value={value} />