import React from 'react'
import DifferenceCard from './DifferenceCard'

const ContractMonthDebtorTrajectoryDayCountCard = ({ component, source }) => (
    <DifferenceCard
        title={component.name}
        value={source.debtorTrajectoryDayCount}
        differences={[
            {
                title: 'Vergeleken met dezelfde maand vorig jaar',
                prev: source.debtorTrajectoryDayCountPrevYear,
                diff: source.debtorTrajectoryDayCountPrevYearDiff,
                diffPct: source.debtorTrajectoryDayCountPrevYearDiffPct,
                reverse: true
            },
            {
                title: 'Vergeleken met LesautoDeal gemiddelde',
                prev: source.stdDebtorTrajectoryDayCount,
                diff: source.stdDebtorTrajectoryDayCountDiff,
                diffPct: source.stdDebtorTrajectoryDayCountDiffPct,
                reverse: true
            }
        ]}
    />
)

export default ContractMonthDebtorTrajectoryDayCountCard