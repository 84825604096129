import React from 'react'
import { css } from 'emotion'
import Button from '@pndr/button'
import spinner from '@pndr/spinner'
import OutputConsole from './OutputConsole'
import ProgressBar from './ProgressBar'
import api from '../services/api'

class SystemTaskMonitor extends React.Component {

    interval = null

    state = {
        loading: true,
        data: null
    }

    componentDidMount() {
        this.startInterval()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.startInterval()
        }
    }

    componentWillUnmount() {
        this.stopInterval()
    }

    startInterval = () => {
        this.interval = setInterval(this.fetch, 1000)
    }

    stopInterval = () => {

        if (this.interval) {
            clearInterval(this.interval)
            this.interval = null
        }
    }

    fetch = async () => {

        const response = await api.request({
            method: 'post',
            data: {
                query: `
                    query systemTask($id: ID!) {
                        viewer {
                            systemTask(id: $id) {
                                id
                                stateId
                                progress
                                output
                            }
                        }
                    }
                `,
                variables: {
                    id: this.props.id
                }
            }
        })

        const systemTask = response.data.data.viewer.systemTask

        this.setState({
            loading: false,
            data: systemTask
        })

        if (systemTask.stateId !== 'running') {
            this.stopInterval()
            this.props.onCompleted()
            return
        }
    }

    render() {

        if (this.state.loading) {

            return (
                <div>
                    {spinner({ width: 24 })}
                </div>
            )
        }

        return (
            <div>
                <div
                    className={css`
                        margin-bottom: 16px;
                    `}
                >
                    <ProgressBar percentage={this.state.data.progress} />
                </div>
                <div
                    className={css`
                        margin-bottom: 16px;
                    `}
                >
                    <OutputConsole
                        value={this.state.data.output}
                    />
                </div>
            </div>
        )
    }
}

export default class DeveloperSystemTask extends React.Component {

    state = {
        running: false,
        id: null,
        lastSystemTask: null,
        loading: true
    }

    async componentDidMount() {
        await this.fetch()
    }

    fetch = async () => {

        const response = await api.request({
            method: 'post',
            data: {
                query: `
                    query lastSystemTask($typeId: ID!) {
                        viewer {
                            lastSystemTask(typeId: $typeId) {
                                id
                                completedAt
                            }
                        }
                    }
                `,
                variables: {
                    typeId: this.props.typeId
                }
            }
        })

        this.setState({
            loading: false,
            lastSystemTask: response.data.data.viewer.lastSystemTask
        })
    }

    render() {

        return (
            <div>
                {this.state.id ? (
                    <SystemTaskMonitor
                        id={this.state.id}
                        onCompleted={this.handleCompleted}
                    />
                ) : this.state.loading ? (
                    (
                        <div className={css`margin-bottom: 20px;`}>
                            {spinner({ width: 24 })}
                        </div>
                    )
                ) : (
                            <div className={css`margin-bottom: 20px;`}>
                                {this.state.lastSystemTask ? (
                                    <div>
                                        This task last completed on {this.state.lastSystemTask.completedAt}
                                    </div>
                                ) : (
                                        <div>
                                            This task hasn't run before.
                                        </div>
                                    )}
                            </div>
                        )}
                <Button primary onClick={this.handleRun} disabled={this.state.running}>
                    {this.state.running ? 'Running...' : 'Run'}
                </Button>
            </div>
        )
    }

    handleCompleted = () => {

        this.setState({
            running: false
        })
    }

    handleRun = async () => {

        this.setState({ running: true })

        const response = await api.request({
            method: 'post',
            data: {
                query: `
                    mutation createSystemTask($typeId: ID!) {
                        createSystemTask(typeId: $typeId) {
                            id
                        }
                    } 
                `,
                variables: {
                    typeId: this.props.typeId
                }
            }
        })

        this.setState({
            id: response.data.data.createSystemTask.id
        })
    }
}
