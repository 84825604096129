import React from 'react'
import { css } from 'emotion'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { githubGist } from 'react-syntax-highlighter/dist/esm/styles/hljs';

export default ({ value }) => (
    <SyntaxHighlighter
        language="json"
        style={githubGist}
        className={css`
    background-color: #fff;
    border-radius: 6px;
    overflow: auto;
    max-height: 400px;
    border: 1px solid #f2f2f2;
`}
    >
        {value ? JSON.stringify(value, null, 2) : null}
    </SyntaxHighlighter>
)