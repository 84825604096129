import React from 'react'
import { css } from 'emotion'
import Card from './Card'
import StatisticsDiff from './StatisticsDiff'
import StatisticsDiffPct from './StatisticsDiffPct'
import StatisticsValue from './StatisticsValue'

const DifferenceCard = ({ title, value, differences }) => (
    <Card>
        <div
            className={css`
                display: flex;
                align-items: center;
                padding: 8px 15px;
                border-bottom: 1px solid #f2f2f2;
            `}
        >
            <div
                className={css`
                    font-weight: 600;
                    font-size: 16px;
                `}
            >
                {title}
            </div>
        </div>
        <div
            className={css`
                display: flex;
                align-items: center;
                padding: 16px 15px;
                border-bottom: 1px solid #f2f2f2;
            `}
        >
            <div
                className={css`
                    font-weight: 700;
                    font-size: 22px;
                `}
            >
                <StatisticsValue value={value} />
            </div>
        </div>
        <div
            className={css`
                display: flex;
                align-items: center;
            `}
        >
            {differences.map((difference, index) =>
                <div
                    key={index}
                    className={css`
                width: 50%;
                border-left: 1px solid #f2f2f2;
                &:first-of-type {
                    border-left: none;
                }
            `}
                >
                    <div
                        className={css`
                display: flex;
                align-items: center;
                padding: 10px 15px;
                border-bottom: 1px solid #f2f2f2;
            `}
                    >
                        <div
                            className={css`
                        font-weight: 600;
                        font-size: 14px;
                    `}
                        >
                            {difference.title}
                        </div>
                    </div>
                    <div
                        className={css`
                    padding: 10px 15px;
                    border-bottom: 1px solid #f2f2f2;
                    color: #b3b3b3;
                `}
                    >
                        <div
                            className={css`
                            font-size: 18px;
                        margin-bottom: 4px;
                    `}
                        >
                            <StatisticsDiffPct value={difference.diffPct} reverse={difference.reverse} />
                        </div>
                        <div>
                            <StatisticsValue value={difference.prev} /> (<StatisticsDiff value={difference.diff} />)
                        </div>
                    </div>
                </div>
            )}

        </div>
    </Card>
)

export default DifferenceCard