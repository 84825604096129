import React from 'react'
import times from 'lodash/times'
import { css } from 'emotion'
import api from '../services/api'
import Title from "../components/Title";
import LoadingState from "../components/LoadingState";
import ListTable from '../components/ListTable'
import Button from '@pndr/button'

const LIMIT = 50

export default class EventsRoute extends React.Component {

    state = {
        loading: true,
        data: null,
        page: 0,
        eventType: 'Show all events'
    }

    componentDidMount() {
        this.fetch()
    }

    fetch = async () => {

        this.setState({
            loading: true
        })

        const response = await api.request({
            method: 'post',
            data: {
                query: `
                    query events($eventType: ID, $offset: Int, $limit: Int) {
                        viewer {
                            eventTypes {
                                id
                            }
                            events(eventType: $eventType, offset: $offset, limit: $limit) {
                                totalCount
                                nodes {
                                    id
                                    type
                                    entityType
                                    entityId
                                    payload
                                    createdAt
                                }
                            }
                        }
                    }
                `,
                variables: {
                    eventType: this.state.eventType !== 'Show all events' ? this.state.eventType : null,
                    offset: this.state.page * LIMIT,
                    limit: LIMIT
                }
            }
        })

        response.data.data.viewer.eventTypes.unshift({
            id: 'Show all events'
        })

        this.setState({
            loading: false,
            data: response.data.data
        })
    }

    render() {

        if (this.state.loading) return <LoadingState />

        const { eventTypes, events } = this.state.data.viewer

        const pages = times(Math.ceil(events.totalCount / LIMIT)).map(i => ({
            value: i,
            label: 'Page ' + (i + 1)
        }))

        return (
            <div>
                <Title>
                    {`Events (${events.totalCount})`}
                </Title>
                <h1>
                    Events ({events.totalCount})
                </h1>
                <div className={css`margin-bottom: 20px;`}>
                <Button primary onClick={this.fetch}>Refresh</Button>

                </div>
                <div
                    className={css`
                        margin-bottom: 50px;
                    `}
                >
                    <div
                        className={css`
                        margin-bottom: 20px;
                    `}
                    >
                        <div
                            className={css`
                            font-weight: bold;
                            margin-bottom: 8px;
                        `}
                        >
                            Event type
                        </div>
                        <select
                            value={this.state.eventType}
                            onChange={this.handleEventTypeChange}
                        >
                            {eventTypes.map(eventType => (
                                <option
                                    key={eventType.id}
                                    value={eventType.id}
                                >
                                    {eventType.id}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div
                        className={css`
                        margin-bottom: 20px;
                    `}
                    >
                        <div
                            className={css`
                            font-weight: bold;
                            margin-bottom: 8px;
                        `}
                        >
                            Page
                        </div>
                        <select
                            value={this.state.page}
                            onChange={this.handlePageChange}
                        >
                            {pages.map(page => (
                                <option
                                    key={page.value}
                                    value={page.value}
                                >
                                    {page.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <ListTable
                    fields={[{
                        name: 'event',
                        type: 'eventDetails',
                        width: 700,
                        getter: ({ row }) => ({
                            id: row.id,
                            type: row.type,
                            payload: row.payload
                        })
                    }, {
                        name: 'entityType',
                        dataKey: 'entityType'
                    }, {
                        name: 'entityId',
                        type: 'eventEntityLink',
                        getter: ({ row }) => ({
                            id: row.id,
                            type: row.type,
                            entityType: row.entityType,
                            entityId: row.entityId,
                            payload: row.payload
                        })
                    }, {
                        name: 'createdAt',
                        type: 'dateTime',
                        dataKey: 'createdAt'
                    }]}
                    rows={events.nodes}
                />
            </div>
        )
    }

    handlePageChange = (e) => {

        this.setState({
            page: e.target.value
        }, () => {
            this.fetch()
        })
    }

    handleEventTypeChange = (e) => {

        this.setState({
            eventType: e.target.value
        }, () => {
            this.fetch()
        })
    }
}