import React from 'react'
import spinner from '@pndr/spinner'
import Box from '../components/Box'
import CalendarGraph from '../components/CalendarGraph'
import api from '../services/api'

export default class ContractDatesGraph extends React.Component {

    state = {
        loading: true,
        data: null
    }

    componentDidMount() {
        this.fetch()
    }

    fetch = async () => {

        const response = await api.request({
            method: 'post',
            data: {
                query: `
                    query contract($id: ID!) {
                        viewer {
                            contract(id: $id) {
                                dates {
                                    id
                                    date
                                    canceled
                                    state
                                    pricePerDay
                                    score
                                    issues {
                                        id
                                        message
                                    }
                                    debitContractPeriodId
                                    isContractStartDate
                                    isContractEndDate
                                    isPaidUntilDate
                                }
                            }
                        }
                    }
                `,
                variables: {
                    id: this.props.recordId
                }
            }
        })

        this.setState({
            loading: false,
            data: response.data.data
        })
    }

    render() {

        return (
            <div>
                <h2>
                    {this.props.component.name}
                </h2>
                <Box p={32}>
                    {this.renderContent()}
                </Box>
            </div>
        )
    }

    renderContent() {

        if (this.state.loading) {
            return spinner({ width: 24 })
        }

        const { contract } = this.state.data.viewer
        const { dates } = contract

        const datesByDate = dates
            .map(date => ({
                ...date,
                fill: date.score > 0 ? '#2cbe4e' : '#cb2431',
                hasIssues: date.issues.length > 0,
                highlighted: date.isContractStartDate || date.isContractEndDate
            }))
            .reduce((result, date) => {
                result[date.id] = date
                return result
            }, {})

        return (
            <CalendarGraph
                data={datesByDate}
            />
        )
    }
}