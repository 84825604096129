import { fromJS } from 'immutable'

export default (state, action) => {

    switch (action.type) {

        case 'SET_SCHEMA': {

            const schema = fromJS(action.payload)

            schema.get('tables').forEach(table => {
                state = state.setIn(['tablesById', table.get('id')], table)
                state = state.update('tables', tables => {
                    tables = tables || fromJS([])
                    return tables.push(table.get('id'))
                })
            })

            return state
        }

        default: {
            return state
        }
    }
}