import React from 'react'
import api from '../services/api'
import Title from "../components/Title";
import LoadingState from "../components/LoadingState";
import ListTable from '../components/ListTable'
import PageHeader from '../components/PageHeader'
import Content from '../components/Content'

export default class ActionsRoute extends React.Component {

    state = {
        loading: true,
        data: null
    }

    componentDidMount() {
        this.fetch()
    }

    fetch = async () => {

        const response = await api.request({
            method: 'post',
            data: {
                query: `
                    query actions {
                        viewer {
                            actions {
                                module
                                trigger
                                handler
                                handlerFn
                                tags
                                description
                            }
                        }
                    }
                `
            }
        })

        this.setState({
            loading: false,
            data: response.data.data
        })
    }

    render() {

        if (this.state.loading) return <LoadingState />

        const { actions } = this.state.data.viewer

        const modules = actions.reduce((result, action) => {

            let module = result.find(module => module.name === action.module)

            if (!module) {
                module = {
                    name: action.module,
                    actions: []
                }
                result.push(module)
            }

            module.actions.push(action)

            return result
        }, [])

        return (
            <div>
                <Title>
                    {`Actions (${actions.length})`}
                </Title>
                <PageHeader title={`Actions (${actions.length})`} />
                <Content>
                    {modules.map(module => (
                        <div key={module.name}>
                            <h2>{module.name}</h2>
                            <ListTable
                                fields={[{
                                    name: 'module',
                                    dataKey: 'module'
                                }, {
                                    name: 'tags',
                                    type: 'tags',
                                    dataKey: 'tags'
                                }, {
                                    name: 'trigger',
                                    type: 'code',
                                    dataKey: 'trigger'
                                }, {
                                    name: 'handler',
                                    type: 'code',
                                    dataKey: 'handler'
                                }, {
                                    name: 'handlerFn',
                                    type: 'js',
                                    dataKey: 'handlerFn'
                                }, {
                                    name: 'description',
                                    dataKey: 'description'
                                }]}
                                rows={module.actions}
                            />
                        </div>
                    ))}
                </Content>
            </div>
        )
    }
}