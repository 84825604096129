import React from 'react'
import { css } from 'emotion'
import api from "../services/api";
import Title from '../components/Title'
import LoadingState from '../components/LoadingState'
import DetailTable from '../components/DetailTable'
import PageHeader from '../components/PageHeader'
import Content from '../components/Content'

export default class IndexRoute extends React.Component {

    state = {
        me: null
    }

    async componentDidMount() {
        await this.fetch()
    }

    fetch = async () => {

        const response = await api.request({
            method: 'post',
            data: {
                query: `
                    query me {
                        viewer {
                            statistics {
                                avgDtg
                                debtorTrajectoriesCount
                                contactCount
                                contractCount
                                invoiceCount
                            }
                            me {
                                id
                                email
                            }
                        }
                    }
                `
            }
        })

        this.setState({
            statistics: response.data.data.viewer.statistics,
            me: response.data.data.viewer.me
        })

    }

    render() {

        const { me, statistics } = this.state

        if (!me) return <LoadingState />

        return (
            <div>
                <Title>
                    Statistieken
                </Title>
                <PageHeader
                    title={'Statistieken'}
                />
                <Content>
                    <div
                        className={css`
                            margin-bottom: 20px;
                        `}
                    >
                        Hallo <strong>{me.email}</strong>
                    </div>
                    <DetailTable
                        fields={[{
                            name: 'Gemiddelde DTG',
                            value: statistics.avgDtg
                        }, {
                            name: 'Aantal debiteurentrajecten',
                            value: statistics.debtorTrajectoriesCount
                        }, {
                            name: 'Aantal contactpersonen',
                            value: statistics.contactCount
                        }, {
                            name: 'Aantal contracten',
                            value: statistics.contractCount
                        }, {
                            name: 'Aantal facturen',
                            value: statistics.invoiceCount
                        }]}
                    />
                </Content>
            </div>
        )
    }
}