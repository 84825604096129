import React from 'react'
import { connect } from 'react-redux'
import { css } from 'emotion'

class ContractDateIssue extends React.Component {

    render() {

        const options = this.props.field.getIn(['settings', 'options'])

        const option = options.find(option =>
            option.get('id') === this.props.issue.type
        ).toJS()

        if (!option) {
            return `Issue Type with value ${this.props.issue.type} not found`
        }

        const stateId = this.props.issue.stateId

        return (
            <div
                className={css`
            background-color: ${option.backgroundColor};
            padding: 2px 8px;
            font-size: 85%;
            border-radius: 99999px;
            display: inline-block;
            color: ${option.color};
            font-weight: 700;
            opacity: ${stateId === 'ignored' ? '0.6' : '1'};
        `}
            >
                {option.name}
            </div>
        )
    }
}

export default connect((state, props) => {

    const table = state.getIn(['tablesById', 'ContractDateIssue'])
    const fields = table.get('fields')
    const field = fields.find(field => field.get('id') === 'type')

    return {
        field
    }
})(ContractDateIssue)