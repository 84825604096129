import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import 'bootstrap-4-grid';
import Root from './Root';
import * as serviceWorker from './serviceWorker';
import numeral from 'numeral'
import { injectGlobal } from 'emotion'
import moment from 'moment'
import 'moment/locale/nl'
import * as Sentry from '@sentry/react';

injectGlobal`
    * {
        box-sizing: border-box;
    }
    a {
        color: #07f;
    }
    body {
        font-size: 14px;
        background-color: #fbfbfb;
    }
`

moment.locale('nl')

// load a locale
numeral.register('locale', 'nl', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
    },
    ordinal: function (number) {
        return number === 1 ? 'er' : 'ème';
    },
    currency: {
        symbol: '€'
    }
});

// switch between locales
numeral.locale('nl');

Sentry.init({
    dsn: "https://bea40e27cea5dd9979303e4ee9ca749d@o4505243400798208.ingest.us.sentry.io/4508216336777216",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
});

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<Root />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
