import React from 'react'
import hasMany from '../custom-components/hasMany'
import HasOne from '../custom-components/HasOne'
import ContractActions from '../custom-components/ContractActions'
import DebtorTrajectoryActions from '../custom-components/DebtorTrajectoryActions'
import ContractDTGGraph from '../custom-components/ContractDTGGraph'
import ContractDatesGraph from '../custom-components/ContractDatesGraph'
import ContractDatesGraph2 from '../custom-components/ContractDatesGraph2'
import ContractStatistics from '../components/ContractStatistics'
import DashboardStatistics from '../components/DashboardStatistics'
import DebtorTrajectoryPhaseTracker from '../custom-components/DebtorTrajectoryPhaseTracker'
import DebtorTrajectoryConvertPenaltiesToInvoiceButton from '../custom-components/DebtorTrajectoryConvertPenaltiesToInvoiceButton'
import ContractDatesComputeButton from '../custom-components/ContractDatesComputeButton'
import Box from '../components/Box'
import ContractMonthChart from '../components/ContractMonthChart'
import ContractMonthDTGCard from '../components/ContractMonthDTGCard'
import ContractMonthDebtorTrajectoryCountCard from '../components/ContractMonthDebtorTrajectoryCountCard'
import ContractMonthDebtorTrajectoryDayCountCard from '../components/ContractMonthDebtorTrajectoryDayCountCard'
import ContractYearChart from '../components/ContractYearChart'
import ContractYearDTGCard from '../components/ContractYearDTGCard'
import ContractYearDebtorTrajectoryCountCard from '../components/ContractYearDebtorTrajectoryCountCard'
import ContractYearDebtorTrajectoryDayCountCard from '../components/ContractYearDebtorTrajectoryDayCountCard'
import ContractDatePreview from '../components/ContractDatePreview'
import ContractMonthPreview from '../components/ContractMonthPreview'
import StandardMonthChart from '../components/StandardMonthChart'
import StandardMonthPreview from '../components/StandardMonthPreview'
import StandardMonthDTGCard from '../components/StandardMonthDTGCard'
import StandardYearDTGCard from '../components/StandardYearDTGCard'
import StandardYearChart from '../components/StandardYearChart'

const componentRenderers = {
    hasMany,
    hasOne: HasOne,
    DebtorTrajectoryActions,
    ContractActions,
    ContractDatesGraph,
    ContractDatesGraph2,
    ContractDTGGraph,
    ContractStatistics,
    DashboardStatistics,
    DebtorTrajectoryPhaseTracker,
    DebtorTrajectoryConvertPenaltiesToInvoiceButton,
    ContractDatesComputeButton,
    ContractDatePreview,
    ContractMonthChart,
    ContractMonthPreview,
    ContractMonthDTGCard,
    ContractMonthDebtorTrajectoryCountCard,
    ContractMonthDebtorTrajectoryDayCountCard,
    ContractYearChart,
    ContractYearDTGCard,
    ContractYearDebtorTrajectoryCountCard,
    ContractYearDebtorTrajectoryDayCountCard,
    StandardMonthChart,
    StandardMonthPreview,
    StandardMonthDTGCard,
    StandardYearDTGCard,
    StandardYearChart,
}

const defaultComponentRenderer = ({ component }) => (
    <div component-id={component.id}>
        <Box p={32}>
            <pre>
                {JSON.stringify(component, null, 2)}
            </pre>
        </Box>
    </div>
)

export default component => componentRenderers[component.type] || defaultComponentRenderer