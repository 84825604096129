import React from 'react'
import moment from 'moment'
import StatisticsPreview from './StatisticsPreview'

const StandardMonthPreview = ({ source }) => (
    <StatisticsPreview
        title={source.name}
        description={moment(source.startDate).subtract(1, 'month').format('MMMM YYYY')}
        rows={[
            {
                title: 'Gemiddelde DTG',
                type: 'Difference',
                value: source.dtgMean,
                prev: source.dtgMeanPrevYear,
                prevDiff: source.dtgMeanPrevYearDiff,
                prevDiffPct: source.dtgMeanPrevYearDiffPct,
                reverse: true
            },
            {
                title: 'Max',
                type: 'Default',
                value: source.dtgMax
            },
            {
                title: 'Min',
                type: 'Default',
                value: source.dtgMin
            }
        ]}
    />
)

export default StandardMonthPreview