import React from 'react'
import {css} from 'emotion'
import renderType from './renderType'

export default class DetailTable extends React.Component {

    render() {

        const {fields} = this.props

        return (
            <table
                className={css`
                    width: 100%;
                    table-layout: fixed;
                    border-collapse: collapse;
                `}
            >
                <tbody>
                {fields.map(field => {

                    let value = null

                    if (field.renderer) {

                        value = field.renderer({
                            value: field.value
                        })
                    } else {
                        value = renderType(field.type)({
                            value: field.value
                        })
                    }

                    return (
                        <tr
                            key={field.name}
                        >
                            <th
                                className={css`
                                    text-align: left;
                                    border-top: 1px solid #f2f2f2;
                                    border-bottom: 1px solid #f2f2f2;
                                    padding: 6px 0;
                                    vertical-align: center;
                                    color: #737373;
                                    font-weight: 400;
                                    width: 50%;
                                `}
                                height={32}
                            >
                                {field.name}
                            </th>
                            <td
                                className={css`
                                    border-top: 1px solid #f2f2f2;
                                    border-bottom: 1px solid #f2f2f2;
                                    padding: 6px 0;
                                `}
                                height={32}
                            >
                                {value}
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }
}