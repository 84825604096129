import React from 'react'
import { css } from 'emotion'
import arrowLeft from '@pndr/icons/lib/arrowLeft'
import arrowRight from '@pndr/icons/lib/arrowRight'

const PaginateButton = ({ onClick, children }) => (
    <div
        onClick={onClick}
        className={css`
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            cursor: pointer;
            border-right: 1px solid rgb(242, 242, 242);
            padding: 4px 7px;
            height: 30px;
            &:hover {
                background-color: #f9f9f9;
            }
            &:active {
                background-color: #eee;
            }
        `}
    >
        {children}
    </div>
)

class Pagination extends React.Component {

    render() {

        const {
            offset,
            limit,
            recordCount
        } = this.props

        return (
            <div
                className={css`
                    display: flex;
                    align-items: center;
                `}
            >
                {recordCount > limit ? (
                    <div
                        className={css`
                        box-shadow: 0 0 0 1px rgba(0,0,0,.09), 0 1px 0 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.1);
                        font-size: 15px;
                height: 30px;
                -webkit-box-align: center;
                align-items: center;
                display: inline-flex;
                border-radius: 5px;
                overflow: hidden;
                margin-right: 8px;
                background-color: #fff;
                `}
                    >
                        <PaginateButton
                            onClick={this.handlePreviousPage}
                        >
                            {arrowLeft({ width: 14 })}
                        </PaginateButton>
                        <div
                            className={css`
                        display: flex;
                        -webkit-box-align: center;
                        align-items: center;
                        cursor: pointer;
                        border-right: 1px solid rgb(242, 242, 242);
                        padding: 4px 12px;
                        height: 30px;
                        user-select: none;
                        font-size: 14px;
                    `}
                        >
                            {Math.min(offset + 1, recordCount)} - {Math.min(offset + limit, recordCount)}
                        </div>
                        <PaginateButton
                            onClick={this.handleNextPage}
                        >
                            {arrowRight({ width: 14 })}
                        </PaginateButton>
                    </div>
                ) : null}
                <div
                    className={css`
                       font-size: 14px;
                       background: #fff;
                       box-shadow: 0 0 0 1px rgba(0,0,0,.09);
                       height: 30px;
                       padding: 4px 8px;
                       align-items: center;
                       display: flex;
                       border-radius: 6px;
                       color: #737373;
                       `}
                >
                    <React.Fragment>
                        {recordCount} records
                        </React.Fragment>
                </div>
            </div>
        )
    }

    handlePreviousPage = () => {

        const { pageIndex, pageCount, limit } = this.props

        const nextPageIndex = pageIndex === 0 ? pageCount - 1 : pageIndex - 1

        this.props.onOffsetChange(nextPageIndex * limit)
    }

    handleNextPage = () => {

        const { pageIndex, pageCount, limit } = this.props

        const nextPageIndex = pageIndex === pageCount - 1 ? 0 : pageIndex + 1

        this.props.onOffsetChange(nextPageIndex * limit)
    }
}

export default props => {

    const recordCount = props.recordCount
    const pageIndex = Math.floor(props.offset / props.limit)
    const pageCount = Math.max(Math.ceil(recordCount / props.limit), 1)

    return (
        <Pagination
            onOffsetChange={props.onOffsetChange}
            offset={props.offset}
            limit={props.limit}
            recordCount={recordCount}
            pageIndex={pageIndex}
            pageCount={pageCount}
        />
    )
}