import React from 'react'
import DifferenceCard from './DifferenceCard'

const ContractMonthDebtorTrajectoryCountCard = ({ component, source }) => (
    <DifferenceCard
        title={component.name}
        value={source.debtorTrajectoryCount}
        differences={[
            {
                title: 'Vergeleken met dezelfde maand vorig jaar',
                prev: source.debtorTrajectoryCountPrevYear,
                diff: source.debtorTrajectoryCountPrevYeraDiff,
                diffPct: source.debtorTrajectoryCountPrevYearDiffPct,
                reverse: true
            },
            {
                title: 'Vergeleken met LesautoDeal gemiddelde',
                prev: source.stdDebtorTrajectoryCount,
                diff: source.stdDebtorTrajectoryCountDiff,
                diffPct: source.stdDebtorTrajectoryCountDiffPct,
                reverse: true
            }
        ]}
    />
)

export default ContractMonthDebtorTrajectoryCountCard