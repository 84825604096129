import React from 'react'
import moment from 'moment'
import 'moment/locale/nl'
import { css } from 'emotion'
import Autosizer from 'react-virtualized-auto-sizer'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ReferenceArea, Text, ReferenceLine } from 'recharts'
import LoadingOverlay from './LoadingOverlay'

export const intervals = {
    month: {
        pageUnit: 'month',
        pageLabelFormatter: ({ date }) => {
            return date.format('MMM YY')
        },
        tickUnit: 'day',
        tickLabelFormatter: ({ date }) => {
            return date.format('dd DD MMM')
        },
        interval: 'preserveEnd'
    },
    year: {
        pageUnit: 'year',
        pageLabelFormatter: ({ date }) => {
            return date.format('YYYY')
        },
        tickUnit: 'month',
        tickLabelFormatter: ({ date }) => {
            return date.format('MMM YY')
        },
        interval: 0
    }
}

const CustomXTick = (props) => {

    return <Text
        {...props}
        fill={'#000'}
        fontSize={13}
        fontWeight={'bold'}
    >
        {props.labelFormatter({ date: moment(props.payload.value) })}
    </Text>
}

const CustomizedTooltip = (props) => {

    if (!props.payload || !props.payload.length) {
        return null
    }

    // console.log(props.payload)

    const payload = props.payload[0].payload

    // console.log('payload', payload)

    if (!payload.hasDataPoint) {
        return null // Don't show any tooltip
    }

    if (props.tooltipRenderer) {
        return (
            <div
                className={css`
                    min-width: 280px;
                `}
            >
                {props.tooltipRenderer({ source: payload })}
            </div>
        )
    }

    const { date } = payload

    return (
        <div
            className={css`
            width: 250px;
            border: 1px solid #e3e3e3;
            border-radius: 4px;
            background: #fff;
            font-size: 14px;
            box-shadow: 0 5px 20px rgba(0,0,0,.1);
            `}
        >
            <div
                className={css`
                padding: 15px;             
                `}
            >
                <div
                    className={css`
                    color: #000;
                    font-weight: 700;
                    margin-bottom: 4px;
                    `}
                >{props.labelFormatter({ date: moment(date) })}</div>
                <div
                    className={css`
                    color: #737373;
    font-weight: 400;
                    `}
                >omschrijving</div>
            </div>
            {props.payload.map((payload, index) => {

                return (
                    <div
                        key={index}
                        className={css`
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                border-top: 1px solid #ebebeb;
                padding: 10px 15px;
                `}
                    >
                        <div
                            className={css`
                    display: flex;
                    justify-content: space-between;
                    flex: 0 0 100%;
                    margin-bottom: 0;
                    `}
                        >
                            <span>
                                {payload.dataKey}
                            </span>
                            <span>
                                {payload.value ? payload.value.toFixed(0) : '-'}
                            </span>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

const CustomCursor = props => {
    // console.log("CustomCursor", props)
    return null
};

const phases = [
    {
        "id": "PRE_PHASE_2",
        "name": "Fase -1",
        "description": "DTG of 8\nAdministrator get's notified that the customer will receive a message the following day\n",
        "backgroundColor": "#ddd",
        "color": "white",
        "type": "ReferenceArea",
        "props": {
            "y1": 8,
            "y2": 9
        }
    },
    {
        "id": "PRE_PHASE_1",
        "name": "Fase 0",
        "description": "DTG of 9\nCustomer get's notified that they will enter Phase 1 of the debtor trajectory the following day\n",
        "backgroundColor": "#888",
        "color": "white",
        "type": "ReferenceArea",
        "props": {
            "y1": 9,
            "y2": 10
        }
    },
    {
        "id": "PHASE_1",
        "name": "Fase 1",
        "description": "DTG of 10 to 19\nPenalty of € 10,- per day in this phase\n",
        "backgroundColor": "#FEB24C",
        "color": "white",
        "type": "ReferenceArea",
        "props": {
            "y1": 10,
            "y2": 20
        }
    },
    {
        "id": "PHASE_2",
        "name": "Fase 2",
        "description": "DTG of 20 to 24\nPenalty of € 15,- per day in this phase\n",
        "backgroundColor": "#FD8D3C",
        "color": "white",
        "type": "ReferenceArea",
        "props": {
            "y1": 20,
            "y2": 25
        }
    },
    {
        "id": "PHASE_3",
        "name": "Fase 3",
        "description": "DTG of 25 to 28\nPenalty of € 25,- per day in this phase\n",
        "backgroundColor": "#FC4E2A",
        "color": "white",
        "type": "ReferenceArea",
        "props": {
            "y1": 25,
            "y2": 29
        }
    },
    {
        "id": "PHASE_4",
        "name": "Fase 4",
        "description": "DTG of 29\nPenalty of € 25,- per day in this phase\n",
        "backgroundColor": "#E31A1C",
        "color": "white",
        "type": "ReferenceArea",
        "props": {
            "y1": 29,
            "y2": 30
        }
    },
    {
        "id": "PHASE_5",
        "name": "Fase 5",
        "description": "DTG of 30\nPenalty of € 25,- per day in this phase\n",
        "backgroundColor": "#BD0026",
        "color": "white",
        "type": "ReferenceArea",
        "props": {
            "y1": 30,
            "y2": 31
        }
    },
    {
        "id": "PHASE_6",
        "name": "Fase 6",
        "description": "DTG of 31\nPenalty of € 25,- per day in this phase\n",
        "backgroundColor": "#800026",
        "color": "white",
        "type": "ReferenceArea",
        "props": {
            "y1": 31,
            "y2": 32
        }
    }
]

class Chart extends React.Component {

    constructor(props) {

        super(props)

        this.state = {
            ...this.createState()
        }
    }

    componentDidUpdate(prevProps) {

        if (prevProps.loading !== this.props.loading) {
            
            this.setState(
                this.createState()
            )
        }
    }

    createState = () => {

        let data = []

        const defaultDataPointerGetter = () => null
        const dataPointGetter = this.props.dataPointGetter || defaultDataPointerGetter

        // const today = moment()

        for (var pointer = this.props.startDate.clone(); pointer.isSameOrBefore(this.props.endDate); pointer.add(1, this.props.interval.tickUnit)) {

            // const futureDate = pointer.isAfter(today)

            const dataPoint = dataPointGetter({ date: pointer.toISOString() })

            let item = {
                date: pointer.toISOString(),
                hasDataPoint: false
            }

            if (dataPoint) {
                item = {
                    hasDataPoint: true,
                    ...dataPoint
                }
            }

            data.push(item)
        }

        return {
            data
        }
    }

    render() {

        return (
            <div>
                <div
                    className={css`
                    position: relative;
                    padding: 40px 40px;
                    background-color: #fff;
                    box-shadow: 0 0 1px 0 #ebebeb, 0 0 1px 0 #ebebeb;
                    `}
                >
                    <div>
                        {this.props.legend}
                    </div>
                    <div
                        className={css`
                            position: relative;
                            height: 500px;
                        `}
                    >
                        <Autosizer>
                            {({ width, height }) => (
                                <LineChart
                                    width={width}
                                    height={height}
                                    data={this.state.data}
                                    margin={{
                                        top: 10, right: 30, left: -20, bottom: 20,
                                    }}
                                >
                                    <CartesianGrid vertical={false} stroke={'#f5f5f5'} />
                                    <XAxis
                                        dataKey="date"
                                        tick={props => <CustomXTick {...props} labelFormatter={this.props.interval.tickLabelFormatter} />}
                                        type="category"
                                        tickMargin={20}
                                        interval={this.props.interval.interval}
                                        tickLine={false}
                                        axisLine={false}
                                    />
                                    <YAxis
                                        tick={{ fill: '#000', fontSize: 13 }}
                                        tickMargin={10}
                                        tickLine={false}
                                        axisLine={false}
                                        domain={this.props.domain}
                                    />
                                    <Tooltip
                                        isAnimationActive={false}
                                        filterNull={false}
                                        offset={40}
                                        position={{ y: this.props.height / 2 }}
                                        content={<CustomizedTooltip labelFormatter={this.props.interval.tickLabelFormatter} tooltipRenderer={this.props.tooltipRenderer} />}
                                        // cursor={{ stroke: 'rgba(0,119,255,.05)', strokeWidth: 40 }}
                                        cursor={<CustomCursor />}
                                        dot={{ stroke: '#fff', strokeWidth: 3, fill: '#0095ff', r: 5, fillOpacity: 1 }}
                                    />
                                    <defs>
                                        {phases.map(phase => (
                                            <linearGradient key={phase.id} id={phase.id} x1="0%" y1="0%" x2="100%" y2="0%">
                                                <stop offset={'0%'} stopColor={phase.backgroundColor} stopOpacity={0} />
                                                <stop offset={'3%'} stopColor={phase.backgroundColor} stopOpacity={0.4} />
                                                <stop offset={'97%'} stopColor={phase.backgroundColor} stopOpacity={0.4} />
                                                <stop offset={'100%'} stopColor={phase.backgroundColor} stopOpacity={0} />
                                            </linearGradient>
                                        ))}
                                    </defs>
                                    {this.props.lines.map((line, index) => (
                                        <Line
                                            key={index}
                                            dataKey={line.dataKey}
                                            stroke={line.color}
                                            strokeDasharray={line.strokeDasharray}
                                            strokeWidth={line.strokeWidth}
                                            isAnimationActive={false}
                                            dot={{ strokeDasharray: '', stroke: line.color, strokeWidth: 2, fill: '#fff', r: 4, fillOpacity: 1 }}
                                        />
                                    ))}
                                    {phases.map(phase => {

                                        if (phase.type === 'ReferenceLine') {

                                            return (
                                                <ReferenceLine key={phase.id} y={phase.props.y} stroke={`url(#${phase.id})`} ifOverflow={'extendDomain'} />
                                            )
                                        }

                                        if (phase.type === 'ReferenceArea') {

                                            return (
                                                <ReferenceArea key={phase.id} y1={phase.props.y1} y2={phase.props.y2} fill={`url(#${phase.id})`} ifOverflow={'extendDomain'} />
                                            )
                                        }

                                        return null
                                    })}
                                </LineChart>
                            )}
                        </Autosizer>
                    </div>
                    {this.props.loading && <LoadingOverlay />}
                </div>
            </div>
        )
    }
}

export default Chart