import React from 'react'
import { css } from 'emotion'
import icons from '../icons'

export default class SearchInput extends React.Component {

    render() {

        return (
            <div
                className={css`
                    position: relative;
                `}
            >
                {icons.search({
                    width: 12,
                    className: css`
                    pointer-events: none;
                    position: absolute;
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                    left: 10px;
                    `
                })}
                <input
                    {...this.props}
                    type="text"
                    className={css`
                    display: block;
                    width: 100%;
                    height: 30px;
                    padding: 5px 10px 5px 27px;
                    border: 0;
                    box-shadow: 0 0 0 1px rgba(0,0,0,.09), 0 1px 0 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.1);
                    border-radius: 6px;
                    background-color: #fff;
                    color: #191919;
                    font-size: 15px;
                    line-height: 1.42857;
                    -webkit-transition: box-shadow .1s ease-in-out;
                    transition: box-shadow .1s ease-in-out;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    &:focus {
                        outline: 0;
                        box-shadow: inset 0 0 0 2px #07f, 0 2px 4px rgba(0,0,0,.1), 0 0 1px rgba(0,0,0,.1);
                        -webkit-transition-duration: 0s;
                        transition-duration: 0s;
                    }
                `}
                />
            </div>
        )
    }
}