import React from 'react'
import Button from '@pndr/button'
import { css } from 'emotion'
import moment from 'moment'
import chunk from 'lodash/chunk'
import times from 'lodash/times'
import CompactDetailTable from './CompactDetailTable'
import assign from 'lodash/assign'

class Tooltip extends React.Component {

    render() {

        return (
            <div
                className={css`
                position: absolute;
                background-color: #fff;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 4px 11px;
                border-radius: 3px;
                width: 400px;
                height: 250px;
                overflow: hidden;
                margin-top: -260px;
                margin-left: -200px;
                z-index: 1200;
            `}
                style={{
                    top: this.props.y,
                    left: this.props.x
                }}
            >
                <div
                    className={css`
                        padding: 16px;
                        font-size: 14px;
                    `}
                >
                    <div
                        className={css`
                            font-weight: bold;
                        `}
                    >
                        {moment.utc(this.props.date).format('ddd D MMM YYYY')}
                    </div>
                    {this.props.data && (this.props.data.isContractStartDate || this.props.data.isContractEndDate || this.props.data.canceled) ? (
                        <div
                            className={css`
                                color: #787878;
                                font-size: 11px;
                                margin-top: 8px;
                            `}
                        >
                            {this.props.data.isContractStartDate ? 'Start van contract' : null}
                            {this.props.data.isContractEndDate ? 'Einde van contract' : null}
                            {this.props.data.isPaidUntilDate ? 'Betaald tot en met' : null}
                            {this.props.data.canceled ? 'Buiten de contract periode' : null}
                        </div>
                    ) : null}
                    {this.props.date.isSame(moment.utc(), 'd') ? (
                        <div
                            className={css`
                                color: #787878;
                                font-size: 11px;
                                margin-top: 8px;
                            `}
                        >
                            Vandaag
                        </div>
                    ) : null}
                    {this.props.data ? (
                        <div
                            className={css`
                                margin-top: 16px;
                            `}
                        >
                            <CompactDetailTable
                                fields={[{
                                    name: 'Status',
                                    type: 'invoiceState',
                                    value: this.props.data.state
                                }, {
                                    name: 'Score',
                                    value: this.props.data.score
                                }, {
                                    name: 'Laatst gedebiteerde contract periode',
                                    value: this.props.data.debitContractPeriodId
                                }, {
                                    name: 'Prijs per dag',
                                    type: 'currency',
                                    value: this.props.data.pricePerDay
                                }]}
                            />
                        </div>
                    ) : null}
                    {this.props.data && this.props.data.issues ? (
                        <div
                            className={css`
                                margin-top: 16px;
                            `}
                        >
                            {this.props.data.issues.map(issue => (
                                <li>
                                    {issue.message}
                                </li>
                            ))}
                        </div>
                    ) : null}
                </div>
            </div>
        )
    }
}

class DateRect extends React.Component {

    state = {
        hover: false
    }

    render() {

        let boxShadow = null

        const isContractStartDate = this.props.data ? this.props.data.isContractStartDate : false
        const isContractEndDate = this.props.data ? this.props.data.isContractEndDate : false
        const isStartOrEnd = isContractStartDate || isContractEndDate
        const isToday = this.props.date.isSame(moment.utc(), 'd')
        const isPaidUntilDate = this.props.data ? this.props.data.isPaidUntilDate : null

        if (isToday || isPaidUntilDate) {

            const items = []

            if (isToday) {
                items.push('rgb(0, 0, 0) 0px 0px 0px 2px')
            } else {
                items.push('rgb(255, 255, 255) 0px 0px 0px 2px')
            }

            if (isPaidUntilDate) {
                items.push('rgb(238, 194, 0) 0px 0px 0px 4px')
            }



            boxShadow = items.join(', ')
        }

        return (
            <React.Fragment>
                <div
                    className={css`
                    cursor: pointer;
                `}
                    style={{
                        borderRadius: isStartOrEnd ? '50%' : '3px',
                        position: 'absolute',
                        top: `${this.props.y}px`,
                        left: `${this.props.x}px`,
                        width: this.props.width,
                        height: this.props.height,
                        boxShadow
                    }}
                    onMouseEnter={this.handleEnter}
                    onMouseLeave={this.handleLeave}
                    data-date={this.props.id}
                    width={this.props.width}
                    height={this.props.height}
                >
                    <div
                        style={{
                            borderRadius: isStartOrEnd ? '50%' : '3px',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            width: this.props.width,
                            height: this.props.height,
                            opacity: this.state.hover ? 0.5 : this.props.muted ? 0.2 : 1,
                            backgroundColor: this.props.fill,
                        }}
                    />
                    {this.props.point ? (
                        <div
                            className={css`
                                position: absolute;
                                top: 4px;
                                left: 4px;
                                background-color: #fff;
                                border-radius: ${isStartOrEnd ? '50%' : '2px'};
                                width: 8px;
                                height: 8px;
                            `}
                        />
                    ) : null}
                    {this.props.hasIssues ? (
                        <div
                            className={css`
                                position: absolute;
                                right: -4px;
                                top: -4px;
                                background-color: #EEC200;
                                border-radius: 50%;
                                width: 8px;
                                height: 8px;
                            `}
                        />
                    ) : null}
                </div>
                {this.state.hover ? (
                    <Tooltip
                        x={this.props.x + (this.props.width / 2)}
                        y={this.props.y}
                        date={this.props.date}
                        data={this.props.data}
                    />
                ) : null}
            </React.Fragment>
        )
    }

    handleEnter = () => {

        this.setState({
            hover: true
        })
    }

    handleLeave = () => {

        this.setState({
            hover: false
        })
    }
}

class Indicator extends React.Component {

    state = {
        hover: false
    }

    handleEnter = () => {

        this.setState({
            hover: true
        })
    }

    handleLeave = () => {

        this.setState({
            hover: false
        })
    }

    render() {

        const styles = {
            default: {
                backgroundColor: '#ebedf0'
            },
            credited: {
                backgroundColor: '#dc3545'
            },
            debited: {
                backgroundColor: '#2cbe4e'
            },
            contract_start: {
                borderRadius: '50%'
            },
            contract_end: {
                borderRadius: '50%'
            },
            paid_until_date: {
                boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 2px, rgb(238, 194, 0) 0px 0px 0px 4px'
            },
            today: {
                boxShadow: 'rgb(0, 0, 0) 0px 0px 0px 2px'
            }
        }

        const style = this.props.styles.reduce((result, id) => assign({}, result, styles[id] || {}), {})

        return (
            <div
                className={css`
                    position: relative;
                `}
            >
                <div
                    onMouseEnter={this.handleEnter}
                    onMouseLeave={this.handleLeave}
                    style={style}
                    className={css`
                    position: relative;
                    width: 16px;
                    height: 16px;
                    background-color: rgb(44, 190, 78);
                    box-shadow: none;
                    border-radius: 3px;
                    opacity: ${this.state.hover ? 0.5 : this.props.styles.includes('muted') ? 0.2 : 1};
                `}
                >
                    {this.props.styles.includes('paid') ? (
                        <div
                            className={css`
                        position: absolute;
                        top: 4px;
                        left: 4px;
                        background-color: rgb(255, 255, 255);
                        width: 8px;
                        height: 8px;
                        border-radius: 2px;
                    `}
                        />
                    ) : null}

                </div>
                {this.props.styles.includes('issues') ? (
                    <div
                        className={css`
                position: absolute;
                right: -4px;
                top: -4px;
                background-color: #EEC200;
                border-radius: 50%;
                width: 8px;
                height: 8px;
            `}
                    />
                ) : null}
            </div>
        )
    }
}

class LegendItem extends React.Component {

    render() {

        return (
            <div
                className={css`
                    display: flex;
                    align-items: center;
                    padding: 8px 8px;
                    margin-right: 8px;
                    background: #fff;
                    border-radius: 6px;
                    margin-top: 8px;
                    color: #333333b5;
                    user-select: none;
                    border: 1px solid #d9d9d9;
                `}
            >
                <div>
                    <Indicator styles={this.props.styles} />
                </div>
                <div
                    className={css`
                        margin-left: 8px;
                        font-size: 13px;
                    `}
                >
                    {this.props.text}
                </div>
            </div>
        )
    }
}

class Legend extends React.Component {

    render() {

        return (
            <div
                className={css`
                    display: flex;
                    align-items: center;
                    flex-flow: wrap;
                `}
            >
                <LegendItem styles={['default']} text={'Niet gedebiteerd / gecrediteerd'} />
                <LegendItem styles={['credited']} text={'Gecrediteerd'} />
                <LegendItem styles={['debited']} text={'Gedebiteerd'} />
                <LegendItem styles={['contract_start']} text={'Start / einde van het contract'} />
                <LegendItem styles={['today']} text={'Vandaag'} />
                <LegendItem styles={['paid_until_date']} text={'Betaald tot en met'} />
                <LegendItem styles={['paid']} text={'Datum is betaald'} />
                <LegendItem styles={['credited', 'issues', 'paid']} text={'Datum heeft issues'} />
                <LegendItem styles={['credited', 'issues', 'paid', 'muted']} text={'Buiten contract periode'} />
            </div>
        )
    }
}

export default class CalendarGraph extends React.Component {

    constructor(props) {

        super(props)

        const dates = Object.keys(this.props.data).sort((a, b) => b.localeCompare(a))
        const last_date = dates.length ? moment(dates[0]) : moment.utc()

        this.state = {
            pointer: last_date.add(2, 'months').startOf('isoweek').subtract(51, 'weeks')
        }
    }

    render() {

        const start = this.state.pointer.clone()

        const dates = times(364).map(i => {

            const date = start.clone().add(i, 'days')
            const id = date.format('YYYY-MM-DD')

            return {
                id,
                date
            }
        })

        const { months: monthData } = chunk(dates, 7)
            .map(dates => dates[6].date)
            .reduce((result, date, i) => {

                const month = date.format('MMM')

                if (!result.pointer || result.pointer.name !== month) {

                    result.pointer = {
                        name: month,
                        offset: i,
                        size: 0
                    }

                    result.months.push(result.pointer)
                }

                result.pointer.size++

                return result
            }, {
                pointer: null,
                months: []
            })

        // Filter out months that have a size bigger than 1, else they would not display well
        const months = monthData
            .filter(month => month.size > 1)

        return (
            <div>
                <div
                    className={css`
                        margin-bottom: 16px;
                    `}
                >
                    <strong>{this.state.pointer.clone().format('DD MMMM YYYY')}</strong> tot en met <strong>{this.state.pointer.clone().add(52, 'isoweeks').subtract(1, 'day').format('DD MMMM YYYY')}</strong>
                </div>
                <div
                    className={css`
                        border: 1px solid #d9d9d9;
                        border-radius: 6px;
                        padding: 16px;
                        display: inline-block;
                    `}
                >
                    <div
                        className={css`
                            color: #737373;
                            margin-left: 40px;
                        `}
                        style={{
                            position: 'relative',
                            boxSizing: 'border-box',
                            height: 22
                        }}
                    >
                        {months.map((month, i) => (
                            <div
                                key={i}
                                style={{
                                    position: 'absolute',
                                    left: month.offset * 22,
                                    width: month.size * 22
                                }}
                                className={css`
                                    font-size: 13px;
                                `}
                            >
                                {month.name}
                            </div>
                        ))}
                    </div>
                    <div
                        className={css`
                        display: flex;
                    `}
                    >
                        <div
                            className={css`
                            color: #737373;
                        `}
                            style={{
                                position: 'relative',
                                boxSizing: 'border-box',
                                height: 22 * 7,
                                width: 40
                            }}
                        >
                            {['Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za', 'Zo'].map((a, i) => (
                                <div
                                    key={i}
                                    style={{
                                        position: 'absolute',
                                        top: i * 22
                                    }}
                                    className={css`
                                    height: 22px;
                                    font-size: 13px;
                                `}
                                >
                                    {a}
                                </div>
                            ))}
                        </div>
                        <div>
                            <div
                                style={{
                                    position: 'relative',
                                    boxSizing: 'border-box',
                                    width: 22 * 52,
                                    height: 22 * 7
                                }}
                            >
                                {dates.map((date, i) => {

                                    const data = this.props.data[date.id]

                                    let fill = data ? data.fill : '#ebedf0'

                                    const x = Math.floor(i / 7)

                                    return (
                                        <DateRect
                                            key={date.id}
                                            width={16}
                                            height={16}
                                            x={22 * x}
                                            y={22 * (i % 7)}
                                            id={date.id}
                                            fill={fill}
                                            point={data ? data.state === 'paid' : null}
                                            muted={data ? data.canceled : null}
                                            active={data && !data.canceled}
                                            highlighted={data ? data.highlighted : null}
                                            hasIssues={data ? data.hasIssues : null}
                                            data={data}
                                            date={date.date}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={css`
                        display: flex;
                        align-items: center;
                        margin-top: 16px;
                    `}
                >
                    <Button
                        className={css`
                            margin-right: 8px;
                        `}
                        onClick={this.handlePrevious}
                    >
                        Vorige
                    </Button>
                    <Button onClick={this.handleNext}>
                        Volgende
                    </Button>
                </div>
                <div
                    className={css`
                                margin-top: 16px;
                    `}
                >
                    <Legend />
                </div>
            </div>
        )
    }

    handlePrevious = () => {

        this.setState({
            pointer: this.state.pointer.clone().subtract(52, 'weeks')
        })
    }

    handleNext = () => {

        this.setState({
            pointer: this.state.pointer.clone().add(52, 'weeks')
        })
    }
}