import React from "react"
import { css } from 'emotion'
import getSession from '@sublayer/passport-components/lib/getSession'
class JobQueueRoute extends React.Component {

    render() {

        const session = getSession()

        return (
            <div>
                <iframe
                    src={`${window._env_.REACT_APP_API_URL}/job-queue/dashboard?token=${session}`}
                    title={'iframe'}
                    className={css`
                        position: absolute;
                        top: 0;
                        left: 250px;
                        right: 0;
                        bottom: 0;
                        height: 100%;
                        width: 100%;
                        border: none;
                `}
                />
            </div>
        )
    }
}

export default JobQueueRoute