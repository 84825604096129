import React, { useState } from 'react'
import Button from '@pndr/button'
import spinner from '@pndr/spinner'
import { css, cx } from 'emotion'
import { connect } from 'react-redux'
import dashify from 'dashify'
import api from '../services/api'
import Title from '../components/Title'
import Record from '../components/Record'
import getComponent from '../custom-components/getComponent'
import { TabList, Tab } from './Tabs'
import Content from '../components/Content'
import icons from '../icons'
import { Link } from 'react-router-dom'

const renderComponent = ctx => ({ key, type }) => {

    const component = ctx.components[type]

    if (!component) {
        throw new Error(`Component of type ${type} not found`)
    }

    const Component = getComponent(component)

    const props = {
        source: ctx.record,
        recordId: ctx.recordId,
        tableId: ctx.tableId,
        onPageRefresh: ctx.onPageRefresh,
        component
    }

    console.log('props', props)

    return (
        <div
            className={css`
                margin-bottom: 32px;
            `}
        >
            <Component
                key={key}
                {...props}
            />
        </div>
    )
}

const Tabs = ({ style, ctx, children }) => {

    const [activeChild, setActiveChild] = useState(0)

    const child = children[activeChild]

    return (
        <div
            style={style}
            className={css`
            `}
        >
            <div
                className={css`
           
                `}
            >
                <TabList>
                    {children.map((child, index) => {

                        const component = ctx.components[child.type]

                        return (
                            <Tab
                                active={index === activeChild}
                                onClick={() => setActiveChild(index)}
                            >
                                {child.title ? child.title : component ? component.name : 'Naamloos'}
                            </Tab>
                        )
                    })}
                </TabList>
            </div>
            <div
                className={css`
                    margin-top: 32px;
                `}
            >
                {renderLayoutChild(ctx)(child, child.type)}
            </div>
        </div>
    )
}

const layoutComponents = {
    row: ctx => ({ key, children }) => {
        return (
            <div key={key} className={cx("row")}>
                {renderLayout(ctx)(children)}
            </div>
        )
    },
    div: ctx => ({ key, className, children }) => {
        return (
            <div key={key} className={cx(className)}>
                {renderLayout(ctx)(children)}
            </div>
        )
    },
    col: ctx => ({ key, className, children }) => {
        return (
            <div key={key} className={cx("col", className)}>
                {renderLayout(ctx)(children)}
            </div>
        )
    },
    test: ctx => () => {
        return (
            <div>
                test123
            </div>
        )
    },
    Tabs: ctx => ({ style, children }) => {

        return (
            <div
                className={css`
                    margin-bottom: 32px;
                `}
            >
                <Tabs ctx={ctx} children={children} style={style} />
            </div>
        )
    },
    RecordDetailTable: ctx => ({ fields }) => {

        return (
            <div
                className={css`
                    margin-bottom: 32px;
                `}
            >
                <Record
                    fields={fields}
                    tableId={ctx.tableId}
                    data={ctx.record}
                    onPageRefresh={ctx.onPageRefresh}
                />
            </div>
        )
    }
}

const renderLayoutChild = ctx => (child, key) => {
    const type = child.type
    const renderer = layoutComponents[type]

    if (!renderer) {

        return renderComponent(ctx)({ key, type })
    }

    return renderer(ctx)({
        key,
        ...child
    })
}

const renderLayout = ctx => (children) => children ? children.map(renderLayoutChild(ctx)) : null
class RecordPage extends React.Component {

    state = {
        data: null,
        invalidating: false,
        loading: true,
        noMatch: false
    }

    fetch = async () => {

        this.setState({
            loading: true
        })

        try {
            const response = await api.request({
                method: 'post',
                data: {
                    query: `
                        query table($tableId: ID!, $recordId: ID!) {
                            viewer {
                                record(tableId: $tableId, recordId: $recordId)
                                recordPage(tableId: $tableId)
                            }
                        }
                    `,
                    variables: {
                        tableId: this.props.tableId,
                        recordId: this.props.recordId
                    }
                }
            })

            this.setState({
                data: response.data.data.viewer.record,
                recordPage: response.data.data.viewer.recordPage,
                loading: false
            })

        } catch (e) {

            this.setState({
                noMatch: true
            })
        }
    }

    handleInvalidateRecord = async () => {

        this.setState({
            invalidating: true
        })

        await api.request({
            method: 'post',
            data: {
                query: `
                    mutation invalidateRecord($tableId: ID!, $recordId: ID!) {
                        invalidateRecord(tableId: $tableId, recordId: $recordId)
                    }
                `,
                variables: {
                    tableId: this.props.tableId,
                    recordId: this.props.recordId
                }
            }
        })

        this.setState({
            invalidating: false
        })

        this.fetch()
    }

    async componentDidMount() {
        await this.fetch()
    }

    async componentDidUpdate(prevProps) {

        if (prevProps.tableId !== this.props.tableId || prevProps.recordId !== this.props.recordId) {
            await this.fetch()
        }
    }

    render() {

        const primaryFieldId = this.props.table.get('primaryField')
        const name = this.state.data ? this.state.data[primaryFieldId] : null

        if (this.state.noMatch) {

            return (
                <div>
                    <Title>
                        {`${this.props.table.get('name')}: Record niet gevonden`}
                    </Title>
                    <Content>
                        <h1>
                            Record niet gevonden
                        </h1>
                    </Content>
                </div>
            )
        }

        return (
            <div>
                {this.state.loading || this.state.data.id !== this.props.recordId ? (
                    <div className={css`
                        position: absolute;
                        top: 0;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    `}>
                        {spinner({ width: 24 })}
                    </div>
                ) : (
                        <div>
                            <Title>
                                {`${this.props.table.get('name')}: ${name}`}
                            </Title>
                            <div
                                className={css`
                                    height: 70px;
                                    border-bottom: 1px solid #ebebeb;
                                    background-color: #fff;
                                `}
                            >
                                <div
                                    className={css`
        padding: 0 40px;
    `}
                                >
                                    <div
                                        className={css`
                                            font-size: 26px;
                                            display: flex;
                                            align-items: center;
                                            height: 70px;
                                        `}
                                    >
                                        <div
                                        >
                                            <Link
                                                className={css`
                                                    color: #b3b3b3;
                                                    text-decoration: none;
                                                    cursor: pointer;
                                                    font-weight: 700;
                                                `}
                                                to={`/explorer/${dashify(this.props.tableId)}`}
                                            >
                                                {this.props.table.get('plural')}
                                            </Link>
                                        </div>
                                        <div
                                            className={css`
                                                display: flex;
                                                margin-left: 12px;
                                                margin-right: 12px;
                                            `}
                                        >
                                            {icons.polygon({ height: 8 })}
                                        </div>
                                        <div>
                                            {name}
                                        </div>
                                        <div
                                            className={css`
                                                margin-left: auto;
                                            `}
                                        >
                                            <Button icon={this.state.invalidating ? spinner : null} onClick={this.handleInvalidateRecord}>
                                                Invalidate Record
                                </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Content>
                                {this.state.recordPage && this.state.recordPage.layout ? (
                                    <div
                                        className={css`
                                            margin-left: -15px;
                                            margin-right: -15px;
                                        `}
                                    >
                                        <div className={"container-fluid"}>
                                            {renderLayout({
                                                components: this.state.recordPage.components,
                                                record: this.state.data,
                                                tableId: this.props.tableId,
                                                recordId: this.props.recordId,
                                                fields: this.props.table.get('fields').toJS(),
                                                onPageRefresh: this.fetch
                                            })(this.state.recordPage.layout)}
                                        </div>
                                    </div>
                                ) : null}
                                {false ? (
                                    <div
                                        className={css`
                                    margin-bottom: 70px;
                                `}
                                    >
                                        <Record
                                            fields={this.props.table.get('fields').toJS()}
                                            data={this.state.data}
                                            onPageRefresh={this.fetch}
                                        />
                                    </div>
                                ) : null}
                                {false && this.state.recordPage ? (
                                    <div>
                                        {Object.keys(this.state.recordPage.components).map(id => {

                                            const component = this.state.recordPage.components[id]
                                            const Component = getComponent(component)

                                            const props = {
                                                source: this.state.data,
                                                recordId: this.props.recordId,
                                                tableId: this.props.tableId,
                                                onPageRefresh: this.handlePageRefresh,
                                                component
                                            }

                                            return (
                                                <div key={component.id} className={css`margin-bottom: 70px;`}>
                                                    <Component
                                                        {...props}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>
                                ) : null}
                            </Content>
                        </div>
                    )}
            </div>
        )
    }

    handlePageRefresh = async () => {
        await this.fetch()
    }
}

export default connect((state, props) => ({
    recordId: props.recordId,
    table: state.getIn(['tablesById', props.tableId])
}))(RecordPage)