import React from 'react'
import api from '../services/api'
import history from '../services/history'
import getQueryParam from '../services/getQueryParam'

export default class ToggleMutedForDebtorTrajectoryRoute extends React.Component {

    state = {
        data: null
    }

    async componentDidMount() {
        await this.execute()
    }

    execute = async () => {

        const token = getQueryParam('token')

        if (!token) {
            
            this.setState({
                error: true
            })
            return
        }

        try {

            const response = await api.request({
                method: 'post',
                data: {
                    query: `
                        mutation toggleMutedForDebtorTrajectory($id: ID!, $token: String!) {
                            toggleMutedForDebtorTrajectory(id: $id, token: $token) {
                                id
                                muted
                            }
                        } 
                    `,
                    variables: {
                        id: this.props.match.params.id,
                        token
                    }
                }
            })
    
            if (response.data.errors) {
                this.setState({
                    error: true
                })
                return
            }
    
            this.setState({
                data: response.data.data.toggleMutedForDebtorTrajectory
            })

            setTimeout(() => {
                history.push('/debtor-trajectory/' + this.props.match.params.id)
            }, 3000)

        } catch (e) {

            this.setState({
                error: true
            })
        }
    }

    render() {

        if (this.state.error) {
            return (
                <div>
                    Could not process your request. Contact the developer.
                </div>
            )
        }

        if (!this.state.data) {
            return (
                <div>
                    Processing...
                </div>
            )
        }

        return (
            <div>
                <h1>
                    Success
                </h1>
                <p>
                    The debtor trajectory has now been set to <b>{this.state.data.muted ? 'muted' : 'unmuted'}</b>.
                </p>
                <p>
                    You will now be redirected to the debtor trajectory detail page...
                </p>
            </div>
        )
    }
}