import React from 'react'
import { css } from 'emotion'
import Portal from './Portal'
import Fader from './Fader'
import RecordPage from './RecordPage'

export default class RecordDialog extends React.Component {

    render() {

        return (
            <Portal>
                <Fader width={1440} onClose={this.props.onClose}>
                    <div
                        className={css`
                            background-color: #f9f9f9;
                            display: flex;
                            flex-direction: column;
                            position: relative;
                            height: calc(100vh - 60px);
                            overflow: hidden auto;
                        `}
                    >
                        <RecordPage
                            {...this.props}
                        />
                    </div>
                </Fader>
            </Portal>
        )
    }
}