import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'
import Switch from '@pndr/switch'
import ProgressTimeline from '@pndr/progress-timeline'
import moment from 'moment'
import { css, cx } from 'emotion'
import numeral from 'numeral'
import defaultEmptyRenderer from './defaultEmptyRenderer';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { githubGist } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import jsonRenderer from './jsonRenderer'

class HtmlPreview extends React.Component {

    createMarkup = () => ({
        __html: this.props.value
    })

    render() {

        return (
            <div dangerouslySetInnerHTML={this.createMarkup()} />
        )
    }
}

class Source extends React.Component {

    state = {
        open: false
    }

    render() {

        return (
            <div
                className={css`
                    padding: 20px;
                `}
            >
                <strong>{this.props.type}</strong>
                <p>{this.props.id}</p>
                <div
                    className={css`
                        margin-bottom: 30px;
                    `}
                >
                    {this.props.children}
                </div>
                {this.props.payload ? (
                    <div
                        className={css`
                   background-color: #fff;
                   border-radius: 6px;
                   overflow: auto;
                   max-height: 400px;
                   margin-bottom: 20px;
                   border: 1px solid #f2f2f2;
               `}
                    >
                        <SyntaxHighlighter language="json" style={githubGist}>
                            {this.props.payload ? JSON.stringify(this.props.payload, null, 2) : null}
                        </SyntaxHighlighter>
                    </div>
                ) : null}
            </div>
        )
    }

    handleShow = () => this.setState({ open: true })
    handleHide = () => this.setState({ open: false })
}

class Currency extends React.Component {

    state = {
        hover: false
    }

    render() {

        const { value } = this.props
        const { hover } = this.state

        return (
            <div className={cx(css`cursor: pointer;`, value > 0 ? css`font-weight: bold; color:#000;` : null)}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}>
                {hover ? value : numeral(value).format('$0,0.00')}
            </div>
        )
    }

    handleMouseEnter = () => this.setState({ hover: true })

    handleMouseLeave = () => this.setState({ hover: false })
}

const types = {
    licensePlate: ({ value }) => {
        if (value === null) {
            return defaultEmptyRenderer()
        }
        return (
            <div
                className={css`
                    border: 2px solid #000;
                    border-radius: 3px;
                    font-weight: bold;
                    padding: 4px 8px;
                    display: inline-block;
                    background-color: #ffd102;
                    color: #000;
                `}
            >
                {value}
            </div>
        )
    },
    progressTimeline: ({ value }) => {

        return (
            <ProgressTimeline
                steps={[8, 9, 10, 20, 25, 29, 30, 31]}
                value={value}
            />
        )
    },
    eventDetails: ({ value }) => {

        return (
            <Source id={value.id} type={value.type} payload={value.payload} />
        )
    },
    eventEntityLink: ({ value: { entityType, entityId } }) => {

        if (!entityType) {
            return defaultEmptyRenderer()
        }

        if (!entityId) {
            return defaultEmptyRenderer()
        }

        const entityTypeRoutes = {
            'Message': 'message',
            'Contract': 'contract',
            'Invoice': 'invoice',
            'DebtorTrajectory': 'debtor-trajectory',
        }

        const entityTypeRoute = entityTypeRoutes[entityType]

        if (entityTypeRoute) {
            return (
                <Link to={`/${entityTypeRoute}/${entityId}`} target={'_blank'}>
                    Open details ({entityId})
                </Link>
            )
        }

        return entityId
    },
    contractType: ({ value }) => {

        const types = {
            'LEASE': { name: 'Lease', color: '#00848E' },
            'SHORTLEASE': { name: 'Shortlease', color: '#084E8A' },
            'VERHUUR': { name: 'Verhuur', color: '#202E78' },
            'VOORLOOPAUTO': { name: 'Voorloop auto', color: '#50248F' }
        }

        const { color, name } = types[value] || { name: 'Unknown', color: '#000' }

        return (
            <div className={css`display: flex; align-items: center;`}>
                <div
                    className={css`
                    background-color: ${color};
                    padding: 2px 8px;
                    font-size: 12px;
                    border-radius: 99999px;
                    display: inline-block;
                    color: #fff;
                    font-weight: 700;
                `}
                >
                    {name}
                </div>
            </div>
        )
    },
    invoiceTypeId: ({ value }) => {

        let name = null
        let color = null

        if (value === 'PERIOD') {
            name = 'Period'
            color = '#007ACE'
        }

        if (value === 'OTHER') {
            name = 'Other'
            color = '#108043'
        }

        return (
            <div className={css`display: flex; align-items: center;`}>
                <div
                    className={css`
                    background-color: ${color};
                    padding: 2px 8px;
                    font-size: 12px;
                    border-radius: 99999px;
                    display: inline-block;
                    color: #fff;
                    font-weight: 700;
                `}
                >
                    {name}
                </div>
            </div>
        )
    },
    markdown: ({ value }) => {
        if (!value) return defaultEmptyRenderer()

        return (
            <ReactMarkdown source={value} />
        )
    },
    badge: ({ value }) => {

        if (!value) return defaultEmptyRenderer()

        return (
            <div className={css`display: flex; align-items: center;`}>
                <div
                    className={css`
                    background-color: #EEC200;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 26px;
                    height: 26px;
                    font-size: 10px;
                    border-radius: 50%;
                    color: #fff;
                    font-weight: 700;
                `}
                >
                    {value}
                </div>
            </div>

        )
    },
    invoiceDetailTypeId: ({ value }) => {

        let name = null
        let color = null

        if (value === 'period') {
            name = 'Period'
            color = '#9C6ADE'
        }

        if (value === 'other') {
            name = 'Other'
            color = '#47C1BF'
        }

        return (
            <div className={css`display: flex; align-items: center;`}>
                <div
                    className={css`
                    background-color: ${color};
                    padding: 2px 8px;
                    font-size: 12px;
                    border-radius: 99999px;
                    display: inline-block;
                    color: #fff;
                    font-weight: 700;
                `}
                >
                    {name}
                </div>
            </div>

        )
    },
    contractState: ({ value }) => {

        let name = null
        let color = null

        if (value === 'not_started') {
            name = 'Not started'
            color = '#637381'
        }

        if (value === 'active') {
            name = 'Active'
            color = '#50B83C'
        }

        if (value === 'ended') {
            name = 'Ended'
            color = '#212B36'
        }

        return (
            <div className={css`display: flex; align-items: center;`}>
                <div
                    className={css`
                    background-color: ${color};
                    padding: 2px 8px;
                    font-size: 12px;
                    border-radius: 99999px;
                    display: inline-block;
                    color: #fff;
                    font-weight: 700;
                `}
                >
                    {name}
                </div>
            </div>

        )
    },
    mailExample: ({ value }) => {

        return (
            <iframe
                className={css`
                    width: 100%;
                    border: 1px solid #e0e0e0;
                    border-radius: 6px;
                    height: 600px;
                `}
                src={`${window._env_.REACT_APP_API_URL}/mail-templates/${value}`}
                title={value}
            />
        )
    },
    phase: ({ value }) => {

        let name = null
        let color = null

        if (value === 'PRE_PHASE_2') {
            name = 'Phase -1'
            color = '#888'
        }

        if (value === 'PRE_PHASE_1') {
            name = 'Phase 0'
            color = '#888'
        }

        if (value === 'PHASE_1') {
            name = 'Phase 1'
            color = '#FEB24C'
        }

        if (value === 'PHASE_2') {
            name = 'Phase 2'
            color = '#FD8D3C'
        }

        if (value === 'PHASE_3') {
            name = 'Phase 3'
            color = '#FC4E2A'
        }

        if (value === 'PHASE_4') {
            name = 'Phase 4'
            color = '#E31A1C'
        }

        if (value === 'PHASE_5') {
            name = 'Phase 5'
            color = '#BD0026'
        }

        if (value === 'PHASE_6') {
            name = 'Phase 6'
            color = '#800026'
        }

        return (
            <div className={css`display: flex; align-items: center;`}>
                <div
                    className={css`
                    background-color: ${color};
                    padding: 2px 8px;
                    font-size: 12px;
                    border-radius: 99999px;
                    display: inline-block;
                    color: #fff;
                    font-weight: 700;
                `}
                >
                    {name}
                </div>
            </div>

        )
    },
    dtgScore: ({ value }) => {

        if (value === null) return defaultEmptyRenderer()

        let name = null
        let color = null

        if (value < 8) return value

        if (value === 8) {
            name = 'Phase -1'
            color = '#888'
        }

        if (value === 9) {
            name = 'Phase 0'
            color = '#888'
        }

        if (value >= 10 && value <= 19) {
            name = 'Phase 1'
            color = '#FEB24C'
        }

        if (value >= 20 && value <= 24) {
            name = 'Phase 2'
            color = '#FD8D3C'
        }

        if (value >= 25 && value <= 28) {
            name = 'Phase 3'
            color = '#FC4E2A'
        }

        if (value === 29) {
            name = 'Phase 4'
            color = '#E31A1C'
        }

        if (value === 30) {
            name = 'Phase 5'
            color = '#BD0026'
        }

        if (value >= 31) {
            name = 'Phase 6'
            color = '#800026'
        }

        return (
            <div className={css`display: flex; align-items: center;`}>
                <div
                    className={css`
                        flex-grow: 1;
                    `}
                >
                    {value}
                </div>
                <div
                    className={css`
                    background-color: ${color};
                    padding: 2px 8px;
                    font-size: 12px;
                    border-radius: 99999px;
                    display: inline-block;
                    color: #fff;
                    font-weight: 700;
                    margin-left: 16px;
                `}
                >
                    {name}
                </div>
            </div>

        )
    },
    currency: ({ value }) => {
        return <Currency value={value} />
    },
    distance: ({ value }) => {
        if (value === null) return defaultEmptyRenderer()
        return numeral(value).format() + ' km'
    },
    percentage: ({ value }) => {
        if (!value && value !== 0) return defaultEmptyRenderer()

        return value + '%'
    },
    issueStateId: ({ value }) => {

        if (!value) return defaultEmptyRenderer()

        return (
            <div
                className={css`
                    background-color: ${value === 'resolved' ? '#E3F1DF' : value === 'open' ? '#FCF1CD' : value === 'ignored' ? '#FBEAE5' : '#000'};
                    color: ${value === 'resolved' ? '#108043' : value === 'open' ? '#9C6F19' : value === 'ignored' ? '#BF0711' : '#000'};
                    padding: 2px 8px;
                    font-size: 12px;
                    border-radius: 99999px;
                    display: inline-block;
                    font-weight: 700;
                `}
            >
                {value === 'resolved' ? '✅ Resolved' : value === 'open' ? '⚠️ Open' : value === 'ignored' ? '🚫 Ignored' : 'Unknown'}
            </div>
        )
    },
    issueTypeId: ({ value }) => {

        if (!value) return defaultEmptyRenderer()

        const green = '#00848E'
        const red = '#108043'

        return (
            <div
                className={css`
                    background-color: ${value === 'contract' ? green : red};
                    padding: 2px 8px;
                    font-size: 12px;
                    border-radius: 99999px;
                    display: inline-block;
                    color: #fff;
                    font-weight: 700;
                `}
            >
                {value === 'contract' ? 'Contract Issue' : 'Import Issue'}
            </div>
        )
    },
    contractPeriodTypeId: ({ value }) => {

        if (!value) return defaultEmptyRenderer()

        const green = '#4cd964'
        const red = '#dc3545'

        return (
            <div
                className={css`
                    background-color: ${value === 'debit' ? green : red};
                    padding: 2px 8px;
                    font-size: 12px;
                    border-radius: 99999px;
                    display: inline-block;
                    color: #fff;
                    font-weight: 700;
                `}
            >
                {value === 'debit' ? 'Debit' : 'Credit'}
            </div>
        )
    },
    invoiceState: ({ value }) => {

        if (!value) return defaultEmptyRenderer()

        const green = '#4cd964'
        const red = '#dc3545'

        return (
            <div
                className={css`
                    background-color: ${value === 'paid' ? green : red};
                    padding: 2px 8px;
                    font-size: 12px;
                    border-radius: 99999px;
                    display: inline-block;
                    color: #fff;
                    font-weight: 700;
                `}
            >
                {value === 'paid' ? 'Paid' : 'Unpaid'}
            </div>
        )
    },
    state: ({ value }) => {

        if (!value) return defaultEmptyRenderer()

        const green = '#2dc65e'
        const red = '#dc3545'

        return (
            <div
                className={css`
                    background-color: ${value === 'SUCCESS' ? green : red};
                    padding: 4px 12px;
                    font-size: 12px;
                    border-radius: 99999px;
                    display: inline-block;
                    color: #fff;
                    font-weight: 700;
                `}
            >
                {value}
            </div>
        )
    },
    link: ({ value }) => {

        if (!value) return defaultEmptyRenderer()

        const { to, name } = value

        return (
            <Link to={to}>
                {name}
            </Link>
        )
    },
    href: ({ value }) => {

        if (!value) return defaultEmptyRenderer()

        const { href, target, name } = value

        return (
            <a href={href} target={target}>
                {name}
            </a>
        )
    },
    boolean: ({ value }) => {
        if (value === null) return defaultEmptyRenderer()
        return <Switch value={value} width={25} />
    },
    date: ({ value }) => {
        return value && moment(value).isValid() ?
            <span title={moment(value).toISOString()}>{moment(value).format('ddd D MMM YYYY')}</span> : defaultEmptyRenderer()
    },
    dateTime: ({ value }) => {
        return value && moment(value).isValid() ?
            <span title={moment(value).toISOString()}>{moment(value).format('ddd D MMM YYYY HH:mm:ss')}</span> : defaultEmptyRenderer()
    },
    html: ({ value }) => (
        <div
            className={css`
                background-color: #fff;
                border-radius: 6px;
                overflow: auto;
                padding: 8px;
                max-height: 400px;
                border: 1px solid #f2f2f2;
            `}
        >
            <HtmlPreview value={value} />
        </div>
    ),
    json: jsonRenderer,
    code: ({ value }) => {

        if (value === null) return defaultEmptyRenderer()

        return (
            <span
                className={css`
                padding: .2em .4em;
                margin: 0;
                font-size: 85%;
                background-color: rgba(27,31,35,.05);
                border-radius: 3px;
            `}
            >
                {value}
            </span>
        )
    },
    js: ({ value }) => {

        if (value === null) return defaultEmptyRenderer()

        return (
            <SyntaxHighlighter language="js" style={githubGist} className={css`background-color: #fff; border: 1px solid #e0e0e0; border-radius: 3px; overflow: auto; margin-top: 12px; font-size: 11px; max-height: 200px;`}>
                {value}
            </SyntaxHighlighter>
        )
    },
    tags: ({ value }) => {

        if (!value) return defaultEmptyRenderer()

        return (
            <div>
                {value.map(tag => (
                    <span
                        key={tag}
                        className={css`
                    background-color: #e0ebfd;
                    padding: 4px 8px;
                    color: #000;
                    font-size: 12px;
                    border-radius: 3px;
                    margin-right: 4px;
                    margin-bottom: 4px;
                `}
                    >
                        {tag}
                    </span>
                ))}
            </div>
        )
    }
}

export default type => ({ value }) => {

    const Type = types[type]

    if (Type) {
        return Type({ value })
    }

    return value
}