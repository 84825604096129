import React from "react"
import SchemaInfo from '../components/SchemaInfo'
import PageHeader from "../components/PageHeader"
import Title from "../components/Title"
import Content from "../components/Content"

class DocumentationRoute extends React.Component {

    render() {

        return (
            <div>
                <Title>
                    Documentatie
                </Title>
                <PageHeader title={'Documentatie'} />
                <Content>
                    <SchemaInfo />
                </Content>
            </div>
        )
    }
}

export default DocumentationRoute