import React from 'react'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { css, cx } from 'emotion'
import spinner from '@pndr/spinner'
import arrowLeft from '@pndr/icons/lib/arrowLeft'
import arrowRight from '@pndr/icons/lib/arrowRight'
import api from '../services/api'
import Box from '../components/Box'
import StandardMonthChart from '../components/StandardMonthChart'
import StandardMonthDTGCard from '../components/StandardMonthDTGCard'
import StandardYearChart from '../components/StandardYearChart'
import StandardYearDTGCard from '../components/StandardYearDTGCard'

class SegmentedControlItem extends React.Component {

    state = {
        hover: false
    }

    render() {

        return (
            <div
                className={cx(
                    css`
                    background-color: #fff;
                    border-right: 1px solid #f2f2f2;
                    padding: 8px 7px;
                    cursor: pointer;
                    &:hover {
                        background-color: rgba(0,119,255,.1);
                    }
                    &:last-of-type {
                        border-right: none;
                    }
                `
                )}
                style={{
                    width: this.props.width ? this.props.width : 'auto'
                }}
                onMouseEnter={() => this.setState({ hover: true })}
                onMouseLeave={() => this.setState({ hover: false })}
                onClick={(e) => this.props.onClick({ e, id: this.props.id })}
            >
                <div
                    className={cx(
                        css`
                        font-size: 14px;
                        color: ${this.props.active || this.state.hover ? '#07f' : '#4d4d4d'};
                        font-weight: ${this.props.description || this.props.active ? '700' : '400'};
                        letter-spacing: ${this.props.active ? '0' : '0.252px'};
                        text-align: center;
                    `,
                        this.props.description ? css`
                        margin-bottom: 4px;
                    ` : null
                    )}
                >
                    {this.props.name}
                </div>
                {this.props.description ? (
                    <div
                        className={css`
                        font-size: 12px;
                        color: grey;
                        letter-spacing: ${this.props.active ? '0' : '0.252px'};
                        text-align: center;
                    `}
                    >
                        {this.props.description}
                    </div>
                ) : null}
            </div>
        )
    }
}

const SegmentedControlButton = ({ onClick, className, disabled, children }) => (
    <div
        onClick={e => {
            if (disabled) {
                return
            }
            onClick(e)
        }}
        className={cx(
            css`
            position: absolute;
            top: 0;
            width: 35px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
        `,
            disabled ? css`
            color: #aaaaaa;
            cursor: not-allowed;
            ` : css`
                cursor: pointer;
            `,
            className
        )}
    >
        {children}
    </div>
)

const SegmentedControlPrevButton = props => (
    <SegmentedControlButton
        {...props}
        className={css`
            right: 0;
        `}
    >
        {arrowRight({ width: 16 })}
    </SegmentedControlButton>
)

const SegmentedControlNextButton = props => (
    <SegmentedControlButton
        {...props}
        className={css`
        left: 0;
    `}
    >
        {arrowLeft({ width: 16 })}
    </SegmentedControlButton>
)
class SegmentedControl extends React.Component {

    container = null
    content = null

    state = {
        changed: false,
        paginated: false,
        offset: 0,
        index: 0
    }

    constructor(props) {
        super(props)
        this.handleResize = debounce(this.initialize, 200)
    }

    componentDidMount() {

        this.initialize()
        window.addEventListener('resize', this.handleResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.items !== this.props.items) {
            this.initialize()
        }
    }

    initialize = () => {

        const { container, content } = this

        const offset = container.clientWidth - content.clientWidth - 70

        const itemWidth = this.props.itemWidth
        const pageWidth = container.clientWidth - 70
        const itemsPerPage = Math.floor(pageWidth / itemWidth)
        const maxIndex = this.props.items.length - itemsPerPage

        this.setState({
            changed: false,
            paginated: content.clientWidth > container.clientWidth,
            pageWidth,
            itemsPerPage,
            itemWidth,
            maxIndex,
            offset,
            index: 0
        })
    }

    handlePrev = () => {
        console.log('handlePrev')
        this.setState({
            changed: true,
            index: Math.max(this.state.index - this.state.itemsPerPage, 0)
        })
    }

    handleNext = () => {
        console.log('handleNext')
        this.setState({
            changed: true,
            index: Math.min(this.state.index + this.state.itemsPerPage, this.state.maxIndex)
        })
    }

    render() {

        console.log({
            state: this.state
        })

        const translate = this.state.offset + (this.state.index * this.state.itemWidth)

        console.log('offset', this.state.offset)
        console.log('pageSize', this.state.itemWidth)
        console.log('translate', translate)

        return (
            <div
                ref={ref => this.container = ref}
                className={css`
                    max-width: 100%;
                    overflow: hidden;
                    position: relative;
                    -webkit-box-shadow: 0 0 0 1px rgba(0,0,0,.09), 0 1px 0 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.1);
                    box-shadow: 0 0 0 1px rgba(0,0,0,.09), 0 1px 0 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.1);
                    border-radius: 5px;
                `}
            >
                <div
                    ref={ref => this.content = ref}
                    className={cx(
                        css`
            font-size: 15px;
            overflow: hidden;
            display: flex;
            align-items: center;
            display: inline-flex;
        `,
                        this.state.changed ? css`
        transition: 200ms transform ease;
        ` : null,
                        this.state.paginated ? css`
                        border-left: 1px solid #f2f2f2;
                        border-right: 1px solid #f2f2f2;
        margin-left: 35px;
        margin-right: 35px;
            ` : null
                    )}
                    style={this.state.paginated ? {
                        transform: `translateX(${translate}px)`
                    } : null}
                >
                    {this.props.children}
                </div>
                {this.state.paginated ? (
                    <SegmentedControlNextButton disabled={this.state.index === this.state.maxIndex} onClick={this.handleNext} />
                ) : null}
                {this.state.paginated ? (
                    <SegmentedControlPrevButton disabled={this.state.index === 0} onClick={this.handlePrev} />
                ) : null}
            </div>
        )
    }
}

const getEntityState = ({ entityTypeId, standardMonths, standardYears }) => {

    let entities = null
    let entityId = null

    if (entityTypeId === 'StandardMonth') {
        entities = standardMonths
        entityId = standardMonths.length ? standardMonths[standardMonths.length - 1].id : null
    }

    if (entityTypeId === 'StandardYear') {
        entities = standardYears
        entityId = standardMonths.length ? standardYears[standardYears.length - 1].id : null
    }

    return { entities, entityId }
}

class ContractStatistics extends React.Component {

    state = {
        loading: true,
        data: null,
        entityTypeId: 'StandardMonth',
        entityId: null,
        entities: [],
        entityTypes: [
            { id: 'StandardMonth', name: 'Maanden' },
            { id: 'StandardYear', name: 'Jaren' }
        ]
    }

    componentDidMount() {
        this.fetch()
    }

    componentDidUpdate() {

    }

    fetch = async () => {

        const response = await api.request({
            method: 'post',
            data: {
                query: `
                    query dashboardStatistics($id: ID!) {
                        viewer {
                            dashboardStatistics(id: $id)
                        }
                    }
                `,
                variables: {
                    id: this.props.recordId
                }
            }
        })

        const data = response.data.data.viewer.dashboardStatistics

        const standardYears = data.standardYears.map(standardYear => ({
            id: standardYear.id,
            name: standardYear.name
        }))

        const standardMonths = data.standardMonths.map(standardMonth => ({
            id: standardMonth.id,
            name: moment(standardMonth.date).format('MMMM'),
            description: moment(standardMonth.date).format('YYYY')
        }))

        const { entities, entityId } = getEntityState({
            entityTypeId: this.state.entityTypeId,
            standardMonths,
            standardYears
        })

        const standardYearsById = data.standardYears.reduce((result, standardYear) => {
            result[standardYear.id] = standardYear
            return result
        }, {})

        const standardMonthsById = data.standardMonths.reduce((result, standardMonth) => {
            result[standardMonth.id] = standardMonth
            return result
        }, {})

        this.setState({
            loading: false,
            data,
            standardYearsById,
            standardMonthsById,
            standardYears,
            standardMonths,
            entities,
            entityId
        })
    }

    handlePageSizeChange = (pageSize) => {
        this.setState({
            pageSize
        })
    }

    handlePageSizeChange = (pageSize) => {
        this.setState({
            pageSize
        })
    }

    handleEntityTypeClick = ({ id }) => {

        const { entities, entityId } = getEntityState({
            entityTypeId: id,
            standardMonths: this.state.standardMonths,
            standardYears: this.state.standardYears
        })

        this.setState({
            entityTypeId: id,
            entities,
            entityId
        })
    }

    handleEntityClick = ({ id }) => {

        this.setState({
            entityId: id
        })
    }

    render() {

        return (
            <div>
                <h2>{this.props.component.name}</h2>
                {this.state.entityId
                    ? this.renderContent()
                    : this.renderEmptyState()
                }
            </div>
        )
    }

    renderEmptyState() {

        return (
            <Box>
                <div
                    className={css`
                    height: 500px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                `}
                >
                    <div
                        className={css`
                        font-weight: bold;
                        margin-bottom: 8px;
                        font-size: 16px;
                    `}
                    >
                        Nog geen statistieken beschikbaar
                </div>
                    <div
                        className={css`
                        color: #aaa;
                    `}
                    >
                        Er is nog geen data opgebouwd
                </div>
                </div>
            </Box>
        )
    }

    renderContent() {

        if (this.state.loading) {
            return spinner({ width: 24 })
        }

        const components = {
            StandardMonth: {
                source: this.state.standardMonthsById[this.state.entityId],
                Chart: StandardMonthChart,
                Block1: StandardMonthDTGCard,
                Block2: null,
                Block3: null,
            },
            StandardYear: {
                source: this.state.standardYearsById[this.state.entityId],
                Chart: StandardYearChart,
                Block1: StandardYearDTGCard,
                Block2: null,
                Block3: null,
            }
        }

        const { source, Chart, Block1, Block2, Block3 } = components[this.state.entityTypeId]

        return (
            <div>
                <div
                    className={css`
                        display: flex;
                        align-items: center;
                        margin-bottom: 16px;
                    `}
                >
                    <div
                        className={css`
                            margin-left: auto;
                        `}
                    >
                        <SegmentedControl
                            itemWidth={110}
                            items={this.state.entityTypes}
                        >
                            {this.state.entityTypes.map(entityType => (
                                <SegmentedControlItem
                                    id={entityType.id}
                                    name={entityType.name}
                                    active={entityType.id === this.state.entityTypeId}
                                    onClick={this.handleEntityTypeClick}
                                    width={110}
                                />
                            ))}
                        </SegmentedControl>
                    </div>
                </div>
                <div
                    className={css`
                        display: flex;
                        align-items: center;
                        margin-bottom: 32px;
                    `}
                >
                    <div
                        className={css`
                        margin-left: auto;
                        max-width: 100%;
                    `}
                    >
                        <SegmentedControl
                            itemWidth={110}
                            items={this.state.entities}
                        >
                            {this.state.entities.map(entity => (
                                <SegmentedControlItem
                                    id={entity.id}
                                    name={entity.name}
                                    description={entity.description}
                                    active={entity.id === this.state.entityId}
                                    onClick={this.handleEntityClick}
                                    width={110}
                                />
                            ))}
                        </SegmentedControl>
                    </div>
                </div>
                <div>
                    <div
                        className={css`
                                margin-bottom: 32px;
                        `}
                    >
                        <Chart
                            recordId={this.state.entityId}
                            source={source}
                        />
                    </div>
                    {Block1 || Block2 || Block3 ? (
                        <div className="row">
                            {Block1 ? (
                                <div className="col">
                                    <Block1
                                        component={{
                                            name: 'Gemiddelde DTG'
                                        }}
                                        recordId={this.state.entityId}
                                        source={source}
                                    />
                                </div>
                            ) : null}
                            {Block2 ? (
                                <div className="col">
                                    <Block2
                                        component={{
                                            name: 'Aantal achterstandstrajecten'
                                        }}
                                        recordId={this.state.entityId}
                                        source={source}
                                    />
                                </div>
                            ) : null}
                            {Block3 ? (
                                <div className="col">
                                    <Block3
                                        component={{
                                            name: 'Totaal aantal dagen in achterstandstraject'
                                        }}
                                        recordId={this.state.entityId}
                                        source={source}
                                    />
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            </div>
        )
    }
}

export default ContractStatistics