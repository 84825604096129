import React from "react"
import { css } from 'emotion'
import Box from '../components/Box'
import FieldTooltip from '../components/FieldTooltip'
import FieldTechnical from '../components/FieldTechnical'
import api from '../services/api'

const renderHasManyRelationship = props => (
    <React.Fragment>
        <span className={css`margin-right: 1px;`}>
            {'['}
        </span>
        <span className={css`color: #CA9800;`}>
            {props.relationship.table}
        </span>
        <span className={css`margin-left: 1px;`}>
            {']'}
        </span>
    </React.Fragment>
)

const renderHasOneRelationship = props => (
    <React.Fragment>
        <span className={css`color: #CA9800;`}>
            {props.relationship.table}
        </span>
    </React.Fragment>
)

const RelationshipTechnical = props => (
    <React.Fragment>
        <span className={css`color: #B11A04;`}>{props.relationship.id}</span> : {props.relationship.type === 'hasMany' ? renderHasManyRelationship(props) : renderHasOneRelationship(props)}{props.relationship.onDelete === 'cascade' ? <span className={css`margin-left: 4px; background-color: #f9f9f9; color: #333; font-size: 11px; padding: 1px 4px; border-radius: 2px;`} title="The related entity will be removed when the parent get's removed">cascade</span> : null}
    </React.Fragment>
)

const RelationshipInfo = ({ relationship }) => (
    <div
        className={css`
                    position: relative;
                    padding: 2px 16px;
                    cursor: pointer;
                    &:hover {
                        background-color: #f7fbff;
                    }
                `}
    >
        <RelationshipTechnical relationship={relationship} />
    </div>
)

const FieldInfo = ({ field }) => (
    <div
        className={css`
                    position: relative;
                    padding: 2px 16px;
                    cursor: pointer;
                    &:hover {
                        background-color: #f7fbff;
                    }
                `}
    >
        <FieldTooltip field={field} placement="right">
            <FieldTechnical field={field} />
        </FieldTooltip>
    </div>
)

const TableInfo = props => (
    <div
        className={css`
            border: 1px solid #e0e0e0;
            width: 500px;
            border-radius: 6px;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 400px;
            margin-right: 20px;
            overflow: hidden;
            &:last-of-type {
                margin-right: 0;
            }
        `}
    >
        <div
            className={css`
      
                margin-bottom: 16px;
                padding: 16px;
                background-color: #f9f9f9;
            `}
        >
            <div
                className={css`
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 4px;
                `}
            >
                {props.table.name}
            </div>
            <div
                className={css`
                        color: #B11A04;
                        margin-bottom: 8px;
                    `}
            >
                {props.table.id}
            </div>
            <div
                className={css`
                    color: #787878;
                `}
            >
                {props.table.plural}
            </div>

        </div>
        <div
            className={css`
                font-weight: bold;
                margin-bottom: 8px;
                padding: 0 16px;
            `}
        >
            Fields
        </div>
        <div
            className={css`
                margin-bottom: 20px;
            `}
        >
            {Object.keys(props.table.fields).map(id => {

                const field = props.table.fields[id]

                return (
                    <FieldInfo
                        key={field.id}
                        field={field}
                    />
                )
            })}
        </div>
        <div
            className={css`
                font-weight: bold;
                margin-bottom: 8px;
                padding: 0 16px;
            `}
        >
            Relationships
        </div>
        {props.table.relationships ? (
            <div
                className={css`
                            margin-bottom: 20px;
            `}>
                {Object.keys(props.table.relationships).map(id => {

                    const relationship = props.table.relationships[id]

                    return (
                        <RelationshipInfo
                            key={relationship.id}
                            relationship={relationship}
                        />
                    )
                })}
            </div>
        ) : (
                <div
                    className={css`
                                margin-bottom: 20px;
                                padding: 0 16px;
                                color: #787878;
                `}>
                    No relationships defined
                </div>
            )}
    </div>
)

class SchemaInfo extends React.Component {

    state = {
        data: null
    }

    fetch = async () => {

        const response = await api.request({
            method: 'post',
            data: {
                query: `
                    query info {
                        viewer {
                            schemaInfo
                        }
                    }
                `
            }
        })

        this.setState({
            data: response.data.data.viewer.schemaInfo
        })
    }

    async componentDidMount() {
        await this.fetch()
    }

    render() {

        if (!this.state.data) {
            return (
                <div>
                    Loading...
                </div>
            )
        }

        const tableNames = Object.keys(this.state.data.tables).map(id => {
            const table = this.state.data.tables[id]
            return table.name
        })

        return (
            <div>
                <h2>
                    Schema
                </h2>
                <p>
        LesautoDeal Pi bestaat uit <strong>{Object.keys(this.state.data.tables).length}</strong> verschillende entiteiten, waaronder: {tableNames.map(name => <span className={css`margin-right: 4px; margin-bottom: 4px; font-size: 12px; display: inline-block; font-weight: bold; background-color: #d6ecff; color: #3d4eac; border-radius: 20px; padding: 2px 8px;`}>{name}</span>)}
                </p>
                <Box>
                    <div
                        className={css`
                    overflow: auto hidden;
                `}
                    >
                        <div
                            className={css`
                                padding: 32px;
                            `}
                        >
                            <div
                                className={css`
                    `}
                            >
                                {Object.keys(this.state.data.tables).map(id => {

                                    const table = this.state.data.tables[id]

                                    return (
                                        <div
                                            className={css`
                                                margin-bottom: 30px;
                                            `}
                                        >
                                            <TableInfo
                                                key={table.id}
                                                table={table}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </Box>
            </div>
        )
    }
}

export default SchemaInfo