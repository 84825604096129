import React from 'react'
import { css } from 'emotion'
import isNil from 'lodash/isNil'
import defaultEmptyRenderer from "./defaultEmptyRenderer"

const StatisticsDiffPct = ({ value, reverse }) => {

    const isPositive = reverse ? value < 0 : value > 0
    const isNegative = reverse ? value > 0 : value < 0

    const isIncrease = value > 0
    const isDecrease = value < 0

    return (
        <span
            className={css`
                                                    color: #b3b3b3;
                                                    font-weight: 700;
                                                    display: flex;
                                                    align-items: center;
                                                    color: #b3b3b3;
                                                    ${isPositive ? 'color: #4cd964;' : ''}
                                                    ${isNegative ? 'color: #ff4a1f;' : ''}
                                                    `}
        >
            {isPositive || isNegative ? (
                <span
                    className={css`
                                                        border-radius: 50%;
                                                        height: 15px;
                                                        width: 15px;
                                                        font-size: 12px;
                                                        display: inline-block;
                                                        line-height: 1;
                                                        margin-right: 5px;
                                                        text-align: center;
                                                        ${isNegative ? 'background-color: rgba(255,74,31,.1);' : ''}
                                                        ${isPositive ? 'background-color: rgba(76,217,100,.1);' : ''}
                                                        `}
                >
                    {isIncrease ? '▴' : ''}
                    {isDecrease ? '▾' : ''}
                </span>
            ) : null}
            <span>{isNil(value) ? defaultEmptyRenderer() : Math.abs(value).toFixed(1)} %</span>
        </span>
    )
}

export default StatisticsDiffPct