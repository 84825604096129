import React from 'react'
import DifferenceCard from './DifferenceCard'

const ContractYearDTGCard = ({ component, source }) => (
    <DifferenceCard
        title={component.name}
        value={source.dtgMean}
        differences={[
            {
                title: 'Vergeleken met vorig jaar',
                prev: source.dtgMeanPrev,
                diff: source.dtgMeanDiff,
                diffPct: source.dtgMeanDiffPct,
                reverse: true
            },
            {
                title: 'Vergeleken met LesautoDeal gemiddelde',
                prev: source.stdDtgMean,
                diff: source.stdDtgMeanDiff,
                diffPct: source.stdDtgMeanDiffPct,
                reverse: true
            }
        ]}
    />
)

export default ContractYearDTGCard