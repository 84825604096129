import React from 'react'
import { connect } from 'react-redux'
import { css } from 'emotion'
import spinner from '@pndr/spinner'
import api from '../services/api'
import history from '../services/history'
import Box from '../components/Box'
import Pagination from '../components/Pagination'
import Table from '../components/Table'
import EmptyState from '../components/EmptyState'
import ComponentHeader from '../components/ComponentHeader'

class HasMany extends React.Component {

    state = {
        offset: 0,
        limit: 100,
        data: null,
        loading: true
    }

    fetch = async () => {

        const response = await api.request({
            method: 'post',
            params: {
                hasMany: this.props.component.id  
            },
            data: {
                query: `
                    query hasMany($input: HasManyInput!) {
                        viewer {
                            hasMany(input: $input)
                        }
                    }
                `,
                variables: {
                    input: {
                        tableId: this.props.tableId,
                        recordId: this.props.recordId,
                        componentId: this.props.component.id,
                        offset: this.state.offset,
                        limit: this.state.limit
                    }
                }
            }
        })

        this.setState({
            data: response.data.data.viewer.hasMany,
            loading: false
        })
    }

    async componentDidMount() {
        await this.fetch()
    }

    render() {

        return (
            <div>
                <ComponentHeader {...this.props} />
                {!this.state.loading ? (
                    <div
                        className={css`margin-top: 12px; margin-bottom: 12px;`}

                    >
                        <div className={css``}>
                            <Pagination
                                recordCount={this.state.data.totalCount}
                                offset={this.state.offset}
                                limit={this.state.limit}
                                onOffsetChange={this.handleOffsetChange}
                            />
                        </div>
                    </div>
                ) : null}
                {this.renderContent()}
            </div>
        )
    }

    handleOffsetChange = offset => {

        this.setState({
            offset
        }, () => this.fetch())
    }

    renderContent() {

        if (this.state.loading) {
            return (
                <Box p={32}>
                    {spinner({ width: 24 })}
                </Box>
            )
        }

        if (this.state.data.totalCount === 0) {
            return (
                <EmptyState>
                    Geen records gevonden
                </EmptyState>
            )
        }

        return (
            <div
                className={css`
                    overflow: hidden;
                    border-radius: 6px;
                    border: 1px solid #ebebeb;
                    background-color: #fff;
                `}
                style={{ height: this._getTableHeight(this.state.data.records.length) }}
            >
                <Table
                    ref={'table'}
                    tableId={this.props.component.props.linkTable}
                    fields={this.props.table.get('fields').toJS()}
                    rows={this.state.data.records}
                    onRecordClick={this.handleRecordClick}
                    onPageRefresh={this.handlePageRefresh}
                />
            </div>
        )
    }

    handlePageRefresh = async () => {
        await this.fetch()
        if (this.refs.table) {
            this.refs.table.update()
        }
    }

    handleRecordClick = ({ recordId }) => {
        history.push(`/explorer/${this.props.table.get('slug')}/${recordId}`)
    }

    _getTableHeight = count => Math.min(500, (count * 50) + 20 + 44)
}

export default connect((state, props) => ({
    table: state.getIn(['tablesById', props.component.props.linkTable])
}))(HasMany)