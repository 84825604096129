import React from 'react'
import { css } from 'emotion'

const Card = ({ children }) => (
    <div
        className={css`
        border: 1px solid #f2f2f2;
        border-radius: 8px;
        box-shadow: 0 3px 6px rgba(0,0,0,.03);
        background-color: #fff;
        `}
    >
        {children}
    </div>
)

export default Card