import React from 'react'
import { connect } from 'react-redux'
import compact from 'lodash/compact'
import { css, cx } from 'emotion'
import Box from '../components/Box'
import api from '../services/api'
import Button from '@pndr/button'
import toCurrency from '../toCurrency'

const defaultPhaseRenderer = () => null

const Breadcrumbs = ({ children }) => {
    return (
        <div
            className={css`
                display: flex;
                align-items: center;
            `}
        >
            {children}
        </div>
    )
}

const Breadcrumb = ({ first, last, active, backgroundColor, color, children }) => {

    return (
        <div
            className={cx(
                css`
                    cursor: pointer;
                   position: relative;
                   width: 100%;
                       margin-right: 4px;
                       padding-left: 20px;
                   `,
                last ? css`
                   margin-right: 0;
                   `
                    : null)}
        >
            {first ? (
                <div
                    className={css`
                       content: "";
                       position: absolute;
                       top: 0;
                       background-color: ${backgroundColor};
                       opacity: ${active ? 1 : 0.2};
                       border-width: 20px 10px;
                       height: 40px;
                       width: 20px;
                       left: 0;
           border-left-color: transparent;
           border-top-left-radius: 6px;
           border-bottom-left-radius: 6px;
                           `}
                />
            ) : (
                    <div
                        className={css`
                       content: "";
                       position: absolute;
                       top: 0;
                       border: 0 solid ${backgroundColor};
                       opacity: ${active ? 1 : 0.2};
                       border-width: 20px 10px;
                       width: 0;
                       height: 0;
                       left: 0;
           border-left-color: transparent;
                           `}
                    />
                )}
            <div
                className={cx(css`
                           position: relative;
                           padding: 10px 6px;
                           background-color: ${backgroundColor};
                           border-color: ${backgroundColor};
                           opacity: ${active ? 1 : 0.2};
                           color: ${color};
                           font-weight: bold;
                           line-height: 20px;
                       `,
                )}
            >
                {children}
            </div>
            {last ? (
                <div
                    className={css`
                 position: absolute;
                 top: 0;
                 height: 40px;
                width: 10px;
                 left: 100%;
                background-color: ${backgroundColor};
                opacity: ${active ? 1 : 0.2};
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
        
                     `}
                />


            ) : (
                    <div
                        className={css`
                           content: "";
                           position: absolute;
                           top: 0;
                           border: 0 solid ${backgroundColor};
                           border-width: 20px 10px;
                           width: 0;
                           height: 0;
                           left: 100%;
               border-color: transparent;
               border-left-color: ${backgroundColor};
               opacity: ${active ? 1 : 0.2};
           
                               `}
                    />
                )}
        </div>
    )
}

const handleActivatePhaseForDebtorTrajectory = async ({ id, phase, onPageRefresh }) => {

    console.log('handleActivatePhaseForDebtorTrajectory', { id, phase, onPageRefresh })

    await api.request({
        method: 'post',
        data: {
            query: `
                mutation activatePhaseForDebtorTrajectory($id: ID!, $phase: ID!) {
                    activatePhaseForDebtorTrajectory(id: $id, phase: $phase) {
                        id
                        phase
                    }
                } 
            `,
            variables: {
                id,
                phase
            }
        }
    })

    await onPageRefresh()
}

const phaseRenderers = {
    PHASE_5: (props) => (
        <div>
            <Button disabled={props.source.phase === 'PHASE_5' || props.source.phase === 'PHASE_6'} primary onClick={() => handleActivatePhaseForDebtorTrajectory({ id: props.source.id, phase: 'PHASE_5', onPageRefresh: props.onPageRefresh })}>
                Inschakelen
            </Button>
        </div>
    ),
    PHASE_6: (props) => (
        <div>
            <Button disabled={props.source.phase !== 'PHASE_5'} primary onClick={() => handleActivatePhaseForDebtorTrajectory({ id: props.source.id, phase: 'PHASE_6', onPageRefresh: props.onPageRefresh })}>
                Inschakelen
            </Button>
        </div>
    )
}

const AtgDescription = ({ startAtg, endAtg }) => {
    if (!startAtg || !endAtg) {
        return null
    }
    let content = <span>{startAtg} t/m {endAtg}</span>
    if (startAtg === endAtg) {
        content = <span>{startAtg}</span>
    }

    return (
        <div
            className={css`
            font-size: 13px;
            color: #737373;
            `}
        >
            ATG van {content}
        </div>
    )
}

const DtgDescription = ({ startAtg, endAtg, phaseOffset }) => {
    if (!startAtg || !endAtg) {
        return null
    }
    let content = <span>{startAtg - phaseOffset} t/m {endAtg - phaseOffset}</span>
    if (startAtg === endAtg) {
        content = <span>{startAtg - phaseOffset}</span>
    }

    return (
        <div
            className={css`
            font-size: 13px;
            color: #737373;
            `}
        >
            DTG van {content}
        </div>
    )
}

const PenaltyDescription = ({ penaltyPrice }) => {

    if (!penaltyPrice) {
        return null
    }

    return (
        <div
            className={css`
        font-size: 13px;
        color: #737373;
        `}
        >
            Boete van {toCurrency(penaltyPrice)}
        </div>
    )
}

const MetaBox = ({ rows }) => {

    rows = compact(rows)

    if (!rows.length) {
        return null
    }

    return (
        <div
            className={css`
            background-color: #f9f9f9;
            border-radius: 3px;
            margin-bottom: 8px;
            padding: 6px;
        `}
        >
            {rows}
        </div>
    )
}

class PhaseTracker extends React.Component {

    render() {

        const phaseId = this.props.source.phase

        const colors = this.props.phases.map(phase => phase.backgroundColor)

        const phases = this.props.phases.map((phase, index) => ({
            ...phase,
            width: index === this.props.phases.length - 1 ? 100 : (100 / this.props.phases.length) * (index + 0.5),
            background: `linear-gradient(45deg, ${colors.slice(0, index + 1).join(', ')})`,
            renderer: phaseRenderers[phase.id] ? phaseRenderers[phase.id] : defaultPhaseRenderer
        }))

        const phaseIds = phases.map(phase => phase.id)
        const currentPhaseIndex = phaseIds.indexOf(phaseId)

        return (
            <div>
                <Breadcrumbs>
                    {phases.map((phase, index) =>
                        <Breadcrumb
                            first={index === 0}
                            last={index === phases.length - 1}
                            key={phase.id}
                            backgroundColor={phase.backgroundColor}
                            color={phase.color}
                            active={currentPhaseIndex !== -1 && index <= currentPhaseIndex}
                        >
                            {phase.name}
                        </Breadcrumb>
                    )}
                </Breadcrumbs>
                <div
                    className={css`
                        display: flex;
                        justify-content: space-around;
                    `}
                >
                    {phases.map(phase => (
                        <div
                            className={css`
                                flex-grow: 1;
                                flex-basis: 0;
                                padding: 16px 8px;
                                line-height: 1.5;
                                &:first-child {
                                    padding-left: 0;
                                }
                                &:last-child {
                                    padding-right: 0;
                                }
                            `}
                        >

                            <MetaBox
                                rows={[
                                    AtgDescription({ startAtg: phase.startAtg, endAtg: phase.endAtg }),
                                    DtgDescription({ startAtg: phase.startAtg, endAtg: phase.endAtg, phaseOffset: this.props.source.phaseOffset }),
                                    PenaltyDescription({ penaltyPrice: phase.penaltyPrice })
                                ]}
                            />
                            <div
                                className={css`
                                    font-size: 14px;
                                    margin-bottom: 8px;
                                `}
                            >
                                {phase.description}
                            </div>
                            <div>
                                {phase.renderer(this.props)}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

class DebtorTrajectoryPhaseTracker extends React.Component {

    render() {

        return (
            <div>
                <h2>
                    {this.props.component.name}
                </h2>
                <Box p={32}>
                    <PhaseTracker
                        {...this.props}
                    />
                </Box>
            </div>
        )
    }
}

export default connect(state => ({
    phases: state
        .getIn(['tablesById', 'DebtorTrajectory', 'fields'])
        .find(field => field.get('id') === 'phase')
        .getIn(['settings', 'options'])
        .toJS()
}))(DebtorTrajectoryPhaseTracker)