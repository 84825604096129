import React from 'react'
import DifferenceCard from './DifferenceCard'

const ContractYearDebtorTrajectoryDayCountCard = ({ component, source }) => (
    <DifferenceCard
        title={component.name}
        value={source.debtorTrajectoryDayCount}
        differences={[
            {
                title: 'Vergeleken met vorig jaar',
                prev: source.debtorTrajectoryDayCountPrev,
                diff: source.debtorTrajectoryDayCountDiff,
                diffPct: source.debtorTrajectoryDayCountDiffPct,
                reverse: true
            },
            {
                title: 'Vergeleken met LesautoDeal gemiddelde',
                prev: source.stdDebtorTrajectoryDayCount,
                diff: source.stdDebtorTrajectoryDayCountDiff,
                diffPct: source.stdDebtorTrajectoryDayCountDiffPct,
                reverse: true
            }
        ]}
    />
)

export default ContractYearDebtorTrajectoryDayCountCard