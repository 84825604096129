import React from 'react'
import { connect } from 'react-redux'
import Box from '../components/Box'
import LoadingOverlay from '../components/LoadingOverlay'
import Record from '../components/Record'
import api from '../services/api'
import EmptyState from '../components/EmptyState'

class HasMany extends React.Component {

    state = {
        data: null,
        loading: true
    }

    fetch = async () => {

        this.setState({
            loading: true
        })

        const response = await api.request({
            method: 'post',
            data: {
                query: `
                    query hasOne($input: HasOneInput!) {
                        viewer {
                            hasOne(input: $input)
                        }
                    }
                `,
                variables: {
                    input: {
                        tableId: this.props.tableId,
                        componentId: this.props.component.id,
                        recordId: this.props.recordId
                    }
                }
            }
        })

        this.setState({
            data: response.data.data.viewer.hasOne,
            loading: false
        })
    }

    async componentDidMount() {
        await this.fetch()
    }

    render() {

        return (
            <div>
                <h2>
                    {this.props.component.name}
                </h2>
                {this.state.loading ? (
                    <Box p={32}>
                        <LoadingOverlay />
                    </Box>
                ) : this.state.data ? (
                    <Record
                        tableId={this.props.component.props.linkTable}
                        data={this.state.data}
                    />
                ) : (
                            <EmptyState>
                                Geen record gevonden
                            </EmptyState>
                        )}
            </div>
        )
    }
}

export default connect((state, props) => ({
    table: state.getIn(['tablesById', props.component.props.linkTable])
}))(HasMany)