import React from 'react'
import { css } from 'emotion'

const checkmark = props => (
    <svg {...props} viewBox="0 0 10 9"><polygon fill="currentColor" fillRule="nonzero" points="0.911865754 4.18958751 0 5.38059584 4.07043813 8.49702503 9.08388576 0.820183351 7.82798 0 3.69857048 6.32315832"></polygon></svg>
)

const ChartLegendItem = ({ onClick, active, dashed, color, title, description }) => (
    <div
        onClick={onClick}
        className={css`
            user-select: none;
            padding: 0;
            list-style-type: none;
            display: flex;
            align-items: center;
            color: ${color};
            -webkit-transform: translateX(2px);
            transform: translateX(2px);
            font-size: 14px;
            margin-left: 40px;
            cursor: pointer;
            transition: 200ms ease opacity;
            &:hover {
                opacity: 0.7;
            }
            &:first-child {
                margin-left: 0;
            }
        `}
    >
        <div
            className={css`
                position: relative;
                width: 16px;
                height: 16px;
                margin-right: 5px;
                border: 1px solid;
                border-radius: 50%;
                ${dashed === true ? 'border-style: dashed;' : ''}
            `}
        >
            {active ? checkmark({
                width: 9,
                className: css`
                    position: absolute;
                    top: 3px;
                    left: 3px;
                `
            }) : null}
        </div>
        <div>
            {title}
        </div>
        <div
            className={css`
                color: #b3b3b3;
                font-size: 12px;
                margin-left: 5px;
            `}
        >
            {description}
        </div>
    </div>
)

export default ChartLegendItem