import React from 'react'
import { connect } from 'react-redux'
import { css } from 'emotion'
import fieldRenderer from './fieldRenderer'
import defaultEmptyRenderer from './defaultEmptyRenderer';
import FieldTooltip from './FieldTooltip'
import api from '../services/api'
import spinner from '@pndr/spinner'

class RecordPreview extends React.Component {

    constructor(props) {
        super(props)
        this.fetching = false
        this.state = {
            fetched: false,
            loading: false,
            data: props.data
        }
    }

    componentDidMount() {

        if (this.props.collapsed) {
            return
        }
        this.fetch()
    }

    componentDidUpdate() {
        if (this.fetching) {
            return
        }
        if (this.state.fetched) {
            return
        }
        if (this.props.collapsed) {
            return
        }
        this.fetch()
    }

    fetch = async () => {
        this.fetching = true
        console.log('fetch', this.props.tableId, this.props.recordId)

        this.setState({
            loading: true
        })

        const response = await api.request({
            method: 'post',
            data: {
                query: `
                    query table($tableId: ID!, $recordId: ID!) {
                        viewer {
                            record(tableId: $tableId, recordId: $recordId)
                        }
                    }
                `,
                variables: {
                    tableId: this.props.tableId,
                    recordId: this.props.recordId
                }
            }
        })

        this.setState({
            data: response.data.data.viewer.record,
            loading: false,
            fetched: true
        }, () => {
            this.fetching = false
        })
    }

    render() {

        const { table } = this.props

        let fields = table.get('fields').toJS()

        if (this.props.collapsed) {
            fields = fields.filter(field =>
                this.props.fields.includes(field.id)
            )
        }

        if (this.state.loading) {

            return (
                <div
                    className={css`
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 250px;
                    `}
                >
                    {spinner({ width: 24 })}
                </div>
            )
        }

        return (
            <div>
                <div
                    className={css`
                        border-top: 1px solid #f2f2f2;
                    `}
                >
                    <table
                        className={css`
            width: 100%;
            table-layout: fixed;
            border-collapse: collapse;
        `}
                    >
                        <tbody>
                            {fields.map((field, index) => {

                                const record = this.state.data

                                const value = record[field.id]

                                const content = value === null ? defaultEmptyRenderer() : fieldRenderer({ type: 'recordPage' })({ field, onPageRefresh: this.props.onPageRefresh })(value, record)

                                return (
                                    <tr
                                        key={field.name}
                                    >
                                        <th
                                            className={css`
                            text-align: left;
                            ${index !== 0 ? 'border-top: 1px solid #f2f2f2;' : ''}
                            ${index !== fields.length - 1 ? 'border-bottom: 1px solid #f2f2f2;' : ''}
                            padding: 10px 16px;
                            vertical-align: top;
                            color: #737373;
                            font-weight: 400;
                            width: 30%;
                        `}
                                            height={40}
                                        >
                                            <FieldTooltip field={field}>{field.name}</FieldTooltip>
                                        </th>
                                        <td
                                            className={css`
                                ${index !== 0 ? 'border-top: 1px solid #f2f2f2;' : ''}
                                ${index !== fields.length - 1 ? 'border-bottom: 1px solid #f2f2f2;' : ''}
                            padding: 2px 16px;
                        `}
                                            height={40}
                                        >
                                            <div
                                                className={css`
                                    position: relative;
                                        display: flex;
                                        align-items: center;
                                        width: 100%;
                                        height: 100%;
                                    `}
                                            >
                                                {content}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default connect((state, props) => {
    const table = state.getIn(['tablesById', props.tableId])

    return {
        table
    }
})(RecordPreview)