import React from 'react'
import spinner from '@pndr/spinner'
import Box from '../components/Box'
import CalendarGraph2 from '../components/CalendarGraph2'
import api from '../services/api'

export default class ContractDatesGraph extends React.Component {

    state = {
        loading: true,
        data: null
    }

    componentDidMount() {
        this.fetch()
    }

    fetch = async () => {

        const response = await api.request({
            method: 'post',
            data: {
                query: `
                    query contract($id: ID!) {
                        viewer {
                            contract(id: $id) {
                                startDate
                                endDate
                                paidUntilDate
                                metaObjectsUpdatedAt
                                contractDates {
                                    id
                                    date
                                    state
                                    dtg
                                    phaseOffset
                                    paymentCount
                                    dtgDiffRelativeToYesterday
                                    balance
                                    totalUnpaid
                                    unpaid
                                    contractPeriodStack
                                    score
                                    issueState
                                    issues
                                }
                            }
                        }
                    }
                `,
                variables: {
                    id: this.props.recordId
                }
            }
        })

        this.setState({
            loading: false,
            data: response.data.data
        })
    }

    render() {

        return (
            <div>
                <h2>
                    {this.props.component.name}
                </h2>
                <Box p={32}>
                    {this.renderContent()}
                </Box>
            </div>
        )
    }

    renderContent() {

        if (this.state.loading) {
            return spinner({ width: 24 })
        }

        const { contract } = this.state.data.viewer
        const { contractDates } = contract

        return (
            <CalendarGraph2
                metaObjectsUpdatedAt={contract.metaObjectsUpdatedAt}
                contractStartDate={contract.startDate}
                contractEndDate={contract.endDate}
                paidUntilDate={contract.paidUntilDate}
                contractDates={contractDates}
            />
        )
    }
}