import React from 'react'
import spinner from '@pndr/spinner'
import { css } from 'emotion'

const LoadingOverlay = () => (
    <div
        className={css`
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(255, 255, 255, 0.7);
        `}
    >
        {spinner({ width: 24 })}
    </div>
)

export default LoadingOverlay