import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { css } from 'emotion'
import icons from '../icons'
import { Container, Sidebar, Menu, MenuBrand, MenuContainer, MenuItem, MenuSeparator } from '@pndr/sidebar'
import FeedbackDialog from './FeedbackDialog'

const navBefore = [
    { small: false, icon: icons.statistics, to: '/', name: 'Statistieken' },
    // { small: false, icon: icons.administration, to: '/reports', name: 'Reports' },
    // { small: false, icon: icons.orders, to: '/vehicle-contracts', name: 'Vehicle Contracts' },
    // { small: false, icon: icons.payments, to: '/purchase-invoices', name: 'Purchase Invoices' },
    // { small: false, icon: icons.orders, to: '/contracts', name: 'Customer Contracts' },
    // { small: false, icon: icons.account, to: '/contacts', name: 'Contacts' },
    // { small: false, icon: icons.payments, to: '/invoices', name: 'Sales Invoices' },
    // { small: false, icon: icons.administration, to: '/debtor-trajectories', name: 'Debtor Trajectories' },
    // { small: false, icon: icons.payments, to: '/penalty-invoices', name: 'Penalty Invoices' },
    // { small: false, icon: icons.payments, to: '/penalties', name: 'Penalties' },
    // { small: false, icon: icons.payments, to: '/payments', name: 'Payments' },
    // { small: false, icon: icons.payments, to: '/issues', name: 'Issues' },
    // { small: false, icon: icons.payments, to: '/messages', name: 'Messages' },
    // { small: false, icon: icons.notification, to: '/events', name: 'Events' }
]

const navAfter = [
    { small: false, icon: icons.payments, to: '/agenda', name: 'Agenda' },
]


const nav2 = [
    { small: true, icon: icons.payments, to: '/explorer/mail-template', name: 'E-mail templates' },
    { small: true, icon: icons.payments, to: '/explorer/tax-rate', name: 'Belasting soorten' },
    { small: true, icon: icons.payments, to: '/explorer/action-token', name: 'Actie tokens' },
    { small: true, icon: icons.payments, to: '/actions', name: 'Acties' },
    { small: true, icon: icons.settings, to: '/settings', name: 'Instellingen' },
    { small: true, icon: icons.developer, to: '/developer', name: 'Developer' },
    { small: true, icon: icons.administration, to: '/documentation', name: 'Documentatie' },
    { small: true, icon: icons.payments, to: '/job-queue', name: 'Job Queue' },
    { small: true, icon: icons.help, to: '/about', name: 'Over' },
]

const theme = 'dark'

const Layout = ({ tables, history, session, children }) => (
    <div>
        {session ? (
            <Container>
                <Sidebar theme={theme}>
                    <MenuBrand
                        theme={theme}
                        imageFitTypeId={'cover'}
                        imageUrl={'https://user-images.githubusercontent.com/44947294/52181009-2996ec80-27ed-11e9-923c-54796d82455b.png'}
                        title={'Debtor'}
                        description={window._build_.version}
                    />
                    <MenuContainer>
                        <Menu>
                            {navBefore.map((link, index) => (
                                <MenuItem
                                    theme={theme}
                                    key={index}
                                    icon={link.icon}
                                    active={history.location.pathname === link.to}
                                    onClick={() => history.push(link.to)}
                                    title={link.name}
                                    small={link.small}
                                />
                            ))}
                            {tables.map((table) => (
                                <MenuItem
                                    theme={theme}
                                    key={table.get('id')}
                                    icon={icons.administration}
                                    active={history.location.pathname.indexOf(`/explorer/${table.get('slug')}/`) !== -1 || history.location.pathname === `/explorer/${table.get('slug')}`}
                                    onClick={() => history.push(`/explorer/${table.get('slug')}`)}
                                    title={table.get('plural')}
                                    small={false}
                                />
                            ))}
                            {navAfter.map((link, index) => (
                                <MenuItem
                                    theme={theme}
                                    key={index}
                                    icon={link.icon}
                                    active={history.location.pathname === link.to}
                                    onClick={() => history.push(link.to)}
                                    title={link.name}
                                    small={link.small}
                                />
                            ))}
                            <MenuSeparator theme={theme} />
                            {nav2.map((link, index) => (
                                <MenuItem
                                    theme={theme}
                                    key={index}
                                    icon={link.icon}
                                    active={history.location.pathname === link.to}
                                    onClick={() => history.push(link.to)}
                                    title={link.name}
                                    small={link.small}
                                />
                            ))}
                            <MenuSeparator theme={theme} />
                            <MenuItem
                                theme={theme}
                                icon={icons.signout}
                                small={true}
                                onClick={() => history.push('/sign-out')}
                                title={'Sign out'}
                            />
                        </Menu>
                    </MenuContainer>
                </Sidebar>
                {children}
                <FeedbackDialog />
            </Container>
        ) : (
                <div
                    className={css`
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                `}
                >
                    {children}
                </div>
            )}
    </div>
)

export default withRouter(connect(state => ({
    tables: [
        'Dashboard',
        'Contact',
        'Contract',
        'Invoice',
        'DebtorTrajectory',
        'Message',
        'Penalty',
        'PenaltyInvoice',
        'Issue',
        // 'ContractReport',
        'DebtorTrajectoryReport',
        // 'Report',
        'Payment',
        'PaymentLink',
        'Event',
        'SystemTask',
    ].map(id => state.getIn(['tablesById', id]))
}))(Layout))