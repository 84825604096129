import React from "react"
import moment from 'moment'
import Box from '../components/Box'
import { css } from 'emotion'
import pkg from '../../package.json'
class AboutRoute extends React.Component {

    state = {
        data: {
            name: 'LesautoDeal',
            description: 'Debtor',
        },
        info: {
            Environment: window._env_.REACT_APP_ENVIRONMENT,
            Version: window._build_.version,
            'Release date': window._build_.release_date ? moment(window._build_.release_date).format('dddd, D MMMM YYYY HH:mm') : null,
            'Commit hash': window._build_.commit_hash,
            'Author': window._build_.author
        }
    }

    componentDidMount() {

        console.log({
            pkg
        })
    }

    render() {

        if (!this.state.data) {
            return (
                <div>
                    Loading...
                </div>
            )
        }

        return (
            <div
                className={css`
                    width: 100%;
                    padding: 0 70px;
                    margin: 70px auto;
                    max-width: 840px;
                `}
            >
                <Box p={32}>
                    <div
                        className={css`
                    display: flex;
                    align-items: center;
                    margin-bottom: 16px;
                `}
                    >
                        <div
                            className={css`
                            font-size: 28px;
                            font-weight: 600;
                        `}
                        >
                            {this.state.data.name}
                        </div>
                        <div
                            className={css`
                        font-size: 28px;
                        margin-left: 8px;
                    `}
                        >
                            {this.state.data.description}
                        </div>
                    </div>
                    {Object.keys(this.state.info).map(name => (
                        <div key={name} className={css`margin-bottom: 8px;`}>
                            <strong>{name}</strong> {this.state.info[name] ? this.state.info[name] : 'Unknown'}
                        </div>
                    ))}
                </Box>
            </div>
        )
    }
}

export default AboutRoute