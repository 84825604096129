import axios from 'axios'
import getSession from '@sublayer/passport-components/lib/getSession'

export default {
    request: async (options) => {

        let headers = {
            'Content-Type': 'application/json'
        }

        const session = getSession()

        if (session) {
            headers['Authorization'] = `Bearer ${session}`
        }

        const instance = axios.create({
            baseURL: window._env_.REACT_APP_API_URL,
            headers
        })

        return instance.request(options)
    }
}