import React from 'react'
import Button from '@pndr/button'
import spinner from '@pndr/spinner'
import api from '../services/api'

export default class ContractDateIssueIgnoreButton extends React.Component {

    state = {
        loading: false
    }

    render() {

        if (this.props.resolveType !== 'manual') {
            return null
        }

        return (
            <Button
                icon={this.state.loading ? spinner : () => this.props.stateId === 'open' ? '✅ ' : '⚠️ '}
                onClick={this.handleResolveIssue}
            >
                {this.props.stateId === 'open' ? 'Resolve' : 'Heropenen'}
            </Button>
        )
    }

    handleResolveIssue = async (e) => {

        e.stopPropagation()

        this.setState({ loading: true })

        await api.request({
            method: 'post',
            data: {
                query: `
                    mutation setStateIdForIssue($id: ID!, $stateId: ID!) {
                        setStateIdForIssue(id: $id, stateId: $stateId)
                    } 
                `,
                variables: {
                    id: this.props.id,
                    stateId: this.props.stateId === 'open' ? 'resolved' : 'open'
                }
            }
        })

        this.setState({ loading: false })

        await this.props.onPageRefresh()
    }
}