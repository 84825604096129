import React from 'react'
import spinner from '@pndr/icons/es/spinner'
import gridView from '@pndr/icons/es/gridView'

export default {
    spinner,
    gridView,
    polygon: props => (
        <svg {...props} viewBox="0 0 8 8"><g fill="none" fillRule="evenodd"><g fill="#000"><polygon points="2 .5 7 4 2 7.5"></polygon></g></g></svg>
    ),
    search: props => (
        <svg {...props} viewBox="0 0 13 12"><defs><circle id="path-1" cx="5" cy="5" r="5"></circle><mask id="mask-2" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="10" height="10" fill="white"><use xlinkHref="#path-1"></use></mask></defs><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="Group-4" stroke="currentColor"><path d="M9,9 L12,12" id="Line" strokeLinecap="square"></path><use id="Oval" mask="url(#mask-2)" strokeWidth="2" xlinkHref="#path-1"></use></g></g></svg>
    ),
    expand: props => (
        <svg {...props} viewBox="0 0 12 12">
            <path fillRule="evenodd" fill="currentColor" d="M4.77546101,10.4553613 L1.81474478,10.8783208 C1.25439084,10.9583713 0.87938524,10.5736845 0.958225649,10.0218016 L1.38118511,7.06108542 C1.45982823,6.51058357 1.83916177,6.38790899 2.22968611,6.77843328 L2.93679297,7.48554006 L4.10581974,6.3165134 C4.49903533,5.92329779 5.12937282,5.9261252 5.51989706,6.31664944 C5.91314397,6.70989635 5.91048225,7.3402777 5.52003317,7.73072683 L4.35100654,8.89975362 L5.05811323,9.6068604 C5.45136014,10.0001074 5.32208977,10.3772715 4.77546101,10.4553613 Z M7.10137856,1.3810729 L10.0620948,0.958113443 C10.6224487,0.87806288 10.9974543,1.26274971 10.9186139,1.81463257 L10.4956545,4.7753488 C10.4170113,5.32585066 10.0376778,5.44852523 9.64715346,5.05800094 L8.94004659,4.35089416 L7.45430822,5.83663252 C7.06109263,6.22984813 6.43075515,6.22702073 6.0402309,5.83649648 C5.64698399,5.44324957 5.64964572,4.81286823 6.04009479,4.42241909 L7.52583303,2.9366806 L6.81872633,2.22957382 C6.42547942,1.83632686 6.5547498,1.45916273 7.10137856,1.3810729 Z" />
        </svg>
    ),
    payments: props => (
        <svg {...props} fill="none" viewBox="0 0 20 20">
            <g fill="currentColor">
                <path clipRule="evenodd"
                    d="M5.5 4a1.5 1.5 0 1 0 0 3h9a1.5 1.5 0 0 0 0-3zm0 10a1.5 1.5 0 0 0 0 3h9a1.5 1.5 0 0 0 0-3z"
                    fillOpacity=".6" fillRule="evenodd" />
                <rect height="5" rx="2.5" width="16" x="2" y="8" />
            </g>
        </svg>
    ),
    orders: props => (
        <svg {...props} fill="none" viewBox="0 0 20 20">
            <g clipRule="evenodd" fill="currentColor" fillRule="evenodd">
                <path
                    d="M4 2a1 1 0 0 0-1 1v14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3a1 1 0 0 0-1-1h-1.056a.944.944 0 0 0-.944.944.944.944 0 0 1-.944.945H6.944A.944.944 0 0 1 6 2.944.944.944 0 0 0 5.056 2zm11 4H5v11h10z" />
                <path d="M9 1a2 2 0 0 0-2 2h6a2 2 0 0 0-2-2zm5 6H6v9h8z" fillOpacity=".6" />
            </g>
        </svg>
    ),
    statistics: props => (
        <svg {...props} fill="none" viewBox="0 0 20 20">
            <clipPath>
                <path d="M0 0h20v20H0z" />
            </clipPath>
            <g clipPath="url(#icon-statistics-a)" clipRule="evenodd" fill="currentColor" fillRule="evenodd">
                <path
                    d="M3 11.176V16.5a1.5 1.5 0 0 0 3 0v-7-.012l-.215-.257-1.763 1.479A2.09 2.09 0 0 1 3 11.176zm5 .378V16.5a1.5 1.5 0 0 0 3 0v-5.903l-.779.654A2.1 2.1 0 0 1 8 11.554zm5.014-2.263c-.01.068-.014.138-.014.209v7a1.5 1.5 0 0 0 3 0v-5.857c-.785.299-1.74.133-2.363-.61z"
                    fillOpacity=".6" />
                <path
                    d="M10.715 2.273l5.767-.45a1.1 1.1 0 0 1 1.15 1.371l-1.445 5.601a1.1 1.1 0 0 1-1.908.432l-1.453-1.732-3.371 2.828a1.1 1.1 0 0 1-1.55-.135L5.785 7.66 3.256 9.782a1.1 1.1 0 0 1-1.414-1.685l3.371-2.829a1.1 1.1 0 0 1 1.55.136l2.12 2.527 2.529-2.121-1.454-1.733a1.1 1.1 0 0 1 .757-1.804z" />
            </g>
        </svg>
    ),
    administration: props => (
        <svg {...props} fill="none" viewBox="0 0 20 20">
            <g fill="currentColor">
                <path
                    d="M2 4a1 1 0 0 1 1-1h5a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-.56-.56A2 2 0 0 0 7.172 17H3a1 1 0 0 1-1-1z"
                />
                <path
                    d="M19 4a1 1 0 0 0-1-1h-5a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l.56-.56A2 2 0 0 1 13.828 17H18a1 1 0 0 0 1-1z"
                    fillOpacity=".6"
                />
            </g>
        </svg>
    ),
    developer: props => (
        <svg {...props} fill="none" viewBox="0 0 20 20">
            <g fill="currentColor">
                <path clipRule="evenodd"
                    d="M4 2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm0 7.869v4.263c0 1.597 1.78 2.55 3.11 1.664l3.197-2.132a2 2 0 0 0 0-3.328L7.109 8.204C5.78 7.318 4 8.271 4 9.87z"
                    fillOpacity=".6" fillRule="evenodd" />
                <path
                    d="M5 9.869v4.263a1 1 0 0 0 1.555.831l3.197-2.13a1 1 0 0 0 0-1.665L6.555 9.036A1 1 0 0 0 5 9.87z" />
            </g>
        </svg>
    ),
    notification: props => (
        <svg {...props} fill="none" viewBox="0 0 20 20">
            <clipPath>
                <path d="M0 0h20v20H0z" />
            </clipPath>
            <g fill="currentColor">
                <path d="M5.725 17.548a.5.5 0 0 1 .354-.612L9.943 15.9a.5.5 0 0 1 .612.354 2.5 2.5 0 1 1-4.83 1.294z"
                    fillOpacity=".6" />
                <path clipRule="evenodd"
                    d="M6.802 2.248A1 1 0 1 0 6.285.316a1 1 0 0 0 .517 1.932zm.742.836a5.5 5.5 0 0 0-3.89 6.737l.44 1.642-.6 1.962c-.459 1.502.914 2.923 2.43 2.517l9.45-2.532c1.518-.407 1.996-2.324.847-3.395l-1.501-1.4-.44-1.641a5.5 5.5 0 0 0-6.736-3.89z"
                    fillRule="evenodd" />
            </g>
        </svg>
    ),
    account: props => (
        <svg
            {...props}
            fill="none"
            viewBox="0 0 20 20"
        >
            <g fill="currentColor">
                <path
                    d="M14.846 6.8c0 2.651-2.17 4.8-4.846 4.8S5.154 9.451 5.154 6.8 7.324 2 10 2s4.846 2.149 4.846 4.8z" />
                <path clipRule="evenodd"
                    d="M5.97 11.099A5.329 5.329 0 0 0 3 15.867C3 17.045 3.964 18 5.154 18h9.692c1.19 0 2.154-.955 2.154-2.133a5.329 5.329 0 0 0-2.97-4.768A5.933 5.933 0 0 1 10 12.667a5.933 5.933 0 0 1-4.03-1.568z"
                    fillOpacity=".6" fillRule="evenodd" />
            </g>
        </svg>
    ),
    settings: props => (
        <svg
            {...props}
            fill="none"
            viewBox="0 0 20 20"
        >
            <g clipRule="evenodd" fill="currentColor" fillRule="evenodd">
                <path
                    d="M10.83 6a2.996 2.996 0 0 0 0-2H17a1 1 0 1 1 0 2zM5.17 6H3a1 1 0 0 1 0-2h2.17a2.995 2.995 0 0 0 0 2zm5.66 10a2.997 2.997 0 0 0 0-2H17a1 1 0 1 1 0 2zm-5.66 0H3a1 1 0 1 1 0-2h2.17a2.995 2.995 0 0 0 0 2zM15 10c0 .35-.06.687-.17 1H17a1 1 0 1 0 0-2h-2.17c.11.313.17.65.17 1zM3 11h6.17a2.995 2.995 0 0 1 0-2H3a1 1 0 0 0 0 2z"
                    fillOpacity=".6" />
                <path
                    d="M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm0 10a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm2-3a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
            </g>
        </svg>
    ),
    help: props => (
        <svg {...props} fill="none" height="20" viewBox="0 0 20 20" width="20">
            <g clipRule="evenodd" fill="currentColor" fillRule="evenodd">
                <path d="M10 19a9 9 0 1 0 0-18 9 9 0 0 0 0 18zm0-2a7 7 0 1 0 0-14 7 7 0 0 0 0 14z" fillOpacity=".6" />
                <path
                    d="M10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12zm-.693-5.599c-.122.298-.182.697-.182 1.198H10.5l.021-.328c.04-.35.195-.657.467-.918l.435-.414c.34-.329.579-.628.715-.897.136-.272.204-.56.204-.864 0-.67-.21-1.187-.629-1.553-.419-.368-1.008-.553-1.767-.553-.752 0-1.346.194-1.783.58-.433.387-.653.922-.66 1.606H9.06c.007-.286.09-.51.247-.671.161-.165.374-.247.64-.247.558 0 .837.302.837.908 0 .2-.053.392-.161.574-.107.18-.324.414-.65.704-.322.286-.544.578-.666.875zm-.097 2.256a.781.781 0 0 0-.241.591c0 .236.079.431.236.586.161.153.372.23.634.23.261 0 .47-.077.628-.23a.775.775 0 0 0 .242-.586.773.773 0 0 0-.247-.59c-.161-.158-.369-.237-.623-.237s-.464.079-.629.236z" />
            </g>
        </svg>
    ),
    chat: props => (
        <svg
            {...props}
            fill="none"
            viewBox="0 0 20 20"
        >
            <g clipRule="evenodd" fill="currentColor" fillRule="evenodd">
                <path
                    d="M11.423 8.57A5.98 5.98 0 0 0 12 6c0-.7-.12-1.373-.34-1.998a7.5 7.5 0 1 1-3.57 14.18L4 19l.818-4.09a7.464 7.464 0 0 1-.816-3.25A5.99 5.99 0 0 0 6 12a5.98 5.98 0 0 0 2.57-.577l3.804.951z"
                    fillOpacity=".6" />
                <path d="M10.362 8.446a5 5 0 1 0-1.915 1.915L11 11z" />
            </g>
        </svg>
    ),
    signout: props => (
        <svg {...props} fill="none" viewBox="0 0 20 20">
            <g clipRule="evenodd" fill="currentColor" fillRule="evenodd">
                <path
                    d="M4 2a2 2 0 0 0-2 2v4.044l4-.004V6.996C6 5.39 7.695 4.434 8.99 5.368l4.19 3.02a1.987 1.987 0 0 1 0 3.216l-4.19 3.02c-1.295.935-2.99.02-2.99-1.587v-1.044l-4 .003V16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z"
                    fillOpacity=".6" />
                <path
                    d="M12.59 10.802L8.41 13.815c-.647.466-1.546 0-1.546-.802v-2.027H2V9.014h4.863V6.987c0-.801.899-1.268 1.546-.802l4.182 3.013a.99.99 0 0 1 0 1.604z" />
            </g>
        </svg>
    ),
    formula: props => (
        <svg {...props} viewBox="0 0 16 16">
            <path fillRule="evenodd" fill="currentColor"
                d="M13.427,12.2326 L14.489,13.2946 C14.877,13.6826 14.877,14.3196 14.488,14.7086 C14.1,15.0976 13.463,15.0976 13.074,14.7086 L12.013,13.6476 L10.953,14.7086 C10.564,15.0976 9.928,15.0976 9.539,14.7086 L9.538,14.7076 C9.149,14.3196 9.149,13.6836 9.538,13.2946 L10.599,12.2326 L9.539,11.1726 C9.15,10.7836 9.15,10.1476 9.539,9.7586 C9.928,9.3696 10.564,9.3696 10.953,9.7586 L12.012,10.8176 L13.073,9.7556 C13.463,9.3656 14.099,9.3656 14.488,9.7546 L14.489,9.7556 C14.877,10.1446 14.877,10.7806 14.489,11.1696 L13.427,12.2326 Z M10.2468,0.0002 C12.87146,0.0002 13.3978592,2.53145 13.3978592,2.53145 C13.4839914,2.7844474 13.3156952,3.0002 13.0341343,3.0002 L11.756611,3.0002 C11.4754573,3.0002 11.1584611,2.7973017 11.0494895,2.54701396 C11.0494895,2.54701396 10.977478,2.0002 10.2468,2.0002 C9.4658,2.0002 9.2468,3.0002 9.2468,3.0002 L8.8468,5.0002 L9.2468,5.0002 L10.7468,5.0002 C11.0218,5.0002 11.2468,5.2252 11.2468,5.5002 C11.2468,5.7752 11.0218,6.0002 10.7468,6.0002 L9.2468,6.0002 L8.6468,6.0002 L7.2468,13.0002 C6.9348,14.3752 5.4968,16.0002 3.2468,16.0002 C1.2138,16.0002 0.5268,14.1722 0.3238,13.3702 C0.2748,13.1802 0.4208,13.0002 0.6168,13.0002 L1.74888936,13.0002 C2.02015548,13.0002 2.34410227,13.1978777 2.46413071,13.4417255 C2.46413071,13.4417255 2.59429932,14.0002 3.2468,14.0002 C4.0288,14.0002 4.2468,13.0002 4.2468,13.0002 L5.6468,6.0002 L5.2468,6.0002 L4.7468,6.0002 C4.4718,6.0002 4.2468,5.7752 4.2468,5.5002 C4.2468,5.2252 4.4718,5.0002 4.7468,5.0002 L5.2468,5.0002 L5.8468,5.0002 L6.2468,3.0002 C6.5598,1.6252 7.62214004,0.0002 10.2468,0.0002 Z" />
        </svg>
    )
}