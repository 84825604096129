import React from 'react'
import { css } from 'emotion'

const PageHeader = ({ title }) => (
    <div
        className={css`
        height: 70px;
        border-bottom: 1px solid #ebebeb;
        background-color: #fff;
    `}
    >
        <div
            className={css`
padding: 0 40px;
`}
        >
            <div
                className={css`
                font-size: 26px;
                display: flex;
                align-items: center;
                height: 70px;
            `}
            >
                <div>
                    <div
                        className={css`
                        font-weight: 700;
                    `}
                    >
                        {title}
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default PageHeader